import React, { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Spinner from 'vibo-ui/Spinner';
import Questions from 'components/questions/Questions';
import QuestionsBlockHeader from './QuestionsBlockHeader';
import NoQuestions from 'components/emptyStates/NoQuestions';
import SectionBlock from 'components/sections/blocks/SectionBlock';

import { EventContext } from 'components/context/EventContext';
import { EditableContext } from 'components/common/EditableContext';
import { TimelineQuestionsContext } from './TimelineQuestionsContext';

import { useTargetingQuestion } from 'graphql/hooks/questions';
import { getQuestionsData } from 'services/questions/helpers';

import { ControlProps } from 'components/sections/blocks/SectionBlock/interfaces';
import { EventTimelineTabKey } from 'types/enums';
import { TimelineQuestionsBlockProps } from './interfaces';

import useStyles from './style';

const TimelineQuestionsBlock: FC<TimelineQuestionsBlockProps> = ({
  sections,
  variables,
  listQuery,
  listCacheKey,
  section,
  mutations,
  refetchQueries,
  canAnswer = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [controlRef, setControlRef] = useState<Nullable<ControlProps>>(null);

  const { canEdit } = useContext(EditableContext);
  const { isFavoriteSections, isEventDj, isLoading } = useContext(EventContext);

  const { sectionId } = useParams<EventPageRouteParams>();

  const { targetQuestionId } = useTargetingQuestion();

  const { data, loading: questionsLoading } = useQuery<QuestionsListResponse>(listQuery, {
    variables: {
      ...variables,
      sectionId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !sectionId,
  });

  const questionsData: QuestionsData = getQuestionsData(data);

  const questions = (isFavoriteSections ? questionsData : questionsData.questions) as Question[];

  useEffect(() => {
    if (!!targetQuestionId) {
      controlRef?.setIsCollapsed(false);
    }
  }, [targetQuestionId]);

  return (
    <TimelineQuestionsContext.Provider
      value={{
        variables,
        section,
        sections,
        mutations,
        refetchQueries,
        listCacheKey,
        listQuery,
        questions,
        canAnswer,
        questionsData,
      }}
    >
      <SectionBlock
        controlRef={setControlRef}
        className={classNames('content-fade-in', classes.timelineQuestionsBlock, {
          blankEmptyState: questionsLoading,
          noSpaces: isEventDj && canEdit && (section.questionsCount > 0 || questions.length > 0),
          'ready _2': (!!questionsData && !questionsLoading) || !isLoading,
        })}
        type={EventTimelineTabKey.questions}
        section={section}
        renderHeader={({ caretButton }) => (
          <QuestionsBlockHeader>{caretButton}</QuestionsBlockHeader>
        )}
        renderContent={() => (
          <>
            {!!questions.length ? (
              <Questions onAddQuestion={() => controlRef?.setIsShown(true)} />
            ) : questionsLoading ? (
              <Spinner />
            ) : (
              <NoQuestions>{t('addYourFirstQuestion')}</NoQuestions>
            )}
          </>
        )}
        defaultShown
        canCollapse
      />
    </TimelineQuestionsContext.Provider>
  );
};

export default TimelineQuestionsBlock;
