import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import Icon from 'vibo-ui/Icon';
import Image from 'vibo-ui/Image';
import Video from 'components/modals/VideoPreviewModal/Video';

import { ReactComponent as YoutubeIcon } from 'resources/img/svg/youtube.svg';
import { ReactComponent as VimeoIcon } from 'resources/img/svg/vimeoIcon.svg';

import { useModal } from 'vibo-ui/Modal';
import { getVideoIdByLink, isLoomLink, isYoutubeLink } from 'services/common/videoHelpers';

import { Modals } from 'types/enums';
import { VideoPreviewModalProps } from 'components/modals/VideoPreviewModal';
import { VideoPreviewProps } from './interfaces';

import useStyles from './style';

const VideoPreview: FC<VideoPreviewProps> = ({ link, options, isInlinePlay = false, children }) => {
  const classes = useStyles();

  const { openModal } = useModal();

  const isLoom = useMemo(() => isLoomLink(link), [link]);
  const isYoutube = useMemo(() => isYoutubeLink(link), [link]);

  const videoId = useMemo(() => getVideoIdByLink(link), [link]);

  const videoThumbnail = useMemo(
    () =>
      isLoom
        ? `https://cdn.loom.com/sessions/thumbnails/${videoId}-with-play.gif`
        : isYoutube
        ? `https://img.youtube.com/vi/${videoId}/0.jpg`
        : `https://vumbnail.com/${videoId}.jpg`,
    [videoId]
  );

  const videoProps = useMemo(
    () =>
      isYoutube
        ? {
            videoLink: link,
            options,
          }
        : {
            videoLink: link,
          },
    [isYoutube, link, options]
  );

  const openVideoPreviewModal = () => {
    !!videoId &&
      openModal<VideoPreviewModalProps>({
        key: Modals.videoPreiew,
        props: videoProps,
      });
  };

  return !!videoId ? (
    <div className={classNames('videoPreview', classes.videoPreview, {})}>
      {isInlinePlay ? (
        <Video {...videoProps} />
      ) : (
        <Image onClick={openVideoPreviewModal} src={videoThumbnail}>
          <Icon className={classes.whiteIcon} icon={VimeoIcon} />
          <span className="noPreviewImgText">No video image preview</span>
        </Image>
      )}
      {isLoom || isInlinePlay ? null : <Icon icon={isYoutube ? YoutubeIcon : VimeoIcon} />}
      {children}
    </div>
  ) : null;
};

export default VideoPreview;
