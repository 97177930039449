import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboList: {
    display: 'flex',

    '&.verticalLayout': {
      flexDirection: 'column',
    },

    '&.horizontalLayout': {
      flexDirection: 'row',
    },
  },

  spinner: {
    color: 'var(--primary-main)',
  },
}));

export default useStyles;
