import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  droppable: {
    padding: '2px 0',
    margin: '0 0 0 -1px',
  },

  lastBlock: {
    '& > .customContentArea': {
      '& > .timeline-icon': {
        '&.block-collapsed': {
          '& > .colored-line': {
            display: 'none',
          },
        },

        '& > .colored-line': {
          height: 'calc(100% - 34px)',

          '&:before': {
            display: 'block',
          },
        },
      },
    },
  },

  sectionCoverImgWrapper: {
    color: 'var(--clr-white)',
    position: 'relative',
    opacity: 0,
    animationName: 'fade-in',
    animation: '1s ease-out 0.2s forwards',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '26px',

    '& .linkify-wrapper': {
      display: 'block',

      '& .sectionDesc': {
        color: 'var(--text-primary)',
        margin: 0,
        padding: '12px 22px',
        lineHeight: '17px',

        '@media screen and (min-width: 1441px)': {
          padding: '16px 26px',
          lineHeight: '21px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      marginBottom: '36px',
    },
  },

  sectionCoverImg: {
    width: '100%!important',
    objectFit: 'cover',
    height: '100%',
    borderRadius: '4px 4px 0 0',

    '&:only-child': {
      borderRadius: '4px',
    },
  },
}));

export default useStyles;
