import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  eventLayout: {
    backgroundColor: 'var(--background-default)',
    minHeight: '100%',
    height: '100%',
    overflow: 'hidden',

    '& .iconWrap svg': {
      cursor: 'pointer',
      minWidth: '22px',
      minHeight: '22px',
      marginRight: '0px',

      '&:hover': {
        color: 'inherit',
      },
    },

    '& > .container': {
      height: 'calc(100vh - 40px)!important',

      '& > .containerContent': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        '@media screen and (min-width: 1441px) and (max-width: 1536px)': {
          maxWidth: '1100px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        height: 'calc(100vh - 48px)!important',
      },
    },
  },

  iconWrap: {
    zIndex: 3,
    padding: '6px 0',

    '& > .contentArea': {
      minHeight: '36px',
    },
  },

  reloadButton: {
    margin: '0 12px!important',
  },

  downloadSpotifyButton: {
    margin: '0 8px',
  },
}));

export default useStyles;
