import React from 'react';
import ReactLinkify from 'react-linkify';

import { options } from './constants';

const Linkify: React.FC = ({ children }) => {
  const componentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number
  ): React.ReactNode => {
    return (
      <a key={key} className={'hoverable-action'} href={decoratedHref} {...options}>
        {decoratedText}
      </a>
    );
  };

  return (
    <ReactLinkify textDecorator={v => v} componentDecorator={componentDecorator}>
      <span className="linkify-wrapper">{children}</span>
    </ReactLinkify>
  );
};

export default Linkify;
