import React, {
  FC,
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
  CSSProperties,
} from 'react';
import classNames from 'classnames';
import WaveSurfer from 'wavesurfer.js';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';

import Icon from 'vibo-ui/Icon';
import SeekTime from './SeekTime';
import Button from 'vibo-ui/Button';
import { BeatsourceContext } from '../BeatsourceContext';

import { ReactComponent as VolumeIcon } from 'resources/img/svg/volume.svg';
import { ReactComponent as PauseLgIcon } from 'resources/img/svg/pauseLg.svg';
import { ReactComponent as PlayFilledLgIcon } from 'resources/img/svg/playFilledLg.svg';
import { ReactComponent as KeyBoardIcon } from 'resources/img/svg/keyBoard.svg';

import { useModal } from 'vibo-ui/Modal';
import { usePlayerShortKeys } from './songPlayerHook';
import { handleScrollKeyPress } from 'services/common/scrollHelper';
import { appColor } from 'components/context/ViboThemeContext/constants';
import {
  WAVE_CONFIG,
  HALF_VOLUME,
  MAX_VOLUME,
  MIN_VOLUME,
  VOLUME_STEP,
  getWaveColor,
  normilizeVolume,
} from './constants';

import { Modals } from 'types/enums';
import { SongPlayerProps } from './interfaces';

const SongPlayer: FC<SongPlayerProps> = ({ audio, artist, name }) => {
  const storedVolume = +(localStorage.getItem('player_volume') || HALF_VOLUME);

  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(storedVolume);
  const [ready, setReady] = useState(false);

  const { setIsSongPaused, playSong } = useContext(BeatsourceContext);

  const containerRef = useRef<HTMLDivElement>(null);
  const waveSurferRef = useRef<Nullable<WaveSurfer>>(null);
  const volumeRef = useRef<HTMLInputElement>(null);
  const playBtnRef = useRef<HTMLButtonElement>(null);

  const { openModal } = useModal();

  const openHotKeysPlayerModal = useCallback(
    () =>
      openModal({
        key: Modals.hotKeysPlayer,
      }),
    []
  );

  const togglePlay = useCallback(() => {
    waveSurferRef.current?.playPause();

    playBtnRef.current?.blur();

    setIsPlaying(waveSurferRef.current?.isPlaying() || false);
  }, [waveSurferRef.current]);

  const handleVolume = useCallback(
    e => {
      const newProgress = isNumber(e)
        ? e
        : isBoolean(e)
        ? normilizeVolume(volume + (e ? VOLUME_STEP : -VOLUME_STEP))
        : +e.target.value;

      waveSurferRef.current?.setVolume(newProgress);
      setVolume(newProgress);
    },
    [waveSurferRef.current, volumeRef.current, volume]
  );

  const handleStoreVolume = useCallback(() => {
    localStorage.setItem('player_volume', `${volume}`);
  }, [volume]);

  const handleMute = useCallback(() => {
    const newVolume = !!volume ? MIN_VOLUME : MAX_VOLUME;

    setVolume(newVolume);
    waveSurferRef.current?.setVolume(newVolume);
  }, [volume]);

  usePlayerShortKeys({
    player: waveSurferRef.current,
    handleMute,
    togglePlay,
    playSong,
    handleVolume,
  });

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      ...WAVE_CONFIG,
      progressColor: appColor(),
      waveColor: getWaveColor(),
      container: containerRef.current as HTMLElement,
    });

    waveSurfer.load(audio);
    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer;

      setReady(true);
      waveSurfer.play();
      handleVolume(storedVolume);
    });
    waveSurfer.on('finish', () => {
      setIsPlaying(false);
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  useEffect(() => {
    setIsSongPaused(!isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    handleStoreVolume();
  }, [volume]);

  useEffect(() => {
    const htmlCollection = [].slice.call(document.getElementsByClassName('viboScrollbar'));

    handleScrollKeyPress(htmlCollection, true);

    return () => {
      handleScrollKeyPress(htmlCollection, false);
    };
  }, []);

  return (
    <>
      <div className="info">
        <span className="artist" title={artist}>
          {artist}
        </span>
        <span className="name" title={name}>
          {name}
        </span>
      </div>
      <Button ref={playBtnRef} onClick={togglePlay} loading={!ready} className="playBtn">
        <Icon icon={isPlaying ? PauseLgIcon : PlayFilledLgIcon} />
      </Button>
      {!!waveSurferRef.current ? <SeekTime player={waveSurferRef.current} /> : null}
      <div className="progress" ref={containerRef} />
      <input
        ref={volumeRef}
        onChange={handleVolume}
        onKeyDown={e => e.preventDefault()}
        value={volume}
        min={MIN_VOLUME}
        max={MAX_VOLUME}
        step={VOLUME_STEP}
        className="volume"
        type="range"
        style={
          {
            '--volume-progress': volume,
          } as CSSProperties
        }
      />
      <Button onClick={openHotKeysPlayerModal} className={classNames('hotKeyBtn')}>
        <Icon icon={KeyBoardIcon} />
      </Button>
      <Button
        onClick={handleMute}
        className={classNames('volumeBtn', {
          noVolume: !volume,
          oneBar: volume <= HALF_VOLUME && !!volume,
        })}
      >
        <Icon icon={VolumeIcon} />
      </Button>
    </>
  );
};

export default SongPlayer;
