import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  inputErrors: {
    whiteSpace: 'nowrap',
    color: 'var(--error-main)',
    textAlign: 'right',
    fontSize: '12px',
    lineHeight: '19.84px',
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translateY(100%)',
  },
}));

export default useStyles;
