import React, { FC, useCallback, useContext } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';
import { EventContext } from 'components/context/EventContext';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';

import { GET_EVENT_GUEST_DEEP_LINK, GET_EVENT_HOST_DEEP_LINK } from 'graphql/queries/events';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventUserType, Modals } from 'types/enums';
import { AddEventContactModalProps } from 'components/modals/AddEventContactModal';
import { SendEventInviteModalProps } from 'components/modals/SendEventInviteModal';

const AddPeopleButton: FC = () => {
  const { t } = useTranslation();

  const { event, canAddGuest, isEventHost, isEventDj, isHistory } = useContext(EventContext);

  const { id: eventId } = useParams<EventPageRouteParams>();

  const [, copyToClipboard] = useCopyToClipboard();

  const { openModal } = useModal();

  const openAddEventContactModal = useCallback(
    () =>
      openModal<AddEventContactModalProps>({
        key: Modals.addEventContact,
        props: {
          values: {
            role: EventUserType.host,
            emailMessage: event?.emailMessage,
          },
        },
      }),
    [event?.emailMessage]
  );
  const openSendEventInviteModal = useCallback(
    () =>
      openModal<SendEventInviteModalProps>({
        key: Modals.sendEventInvite,
        props: {
          eventId,
          values: {
            emailMessage: event?.emailMessage,
          },
        },
      }),
    [eventId, event?.emailMessage]
  );

  const hostDeepLinkData = useQuery(GET_EVENT_HOST_DEEP_LINK, {
    variables: {
      eventId,
    },
    onError,
  });
  const guestDeepLinkData = useQuery(GET_EVENT_GUEST_DEEP_LINK, {
    variables: {
      eventId,
    },
    onError,
  });

  const hostDeepLink = get(hostDeepLinkData.data, 'getHostDeepLink');
  const guestDeepLink = get(guestDeepLinkData.data, 'getGuestDeepLink');

  const handleGetHostDeepLink = useCallback(() => {
    copyToClipboard(hostDeepLink);
    toastNotify({
      text: t(`${EventUserType.host}LinkCopiedSuccessfully`),
    });
  }, [hostDeepLink]);
  const handleGetGuestDeepLink = useCallback(() => {
    copyToClipboard(guestDeepLink);
    toastNotify({
      text: t(`${EventUserType.guest}LinkCopiedSuccessfully`),
    });
  }, [guestDeepLink]);

  const menuItems = [
    isEventDj ? (
      <Menu.Item
        onClick={openAddEventContactModal}
        icon={IconmoonFont['userV2-16']}
        key="add-contact"
      >
        {t('addContact')}
      </Menu.Item>
    ) : null,
    isEventDj ? (
      <Menu.Item
        onClick={openSendEventInviteModal}
        icon={IconmoonFont['email-16']}
        key="invite-email"
      >
        {t('inviteByEmail')}
      </Menu.Item>
    ) : null,
    isEventHost || isEventDj ? (
      <Menu.Item
        onClick={handleGetHostDeepLink}
        disabled={hostDeepLinkData.loading}
        icon={IconmoonFont['link-16']}
        key="copy-host"
      >
        {t('copyHostLink')}
      </Menu.Item>
    ) : null,
    canAddGuest ? (
      <Menu.Item
        onClick={handleGetGuestDeepLink}
        disabled={guestDeepLinkData.loading}
        icon={IconmoonFont['link-16']}
        key="copy-guest"
      >
        {t('copyGuestLink')}
      </Menu.Item>
    ) : null,
  ].filter(Boolean);

  return isHistory || !menuItems.length ? null : (
    <Dropdown
      overlay={<Menu selectable={false}>{menuItems}</Menu>}
      trigger="click"
      disableToggleClass
    >
      <Button prefixIcon={IconmoonFont['plus-16']} displayType="bordered" shape="round" size="lg">
        {t('addPeople')}
      </Button>
    </Dropdown>
  );
};

export default AddPeopleButton;
