import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songsGuestView: {
    '& .ReactVirtualized__List': {
      height: 'fit-content!important',
    },

    '& .song': {
      '& .viboImage': {
        width: '28px',
        height: '28px',

        '@media screen and (min-width: 1441px)': {
          width: '36px',
          height: '36px',
        },

        '& img': {
          width: 'inherit',
          height: 'inherit',
        },
      },

      '& .songArtistNames ': {
        margin: '0 auto 0 0',
      },
    },
  },

  songsDjView: {
    '&:not(.isReorderingEnabled)': {
      '& .dndSongIcon': {
        display: 'none',
      },
    },
  },

  songViewForGuest: {
    '& .timelineSongModalHeader': {
      paddingTop: '15px',
    },

    '& .songIframeWithLinks': {
      marginTop: '10px',

      '@media screen and (min-width: 1441px)': {
        marginTop: '20px',
      },
    },

    '& .links': {
      marginTop: '20px',

      '@media screen and (min-width: 1441px)': {
        marginTop: '12px',
      },
    },
  },

  isReordering: {
    opacity: '0.7',
    pointerEvents: 'none',
  },
}));

export default useStyles;
