import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  customNotificationsModal: {
    '& .modalBody': {
      '& .modalBodyScroll': {
        padding: '26px 36px',

        '& > h3': {
          color: 'var(--text-secondary)',
          fontSize: '15px',
          lineHeight: '22px',
          margin: '24px 0 16px 0',

          '&:first-of-type': {
            margin: '0 0 16px 24px',
          },

          '@media screen and (min-width: 1441px)': {
            fontSize: '20px',
            lineHeight: '26px',
          },
        },

        '& > .contentArea': {
          padding: '14px 20px',

          '&.message': {
            padding: '20px',

            '@media screen and (min-width: 1441px)': {
              padding: '24px',
            },
          },

          '&.row': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '12px',

            '& > label': {
              display: 'flex',
              gap: '6px',

              '@media screen and (min-width: 1441px)': {
                gap: '8px',
              },
            },

            '& > .viboIcon:first-of-type': {
              color: 'var(--text-secondary)',
            },

            '& > .error': {
              position: 'relative',
              marginLeft: 'auto',
            },

            '& .viboInputWrapper': {
              width: 'fit-content',

              '& .viboInput[type="number"]': {
                maxWidth: '38px',

                '@media screen and (min-width: 1441px)': {
                  maxWidth: '42px',
                },
              },
            },

            '& .viboSelect__control': {
              height: 'auto',
            },

            '@media screen and (min-width: 1441px)': {
              gap: '16px',
            },
          },

          '& .inputLabel': {
            '&:not(:first-of-type)': {
              marginTop: '24px',
            },
          },

          '@media screen and (min-width: 1441px)': {
            padding: '16px 24px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          padding: '36px 48px',
        },
      },
    },
  },

  selectTimeSettingMenu: {
    '& .viboScrollbar': {
      maxHeight: '100px',

      '@media screen and (min-width: 1441px)': {
        maxHeight: '150px',
      },
    },
  },
}));

export default useStyles;
