export const getIds = <T extends Nullable<{ _id?: string; id?: string }>>(array: T[]): string[] => {
  return array.map(item => item?._id ?? item?.id ?? '');
};

export const concatIdsWithIndex = (ids: string[]): string[] => {
  return ids.map((id, idx) => `${id}[${idx}`);
};

export const splitIdsWithIndex = (ids: string[]): string[] => {
  return ids.map(id => {
    const idx = id.indexOf('[');

    return id.slice(0, idx);
  });
};

export const chunkIntoN = <T extends {}>(array: T[], n: number): T[][] => {
  const size = Math.ceil(array.length / n);

  return Array.from({ length: n }, (v, i) => array.slice(i * size, i * size + size));
};

export const combineChunks = <T extends {}>(chunks1: T[][], chunks2: T[][]): T[][] => {
  const maxLength = Math.max(chunks1.length, chunks2.length);

  return Array.from({ length: maxLength }, (_, i) => [
    ...(chunks1[i] || []),
    ...(chunks2[i] || []),
  ]);
};
