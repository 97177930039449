import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  sectionName: {
    '&': {
      ...mixins.longText,
    },

    maxWidth: '180px',
  },

  scrollbar: {
    width: '225px',
    maxHeight: '200px',
  },
}));

export default useStyles;
