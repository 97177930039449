import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  notesBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    userSelect: 'none',
  },

  noMenu: {
    opacity: 0,
    pointerEvents: 'none',
  },

  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 auto 0 0',
  },

  notesPresentIndicator: {
    width: '6px',
    height: '6px',
    backgroundColor: 'var(--text-hint)',
    borderRadius: '50%',
    transform: 'translateY(1px)',
    margin: 'auto auto auto  6px',

    '@media screen and (min-width: 1441px)': {
      transform: 'translateY(0px)',
    },
  },
}));

export default useStyles;
