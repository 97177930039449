import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  like: {
    width: '37px',

    '& .likeContent': {
      maxWidth: 'none',
      width: 'fit-content',
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '2px',

      //for gtm tracking propper target
      '& *': {
        pointerEvents: 'none',
      },
    },

    '&:hover': {
      '& svg': {
        fill: 'var(--background-paper)',
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '44px',
    },
  },

  likedByMe: {
    color: 'var(--error-light)',
  },

  defaultLiked: {
    color: 'var(--text-hint)',
  },

  likesCount: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
}));

export default useStyles;
