import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  logoContainer: {
    minHeight: '44px',
    borderRadius: 0,
    backgroundColor: 'var(--background-paper)',

    '& *': {
      borderRadius: 0,
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '56px',
    },
  },
}));

export default useStyles;
