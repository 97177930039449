import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  colorfulSectionWrapper: {
    '& .section': {
      '&.headline .sectionTop': {
        '& .textPreview , & .viboInput, & .viboTextarea': {
          'body[theme="dark"] &': {
            color: 'var(--section-dark-color)',
          },

          'body[theme="light"] &': {
            color: 'var(--section-light-color)',
          },
        },
      },

      '&:not(.selected):not(.headline)': {
        overflow: 'hidden',

        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: '2px',
        },
      },

      '&:not(.selected):not(.headline):not(.dontPlay)': {
        '&:before': {
          'body[theme="dark"] &': {
            backgroundColor: 'var(--section-dark-color)',
          },

          'body[theme="light"] &': {
            backgroundColor: 'var(--section-light-color)',
          },
        },
      },
    },
  },
}));

export default useStyles;
