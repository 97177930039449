import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import IconButton from 'components/buttons/IconButton';
import WithEventFeature from 'components/user/WithEventFeature';
import { EventContext } from 'components/context/EventContext';
import {
  QuestionActionsContext,
  QuestionContext,
  TimelineQuestionsContext,
} from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';
import { isQuestionHeader } from 'services/questions/helpers';
import { useEditable } from 'components/common/EditableContext';

import { ButtonDecor } from 'vibo-ui/Button';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { CreateQuestionModalProps } from 'components/modals/CreateQuestionModal';
import { ButtonIconType, Modals, TierFeatueName } from 'types/enums';

import useIconButtonStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const Tools: FC = () => {
  const iconButtonDecClasses = useIconButtonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const eventContext = useContext(EventContext);
  const { question } = useContext(QuestionContext);
  const { values, setFieldValue } = useFormikContext<UpdateQuestionForm>();
  const timelineQuestionsContext = useContext(TimelineQuestionsContext);
  const { deleteQuestion, cloneQuestion } = useContext(QuestionActionsContext);

  const { canEdit } = useEditable();
  const { openModal } = useModal();

  const isNotifyOn = values.settings.notifyMe;

  const isDescription = isQuestionHeader(values.settings.type);

  const openCreateQuestionModal = () => {
    openModal<CreateQuestionModalProps>({
      key: Modals.createQuestion,
      props: {
        questionId: question._id,
        data: question,
        timelineQuestionsContext,
        eventContext,
      },
    });
  };
  const openDeleteQuestionModal = () =>
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('removeThisQuestion'),
        children: t('sureYouWantDelete'),
        submit: {
          text: t('remove'),
          onClick: handleRemoveQuestion,
        },
      },
    });

  const handleRemoveQuestion = () => {
    deleteQuestion(question._id);
  };

  const toggleNotifyMe = debounce(() => {
    setFieldValue('settings.notifyMe', !isNotifyOn);
  }, 500);

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      className={classNames('tools', classes.tools)}
    >
      {canEdit ? (
        <>
          <IconButton
            type={ButtonIconType.edit}
            placement="top"
            title={t('editQuestion')}
            className={classNames(iconButtonDecClasses.highlighted)}
            onClick={e => {
              e.stopPropagation();

              openCreateQuestionModal();
            }}
          />
          <IconButton
            type={ButtonIconType.clone}
            title={t('clone')}
            onClick={e => {
              e.stopPropagation();
              cloneQuestion(question._id);
            }}
            className={classNames(iconButtonDecClasses.highlighted)}
            placement="top"
          />
          <IconButton
            type={ButtonIconType.delete}
            title={t('remove')}
            onClick={e => {
              e.stopPropagation();
              openDeleteQuestionModal();
            }}
            className={classNames(iconButtonDecClasses.highlighted, ButtonDecor.danger)}
            placement="top"
          />
        </>
      ) : null}

      <WithEventFeature feature={TierFeatueName.notify_me} extraCondition={!isDescription}>
        <IconButton
          type={
            isNotifyOn ? ButtonIconType.notifyMeBellFilled : ButtonIconType.notifyMeBellOutlined
          }
          title={t(
            question.isAnswered
              ? 'questionAlreadyAnswered'
              : isNotifyOn
              ? 'dontNotifyMe'
              : 'notifyMeAboutAnswer'
          )}
          onClick={e => {
            e.stopPropagation();
            toggleNotifyMe();
          }}
          className={classNames(iconButtonDecClasses.highlighted, {
            visible: isNotifyOn,
          })}
          placement="top"
          disabled={!canEdit || question.isAnswered}
        />
      </WithEventFeature>
    </div>
  );
};

export default Tools;
