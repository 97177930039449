import React, { FC, createContext, useContext, useState } from 'react';

import { UserContext } from '../UserContext';
import { WizardContext } from 'vibo-ui/Wizard/WizardStepContext';
import { ModalWindowContextProvider } from 'vibo-ui/Modal/ModalWindowContext';

import { useEventColor } from 'graphql/hooks/event';

import { DeepLinkWizardSteps } from 'components/common/DeepLinkWizard/interfaces';
import { HeadingWithImageData } from 'components/common/HeadingWithImage/interfaces';

type TDeepLinkContext = {
  action?: Nullable<Function>;
  goLogin?: Function;
  previewData?: HeadingWithImageData;
  setSkipLogin?: (value: boolean) => void;
};

export const DeepLinkContext = createContext<TDeepLinkContext>(undefined!);

export const DeepLinkContextProvider: FC<TDeepLinkContext> = ({
  action,
  children,
  previewData,
}) => {
  const wizard = useContext(WizardContext);
  const { isLoggedIn } = useContext(UserContext);

  const [skipLogin, setSkipLogin] = useState(false);

  const goLogin = () => {
    wizard?.wizardRefProps?.goToNamedStep(DeepLinkWizardSteps.login);
  };

  useEventColor({
    event: previewData as EventItem,
  });

  return (
    <DeepLinkContext.Provider
      value={{
        goLogin,
        setSkipLogin,
        previewData,
        action: !!action ? (isLoggedIn || skipLogin ? action : goLogin) : () => null,
      }}
    >
      <ModalWindowContextProvider>{children}</ModalWindowContextProvider>
    </DeepLinkContext.Provider>
  );
};
