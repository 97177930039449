import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songIdeasList: {
    position: 'relative',
    display: 'grid',
    gridGap: '12px',
    gridTemplateColumns: 'repeat(5, minmax(auto,1fr))',

    '@media screen and (min-width: 1441px)': {
      gridGap: '16px',
      gridTemplateColumns: 'repeat(6, minmax(auto,1fr))',
    },
  },

  scrollbar: {
    maxHeight: 'calc(100% - 20px)',

    '@media screen and (min-width: 1441px)': {
      maxHeight: 'calc(100% - 20px)',
    },
  },

  playlistCards: {
    '& .playlistCard': {
      maxWidth: '166px',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '195px',
      },
    },
  },

  cardMiddle: {
    width: '100%',
    height: '154px',
    position: 'relative',

    '& img': {
      objectFit: 'cover',
    },

    '@media screen and (min-width: 1441px)': {
      height: '179px',
    },

    '& > .viboIcon': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      color: 'var(--background-paper)',
      fontSize: '48px',
    },
  },

  cardBottom: {
    margin: 'auto 0 0 0',
    justifyContent: 'space-between',
    color: 'var(--text-secondary)',
    lineHeight: '16px',
    fontSize: '11px',

    '&, & .songsCount': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '& .iconButton': {
      color: 'var(--primary-main)',
      margin: '0 -2px -2px 0',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '14px',
    },
  },

  tooltipMenu: {
    '& .rc-tooltip-inner': {
      border: 'none!important',
      padding: 0,
    },
  },

  cardAnimate: {
    border: `2px solid var(--primary-main)!important`,
    boxShadow: 'var(--shadows-0)',

    '&:hover': {
      boxShadow: 'var(--shadows-0)',
    },
  },

  playlistCard: {
    cursor: 'pointer',
    padding: '6px 6px 4px 6px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    userSelect: 'none',

    '&.v2': {
      backgroundColor: 'var(--background-paper)',

      '&:hover': {
        backgroundColor: 'var(--background-default)',
      },
    },

    '& .songsCount': {
      color: 'var(--text-secondary)',

      '& .viboIcon': {
        margin: '0 4px 0 0',
      },
    },

    '& .ellipsis': {
      color: 'var(--primary-main)',

      '& svg': {
        width: '14px',
        height: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },

      '&:hover': {
        '& path': {
          fill: 'var(--action-hover)',
        },
      },
    },

    '& .songIdeasTitle': {
      height: '31px',
      margin: '4px 0 7px 0',
      fontWeight: 500,
      paddingTop: '2px',
      fontSize: '11px',
      lineHeight: '15.4px',
      color: 'var(--text-primary)',
      wordWrap: 'break-word',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',

      '@media screen and (min-width: 1441px)': {
        height: '38px',
        margin: '6px 0 5px 0',
        fontSize: '14px',
        lineHeight: '19.6px',
      },
    },

    '&:hover': {
      backgroundColor: 'var(--sectionHover)',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '8px',
    },
  },

  publicLabel: {
    zIndex: 2,
    position: 'absolute',
    top: '10px',
    left: '10px',

    '@media screen and (min-width: 1441px)': {
      top: '12px',
      left: '12px',
    },
  },
}));

export default useStyles;
