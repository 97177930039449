import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useSearchParam } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';
import { useMutation, useQuery } from '@apollo/client';

import SignInWithLink from 'components/auth/SignInWithLink';
import HeadingWithImage from 'components/common/HeadingWithImage';
import DeepLinkActionButton from 'components/auth/SignInWithLink/DeepLinkActionButton';
import JoinByDeepLinkPlaceholder from 'components/Placeholders/JoinByDeepLinkPlaceholder';
import { WizardState } from 'vibo-ui/Wizard/WizardStepContext';
import { DeepLinkContextProvider } from 'components/context/DeepLinkContext';

import { onError } from 'graphql/helpers';

import { JOIN_EVENT_VIA_DEEP_LINK, JOIN_EVENT_VIA_HASH } from 'graphql/mutations/events';
import { GET_EVENT_DEEP_LINK_PREVIEW, GET_EVENT_PREVIEW_VIA_LINK } from 'graphql/queries/events';

const JoinEventPage: FC = () => {
  const { t } = useTranslation();

  const { push } = useHistory();

  const deepLink = useSearchParam('url') || '';
  const [eventId] = useQueryParam('eventId');
  const [hash] = useQueryParam('hash');

  // SUPPORTING OLD VERSION OF DEEPLINKS BASED ON 'url' PARAMETER IN THE LINK
  const { data: linkEventData } = useQuery<GetEvenPreviewViaLink>(GET_EVENT_PREVIEW_VIA_LINK, {
    variables: {
      deepLink,
    },
    skip: !deepLink || !!hash,
  });
  const { data } = useQuery<GetEvenPreview>(GET_EVENT_DEEP_LINK_PREVIEW, {
    variables: {
      eventId,
    },
    skip: !eventId,
  });

  const event =
    data?.getEventPreview || (linkEventData?.getEvenPreviewViaLink as EventDeepLinkPreview);

  const previewData = {
    title: event?.title,
    photo: { url: event?.image.squareUrl, token: event?._id },
    ...(event?.isBrandingAllowed && {
      eventColors: event?.eventColors,
    }),
  };

  const [joinEventViaDeepLink, { loading: isJoiningViaLink }] = useMutation(
    JOIN_EVENT_VIA_DEEP_LINK,
    {
      variables: {
        deepLink,
      },
      onCompleted: () => {
        push(`/event/${event?._id}/general`);
      },
      onError,
    }
  );
  const [joinEventViaHash, { loading: isJoiningViaHash }] = useMutation(JOIN_EVENT_VIA_HASH, {
    variables: {
      hash,
    },
    onCompleted: () => {
      push(`/event/${event?._id}/general`);
    },
    onError,
  });

  const handleStartPlanning = () => {
    !!deepLink && joinEventViaDeepLink();
    !!hash && joinEventViaHash();
  };

  return (
    <WizardState>
      <DeepLinkContextProvider
        action={!!eventId || !!deepLink ? handleStartPlanning : null}
        previewData={previewData}
      >
        <SignInWithLink>
          {!!event ? <HeadingWithImage {...previewData} /> : <JoinByDeepLinkPlaceholder />}
          <DeepLinkActionButton loading={!event || isJoiningViaLink || isJoiningViaHash}>
            {t('startPlanning')}
          </DeepLinkActionButton>
        </SignInWithLink>
      </DeepLinkContextProvider>
    </WizardState>
  );
};

export default JoinEventPage;
