import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  signInWithLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',

    '& .formContainer': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '16px 36px 36px 36px',

      '@media screen and (min-width: 1441px)': {
        padding: '30px 48px 48px 48px',
      },
    },

    '& .viboButton': {
      width: '100%',
      margin: '6px 0 0 0',
      textAlign: 'center',

      '@media screen and (min-width: 1441px)': {
        margin: '8px 0 0 0',
      },
    },
  },

  welcomeLogo: {
    color: 'var(--primary-main)',
    margin: '0 0 12px 0',

    '& svg': {
      width: '176px',
      height: '23px',

      '@media screen and (min-width: 1441px)': {
        width: '273px',
        height: '32px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 21px 0',
    },
  },
}));

export default useStyles;
