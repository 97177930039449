import React from 'react';
import * as Yup from 'yup';

import Button from 'vibo-ui/Button';
import Linkify from 'components/common/Linkify';

import { IconmoonFont } from 'vibo-ui/Icon';
import { QuestionContactResources } from 'types/enums';

export const EMPTY_CONTACT_ANSWER = {
  name: null,
  title: null,
  resources: [],
};

export const RESOURCE_ICON_BY_TYPE = {
  [QuestionContactResources.phone]: IconmoonFont['phone-16'],
  [QuestionContactResources.email]: IconmoonFont['email-16'],
  [QuestionContactResources.link]: IconmoonFont['link-16'],
};

export const RESOURCE_WRAPPER_BY_TYPE = {
  [QuestionContactResources.phone]: (resource: string) => (
    <Button href={`tel:${resource}`} displayType="link">
      {resource}
    </Button>
  ),
  [QuestionContactResources.email]: (resource: string) => <Linkify>{resource}</Linkify>,
  [QuestionContactResources.link]: (resource: string) => <Linkify>{resource}</Linkify>,
};

export const getContactResourceType = (resource: string) => {
  const isEmail = Yup.string()
    .email()
    .isValidSync(resource);

  const isPhone = /^\+?[1-9]\d{1,20}$/.test(resource || '');

  if (isEmail) {
    return QuestionContactResources.email;
  }

  if (isPhone) {
    return QuestionContactResources.phone;
  }

  return QuestionContactResources.link;
};
