import { createUseStyles } from 'react-jss';

import { GetTooltipColors } from './interfaces';

const getTooltipBase = ({ textColor, fillTextColor, bgColor }: GetTooltipColors) => ({
  '& .rc-tooltip-inner': {
    color: textColor,
    border: `1px solid ${bgColor}`,
  },

  '&.fillOverlay': {
    '& .rc-tooltip-inner': {
      backgroundColor: bgColor,
      ...(!!fillTextColor && {
        color: fillTextColor,
      }),
    },
  },
});

const useStyles = createUseStyles(() => ({
  viboTooltipOvelay: {
    opacity: 1,
    zIndex: 1000,
    maxWidth: '400px',

    '& .rc-tooltip-arrow': {
      display: 'none',
    },

    '&.fillOverlay': {
      '& .rc-tooltip-inner': {
        color: 'var(--text-primary)',
      },
    },

    '& .withSizeLimit': {
      maxWidth: '250px',
    },

    '& .rc-tooltip-inner': {
      boxShadow: 'var(--shadows-1)',
      padding: '2px 4px',
      fontSize: '11px',
      lineHeight: '16px',
      minHeight: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--background-paper)',
      color: 'var(--primary-main)',
      borderRadius: '4px',

      '@media screen and (min-width: 1441px)': {
        minHeight: '28px',
        padding: '2px 6px',
        fontSize: '14px',
        lineHeight: '21px',
      },
    },

    '&.emptyDisplay': {
      backgroundColor: 'transparent',

      '& .rc-tooltip-inner': {
        backgroundColor: 'transparent',
        border: `none`,
        padding: '0',
        minHeight: 'auto',
      },
    },

    '&.defaultDisplay': {
      '& .rc-tooltip-inner': {
        border: `1px solid var(--background-paper)`,
      },
    },

    '&.primaryDisplay': {
      ...getTooltipBase({
        fillTextColor: 'var(--whiteToBlack)',
        textColor: 'var(--text-primary)',
        bgColor: 'var(--text-primary)',
      }),
    },

    '&.successDisplay': {
      ...getTooltipBase({
        textColor: 'var(--success-main)',
        bgColor: 'var(--success-main)',
      }),
    },

    '&.dangerDisplay': {
      ...getTooltipBase({
        textColor: 'var(--error-main)',
        bgColor: 'var(--error-main)',
      }),
    },

    '&.warningDisplay': {
      ...getTooltipBase({
        textColor: 'var(--warning-main)',
        bgColor: 'var(--warning-main)',
      }),
    },

    '&.blackDisplay': {
      ...getTooltipBase({
        textColor: 'var(--text-primary)',
        bgColor: 'var(--text-primary)',
        fillTextColor: 'var(--clr-white)',
      }),
    },

    '&.whiteDisplay': {
      ...getTooltipBase({
        textColor: 'var(--clr-black)',
        bgColor: 'var(--clr-white)',
        fillTextColor: 'var(--clr-black)',
      }),
    },

    '@media screen and (min-width: 1441px)': {
      maxWidth: '600px',
    },
  },
}));

export default useStyles;
