import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  pageContentHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '19px 0',

    '& .eventTitle': {
      margin: '0',
    },

    '& .viboInput.disabled': {
      color: `var(--text-primary)!important`,
    },

    '@media screen and (min-width: 1441px)': {
      margin: '24px 0',
    },
  },

  extra: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 0 0 10px',
    margin: '0 0 0 auto',
    gap: '6px',

    '@media screen and (min-width: 1441px)': {
      gap: '8px',
    },
  },
}));

export default useStyles;
