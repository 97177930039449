import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import './i18n';
import 'react-virtualized/styles.css';
import 'resources/styles/main.css';

const render = (App: FC) =>
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root') as HTMLElement
  );

render(App);
