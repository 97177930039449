import React, { FC, useRef } from 'react';
import classNames from 'classnames';

import { ScrollbarProps } from './interfaces';

import useStyles from './style';

const Scrollbar: FC<ScrollbarProps> = ({
  className,
  id,
  alwaysShowThumb,
  children,
  onScroll,
  disableKeyScrolling = false,
  shiftThumbStyle = 'keepBoxShadow',
}) => {
  const classes = useStyles();

  const scrollRef = useRef<Nullable<HTMLDivElement>>(null);

  return (
    <div
      className={classNames('viboScrollbar', shiftThumbStyle, classes.viboScrollbar, className, {
        alwaysShowThumb,
      })}
      onScroll={e => {
        onScroll?.(e.currentTarget.scrollTop);
      }}
      tabIndex={0}
      id={id}
      ref={scrollRef}
      {...(disableKeyScrolling
        ? {
            onKeyDown: e => e.preventDefault(),
            onKeyUp: e => e.preventDefault(),
          }
        : {})}
    >
      {children}
    </div>
  );
};

export default Scrollbar;
