import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  eventsList: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .viboScrollbar': {
      '& .virtualized-row > a': {
        textDecoration: 'none',
      },
    },

    '& .simplebar-content > div:first-child': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    '& a': {
      color: 'var(--text-primary)',

      '&:hover': {
        '& > div': {
          backgroundColor: 'var(--sectionHover)',
        },

        '& .newStatus:after': {
          background: 'var(--primary-light)',
        },
      },
    },
  },

  eventsLoading: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 999,
  },

  eventsWrap: {
    width: '100%',
    display: 'flex',
    alignSelf: 'center',
  },

  events: {
    '& .label': {
      color: 'var(--text-secondary)',
    },
  },

  eventRow: {
    cursor: 'pointer',
  },

  eventsTable: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    width: '100%',
    paddingTop: '11px',
  },

  eventItem: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '4px',
    boxShadow: 'var(--shadows-3)',

    '&:hover': {
      boxShadow: 'var(--shadows-8)',
      cursor: 'pointer',
    },
  },

  eventHeader: {
    boxShadow: 'var(--shadows-3)',
    borderRadius: '4px 4px 0 0',
  },
}));

export default useStyles;
