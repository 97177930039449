import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';

import { useModal } from 'vibo-ui/Modal';
import { appLightColor } from 'components/context/ViboThemeContext/constants';

import { Modals } from 'types/enums';
import { PickBrandingColorModalProps } from 'components/modals/PickBrandingColorModal/interfaces';
import { PickEventColorButtonProps } from './interfaces';

const PickEventColorButton: FC<PickEventColorButtonProps> = ({
  handleUpdateColor,
  eventColors,
  children,
  modalClassName,
  ...rest
}) => {
  const { t } = useTranslation();

  const { openedModals, openModal } = useModal();

  const openPickBrandingColorModal = useCallback(
    () =>
      openModal<PickBrandingColorModalProps>({
        key: Modals.pickBrandingColor,
        props: {
          resetToColor: eventColors.defaultEventLightModeColor,
          defaultColor: appLightColor(),
          title: children || t('eventColor'),
          handleUpdateColor,
          className: modalClassName,
        },
      }),
    [openedModals, eventColors.defaultEventLightModeColor, modalClassName]
  );

  return (
    <Button
      onClick={openPickBrandingColorModal}
      displayType="link"
      shape="round"
      size="lg"
      {...rest}
    >
      {children || t('eventColor')}
    </Button>
  );
};

export default PickEventColorButton;
