import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  errorPage: {
    padding: '24px',
    fontSize: '15px',
    lineHeight: '21.25px',
    textAlign: 'center',

    '& .viboIcon': {
      margin: '24px 0 0 0',
      color: 'var(--error-main)',

      '& svg': {
        width: '200px',
        height: '200px',

        '@media screen and (min-width: 1441px)': {
          width: '256px',
          height: '256px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        margin: '40px 0 0 0',
      },
    },

    '& .homeBtn': {
      margin: '30px 0 0 0',
      width: '100%',

      '@media screen and (min-width: 1441px)': {
        margin: '50px 0 0 0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '18px',
      lineHeight: '25.5px',
    },
  },
}));

export default useStyles;
