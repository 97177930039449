import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  isMustPlay: {
    '& svg path': {
      fill: 'var(--clr-corn)',
    },
  },
}));

export default useStyles;
