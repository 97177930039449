import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  djSortPreview: {
    '& .viboImage': {
      ...mixins.getStaticIconSize?.(16),
    },

    '& .djName': {
      color: 'inherit',
    },
  },
}));

export default useStyles;
