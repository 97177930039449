import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboTextareaWrapper: {
    '&.errorsPresent .withBorder': {
      '&:hover, &:focus, &:active': {
        border: `1px solid var(--error-main)`,
      },
    },
  },

  viboTextarea: {
    padding: '8px 11px',
    width: '100%',
    maxWidth: '100%',
    resize: 'none',
    fontSize: '13px',
    overflow: 'auto',
    color: 'var(--text-primary)',
    backgroundColor: 'transparent',
    minHeight: '30px',
    lineHeight: '30px',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '&:focus-visible': {
      outline: 'none',
    },

    '&::placeholder': {
      color: 'var(--text-hint)',
    },

    '&.withUnderline': {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '36px',
      minHeight: '36px',
    },
  },
}));

export default useStyles;
