import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboImage: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',

    '&.isPickable': {
      cursor: 'pointer',
    },

    '& .intrinsic-item, & .gradient': {
      width: 'auto',
      height: 'inherit',
    },

    '& .pickPhotoIcon': {
      color: 'var(--primary-main)',
      position: 'absolute',
      top: '6px',
      right: '6px',
      pointerEvents: 'none',
      backgroundColor: 'rgba(255,255,255,.5)',
      borderRadius: '4px',
      padding: '4px',

      '@media screen and (min-width: 1441px) ': {
        top: '8px',
        right: '8px',
        padding: '6px',
      },
    },
  },

  imagePlaceholder: {
    objectFit: 'cover',
    backgroundColor: 'var(--background-photo)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--text-primary)',
  },

  removeImageBtn: {
    position: 'absolute',
    right: '15px',
    top: '15px',

    '&.viboButton': {
      padding: '6px',
      height: 'auto',
      border: 'none',
      backgroundColor: 'rgba(255,255,255,.5)',

      '&.defaultDisplay:hover,&:active,&:focus': {
        border: 'none',
      },
    },
  },
}));

export default useStyles;
