import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  commentText: {
    '& .rc-tooltip-inner': {
      color: 'var(--text-primary)',
    },
  },

  addCommentText: {
    '& .rc-tooltip-inner': {
      color: 'var(--text-hint)',
    },
  },

  songComment: {
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '16.55px',
    color: 'var(--text-primary)',
    wordBreak: 'break-all',
    position: 'relative',

    '&:not(.contentArea)': {
      margin: '0 8px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 12px',
      },
    },

    '&.contentArea': {
      padding: '8px',
    },

    '& .viboToggleDropdown': {
      boxShadow: 'none',
    },

    '& .viboTextareaWrapper': {
      margin: '0 0 22px 0',
      minWidth: '192px',
    },

    '& .viboTextarea': {
      fontWeight: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '14px',
      lineHeight: '21.07px',
    },
  },

  commentFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',

    '& .viboButton': {
      width: '50%',
      padding: '1.4px 5px',
    },
  },
}));

export default useStyles;
