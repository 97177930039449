import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboImage: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '4px',

    '& .cornerIcon': {
      display: 'none',
      position: 'absolute',
      top: '6px',
      right: '6px',
      pointerEvents: 'none',

      '& svg': {
        width: '30px',
        height: '30px',
        borderRadius: '4px',

        '@media screen and (min-width: 1441px) ': {
          width: '36px',
          height: '36px',
        },
      },

      '@media screen and (min-width: 1441px) ': {
        top: '8px',
        right: '8px',
      },
    },

    '&.photo': {
      '& .cornerIcon': {
        display: 'block',
      },
    },

    '& > .viboSpinner': {
      color: 'var(--primary-main)',
    },

    '& .intrinsic-item, & .gradient': {
      width: 'inherit',
      height: 'inherit',
    },
  },

  imagePlaceholder: {
    objectFit: 'cover',
    background: 'var(--button-disabled)',
  },

  fakeImage: {
    display: 'none',
  },
}));

export default useStyles;
