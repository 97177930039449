import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '@rehooks/local-storage';

import Menu from 'vibo-ui/Menu';
import SiderExtra from './SiderExtra';
import SupportMenu from './SupportMenu';
import SiderLogo from 'components/image/SiderLogo';
import SavingLoadingDataIndicator from 'components/common/SavingLoadingDataIndicator';

import { useMe } from 'graphql/hooks/user';
import { SONG_IDEAS_KEYS, MIN_WINDOW_WIDTH } from 'services/constants';
import { getSelectedMainMenuKeys } from 'services/common/routesHelper';

import { IconmoonFont } from 'vibo-ui/Icon';

import useStyles from './style';

const SiderLayout: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { push } = useHistory();
  const [isSiderClosed, setIsSiderClosed] = useLocalStorage<boolean>('isSiderClosed', false);

  const { isDj, isAdmin } = useMe();

  useEffect(() => {
    const handleWidnowResize = () => {
      if (!isSiderClosed && window.innerWidth < MIN_WINDOW_WIDTH) {
        setIsSiderClosed(true);
      } else {
        setIsSiderClosed(false);
      }
    };

    window.addEventListener('resize', handleWidnowResize);

    return () => {
      window.removeEventListener('resize', handleWidnowResize);
    };
  }, []);

  return (
    <div
      className={classNames(classes.sider, {
        isSiderClosed,
      })}
    >
      <div
        className={classNames({
          [classes.logoContainerCollapsed]: isSiderClosed,
          [classes.logoContainer]: !isSiderClosed,
        })}
      >
        <SiderLogo />
      </div>
      <Menu
        className={classNames(classes.menu, {
          collapsed: isSiderClosed,
          expanded: !isSiderClosed,
        })}
        getSelectedSidebarMenuKeys={getSelectedMainMenuKeys}
        styleModificator="siderLeft"
        selectedKeys={[]}
      >
        <Menu.Item
          onClick={() => push('/events/upcoming')}
          icon={IconmoonFont['events-24']}
          title={t('events')}
          key="/events/upcoming"
        >
          <span>{t('events')}</span>
        </Menu.Item>
        {isDj ? (
          <>
            {/* <Menu.Item
              onClick={() => push('/calendar')}
              icon={IconmoonFont['calendar-24']}
              title={t('calendar')}
              key="/calendar"
            >
              <span>{t('calendar')}</span>
            </Menu.Item> */}
            <Menu.Item
              onClick={() => push(`/songIdeas/${SONG_IDEAS_KEYS.me}`)}
              icon={IconmoonFont['lightBulb-24']}
              title={t('songIdeasAmount')}
              key={`/songIdeas/${SONG_IDEAS_KEYS.me}`}
            >
              <span>{t('songIdeasAmount')}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => push('/templates/user')}
              icon={IconmoonFont['templates-24']}
              title={t('templates')}
              key="/templates"
            >
              <span>{t('templates')}</span>
            </Menu.Item>
          </>
        ) : null}
        {isDj ? (
          <Menu.Item
            onClick={() => push('/scanner')}
            icon={IconmoonFont['scanner-24']}
            title={t('scanner')}
            key="/scanner"
          >
            <span>{t('scanner')}</span>
          </Menu.Item>
        ) : null}
        {isDj ? (
          <Menu.Item
            onClick={() => push('/academy')}
            icon={IconmoonFont['academy-24']}
            title={t('academy')}
            key="/academy"
          >
            <span>{t('academy')}</span>
          </Menu.Item>
        ) : null}
        <SupportMenu />
        {isAdmin ? (
          <>
            <Menu.Item
              onClick={() => push('/admin')}
              icon={IconmoonFont['settings-24']}
              title={t('admin')}
              key="/admin"
            >
              <span>{t('admin')}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => push('/health-check')}
              icon={IconmoonFont['settings-24']}
              key="/health-check"
              title="Health check"
            >
              <span>H Check</span>
            </Menu.Item>
          </>
        ) : null}
        <SavingLoadingDataIndicator />
      </Menu>
      <SiderExtra isClosed={isSiderClosed} toggleClose={setIsSiderClosed} />
    </div>
  );
};

export default SiderLayout;
