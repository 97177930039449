import { gql } from '@apollo/client';
import { SONG_IDEAS_RESPONSE } from '../fragments/songIdeas';
import { USER_PREVIEW_DATA } from '../fragments/user';

export const GET_VIBO_TEMPLATES = gql`
  query getViboTemplates(
    $filter: TemplatesFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    getViboTemplates(filter: $filter, pagination: $pagination, sort: $sort) {
      templates {
        title
        userId
        _id
        type
        canEdit
        isPublic
        createdAt
        updatedAt
        user {
          ...UserPreviewData
        }
        image {
          squareUrl
          rectUrl
          cropped {
            small
            medium
          }
        }
        isPro
      }
      totalCount
      next {
        skip
        limit
      }
    }
  }
  ${USER_PREVIEW_DATA}
`;

export const GET_COMPANY_TEMPLATES = gql`
  query getCompanyTemplates(
    $filter: TemplatesFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    getCompanyTemplates(filter: $filter, pagination: $pagination, sort: $sort) {
      templates {
        title
        userId
        _id
        type
        isPublic
        canEdit
        createdAt
        updatedAt
        image {
          squareUrl
          rectUrl
          cropped {
            small
            medium
          }
        }
        isPro
      }
      totalCount
      next {
        skip
        limit
      }
    }
  }
`;

export const GET_MY_TEMPLATES = gql`
  query getMyTemplates(
    $filter: TemplatesFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    getMyTemplates(filter: $filter, pagination: $pagination, sort: $sort) {
      templates {
        _id
        title
        userId
        type
        canEdit
        createdAt
        updatedAt
        isPublic
        image {
          squareUrl
          rectUrl
          cropped {
            small
            medium
          }
        }
        location {
          name
          lat
          lng
        }
        isPro
      }
      totalCount
      next {
        skip
        limit
      }
    }
  }
`;

export const GET_MY_TEMPLATES_ASSIGN_PLAYLIST = gql`
  query getMyTemplates(
    $filter: TemplatesFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    getMyTemplates(filter: $filter, pagination: $pagination, sort: $sort) {
      templates {
        _id
        title
        type
        createdAt
        image {
          squareUrl
          rectUrl
          cropped {
            small
            medium
          }
        }
      }
      totalCount
      next {
        skip
        limit
      }
    }
  }
`;

export const TEMPLATE_SECTIONS = gql`
  query templateSections($templateId: ID!, $filter: SectionsFilterInput) {
    templateSections(templateId: $templateId, filter: $filter) {
      _id
      name
      time
      note
      description
      type
      questionsCount
      answeredCount
      blocks {
        name
        isExpanded
      }
      visibility
      songIdeasCount
      settings {
        songsLimit
        mustPlayLimit
        canHostsOrderSongs
        canHostDeleteSection
        canHostChangeSectionName
        canHostChangeSectionTime
        canHostChangeSectionImage
        visibleForGuests
        visibleForHosts
        songsEnabled
        questionsEnabled
        notesEnabled
        timeEnabled
        notesVisibleForHosts
      }
      sectionImage {
        imageId
        imageUrl
      }
      headlineColors {
        customDarkModeHeadlineColor
        customLightModeHeadlineColor
        defaultLightModeHeadlineColor
        defaultDarkModeHeadlineColor
      }
    }
  }
`;

export const TEMPLATE_SECTIONS_NO_IMAGES_ALLOWED = gql`
  query templateSections($templateId: ID!, $filter: SectionsFilterInput) {
    templateSections(templateId: $templateId, filter: $filter) {
      _id
      name
      time
      note
      description
      type
      questionsCount
      answeredCount
      blocks {
        name
        isExpanded
      }
      visibility
      songIdeasCount
      settings {
        songsLimit
        mustPlayLimit
        canHostsOrderSongs
        canHostDeleteSection
        canHostChangeSectionName
        canHostChangeSectionTime
        canHostChangeSectionImage
        visibleForGuests
        visibleForHosts
        songsEnabled
        questionsEnabled
        notesEnabled
        timeEnabled
        notesVisibleForHosts
      }
      headlineColors {
        customDarkModeHeadlineColor
        customLightModeHeadlineColor
        defaultLightModeHeadlineColor
        defaultDarkModeHeadlineColor
      }
    }
  }
`;

export const TEMPLATE_SECTIONS_ASSIGN_PLAYLIST = gql`
  query templateSections($templateId: ID!, $filter: SectionsFilterInput) {
    templateSections(templateId: $templateId, filter: $filter) {
      _id
      name
      type
      visibility
      songIdeas
      headlineColors {
        customDarkModeHeadlineColor
        customLightModeHeadlineColor
        defaultLightModeHeadlineColor
        defaultDarkModeHeadlineColor
      }
    }
  }
`;

export const GET_TEMPLATE_SECTION_SONG_IDEAS = gql`
  query getTemplateSectionSongIdeas(
    $templateId: ID!
    $sectionId: ID!
    $pagination: PaginationInput
    $filter: SectionSongIdeasFilter
  ) {
    getTemplateSectionSongIdeas(
      templateId: $templateId
      sectionId: $sectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_TEMPLATE_SECTION_USER_SONG_IDEAS = gql`
  query getTemplateSectionUserSongIdeas(
    $templateId: ID!
    $sectionId: ID!
    $pagination: PaginationInput
    $filter: SectionSongIdeasFilter
  ) {
    getTemplateSectionUserSongIdeas(
      templateId: $templateId
      sectionId: $sectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_TEMPLATE_SECTION_VIBO_SONG_IDEAS = gql`
  query getTemplateSectionViboSongIdeas(
    $templateId: ID!
    $sectionId: ID!
    $pagination: PaginationInput
    $filter: SectionSongIdeasFilter
  ) {
    getTemplateSectionViboSongIdeas(
      templateId: $templateId
      sectionId: $sectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_TEMPLATE_SECTION_COMPANY_SONG_IDEAS = gql`
  query getTemplateSectionCompanySongIdeas(
    $templateId: ID!
    $sectionId: ID!
    $pagination: PaginationInput
    $filter: SectionSongIdeasFilter
  ) {
    getTemplateSectionCompanySongIdeas(
      templateId: $templateId
      sectionId: $sectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_TEMPLATE = gql`
  query getTemplate($templateId: ID!) {
    getTemplate(templateId: $templateId) {
      _id
      title
      typeId
      userId
      canEdit
      type
      isPublic
      deepLink
      user {
        ...UserPreviewData
      }
      image {
        squareUrl
        rectUrl
        cropped {
          small
          medium
        }
      }
      daysCountBeforeLock
      location {
        name
        lat
        lng
      }
      arrivalTime
      startTime
      endTime
      settings {
        canHostsInviteGuests
        canGuestsInviteGuests
        canHostCreateSections
        canHostReorderSections
        canHostChangeHeadlineColors
        sectionSongsLimit
        sectionMustPlayLimit
        canHostChangeEventColor
        canHostAddSectionImages
        canHostChangeEventCoverPhoto
        disableAllPushNotifications
        disabledNotifications
      }
      emailMessage {
        host
        guest
      }
      textMessage {
        host
        guest
      }
      eventColors {
        customEventLightModeColor
        customEventDarkModeColor
        defaultEventLightModeColor
        defaultEventDarkModeColor
      }
      allowedFeatures {
        _id
        name
        createdAt
      }
      isPro
      customNotificationsCount
    }
  }
  ${USER_PREVIEW_DATA}
`;

export const GET_TEMPLATES_COUNT = gql`
  query getTemplatesCount($filter: TemplatesFilterInput) {
    getTemplatesCount(filter: $filter) {
      vibo
      company
      user
    }
  }
`;

export const GET_TEMPLATE_TYPES_STATS = gql`
  query getTemplateTypesStats($source: TemplateSource!) {
    getTemplateTypesStats(source: $source) {
      _id
      type
      count
    }
  }
`;

export const GET_TEMPLATE_DEEP_LINK_PREVIEW = gql`
  query getTemplateDeepLinkPreview($templateId: ID!) {
    getTemplateDeepLinkPreview(templateId: $templateId) {
      _id
      title
      image {
        squareUrl
        rectUrl
      }
      eventColors {
        customEventLightModeColor
        customEventDarkModeColor
      }
      isBrandingAllowed
    }
  }
`;

export const GET_TEMPLATE_DEEP_LINK = gql`
  query getTemplateDeepLink($templateId: ID!) {
    getTemplateDeepLink(templateId: $templateId)
  }
`;
