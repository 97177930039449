import React, { FC, lazy } from 'react';
import loadable from '@loadable/component';

import { MWDP } from './interfaces';
import { Modals } from 'types/enums';
import { ModalRenderer } from 'vibo-ui/Modal';
import { CloneModalProps } from 'components/modals/CloneModal';
import { DjNotesModalProps } from 'components/modals/DjNotesModal';
import { CreateQuestionModalProps } from 'components/modals/CreateQuestionModal';
import { EditEventContactModalProps } from 'components/modals/EditEventContactModal';
import { GenerateEventPdfModalProps } from 'components/modals/GenerateEventPdfModal';
import { GenerateEventPlaylistsModalProps } from 'components/modals/GenerateEventPlaylistsModal';
import { DeleteSongIdeasProps } from 'components/modals/DeleteSongIdeasModal';
import { DjDetailsModalProps } from 'components/modals/DjDetailsModal';
import { RemoveEventContactModalProps } from 'components/modals/RemoveEventContactModal';
import { RemoveFavoriteSectionModalProps } from 'components/modals/RemoveFavoriteSectionModal';
import { SectionSongIdeasModalProps } from 'components/modals/SectionSongIdeasModal';
import { PlaylistSongsModalProps } from 'components/modals/PlaylistSongsModal';
import { SendEventInviteModalProps } from 'components/modals/SendEventInviteModal';
import { ShareModalProps } from 'components/modals/ShareModal';
import { CreateSectionModalProps } from 'components/modals/CreateSectionModal';
import { SectionSettingsModalProps } from 'components/modals/SectionSettingsModal';
import { UpdateUserModalProps } from 'components/modals/UpdateUserModal';
import { AssignMultiOpModalProps } from 'components/modals/AssignMultiOpModal';
import { DeleteSectionModalProps } from 'components/modals/DeleteSectionModal';
import { SongModalProps } from 'components/modals/SongModal';
import { ExportToSpotifyModalProps } from 'components/modals/ExportToSpotifyModal';
import { ExportSongIdeasToSpotifyModalProps } from 'components/modals/ExportSongIdeasToSpotifyModal';
import { AddSongsToSectionFromSearchModalProps } from 'components/modals/AddSongsToSectionFromSearchModal';
import { AddSongsToPlaylistFromSearchModalProps } from 'components/modals/AddSongsToPlaylistFromSearchModal';
import { AddSongsFromMusicSourceModalProps } from 'components/modals/AddSongsFromMusicSourceModal';
import { ChangeUserImageModalProps } from 'components/modals/ChangeUserImageModal';
import { AddSongFromLinkModalProps } from 'components/modals/AddSongFromLinkModal';
import { ChooseComputerModalProps } from 'components/modals/ChooseComputerModal';
import { ManageDjsModalProps } from 'components/modals/ManageDjsModal';
import { AssignPlaylistModalProps } from 'components/modals/AssignPlaylistModal/interfaces';
import { ExportToBeatsourceModalProps } from 'components/modals/ExportToBeatsourceModal';
import { AddEventContactModalProps } from 'components/modals/AddEventContactModal';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { CreatePlaylistModalProps } from 'components/modals/CreatePlaylistModal';
import { ImagePreviewModalProps } from 'components/modals/ImagePreviewModal';
import { EventsPreviewModalProps } from 'components/modals/EventsPreviewModal';
import { AddVideoByLinkModalProps } from 'components/modals/AddVideoByLinkModal';
import { VideoPreviewModalProps } from 'components/modals/VideoPreviewModal';
import { CreateAcademyLessonModalProps } from 'components/modals/CreateAcademyLessonModal';
import { AddLessonVideoModalProps } from 'components/modals/AddLessonVideoModal';
import { ChangeEventTemplateModalProps } from 'components/modals/ChangeEventTemplateModal';
import { PickBrandingColorModalProps } from 'components/modals/PickBrandingColorModal';
import CustomNotificationModal, {
  CustomNotificationModalProps,
} from 'components/modals/CustomNotificationModal';
import { QuestionContactsModalProps } from 'components/modals/QuestionContactsModal';
import { QuestionLinksModalProps } from 'components/modals/QuestionLinksModal';
import { QuestionButtonModalProps } from 'components/modals/QuestionButtonModal';
import { PickGifModalProps } from 'components/modals/PickGifModal';

const PickFilesModal = lazy(() => import('components/modals/PickFilesModal'));
const PickImageModal = lazy(() => import('components/modals/PickImageModal'));
const DjNotesModal = lazy(() => import('components/modals/DjNotesModal'));
const CreateNewEventModal = loadable(() => import('components/modals/CreateNewEventModal')) as FC;
const GenerateEventPdfModal = loadable(() =>
  import('components/modals/GenerateEventPdfModal')
) as FC<GenerateEventPdfModalProps>;
const ChooseComputerModal = loadable(() => import('components/modals/ChooseComputerModal')) as FC<
  ChooseComputerModalProps
>;
const GenerateEventPlaylistsModal = loadable(() =>
  import('components/modals/GenerateEventPlaylistsModal')
) as FC<GenerateEventPlaylistsModalProps>;
const AddEventContactModal = loadable(() => import('components/modals/AddEventContactModal')) as FC<
  AddEventContactModalProps
>;
const EditEventContactModal = loadable(() =>
  import('components/modals/EditEventContactModal')
) as FC<EditEventContactModalProps>;
const ChangeLanguageModal = loadable(() => import('components/modals/ChangeLanguageModal')) as FC;
const CreateQuestionModal = lazy(() => import('components/modals/CreateQuestionModal'));
const ConfirmActionModal = loadable(() => import('components/modals/ConfirmActionModal')) as FC<
  ConfirmActionModalProps
>;
const CloneModal = lazy(() => import('components/modals/CloneModal'));
const CreatePlaylistModal = loadable(() => import('components/modals/CreatePlaylistModal')) as FC<
  CreatePlaylistModalProps
>;
const DeleteSongIdeasModal = loadable(() => import('components/modals/DeleteSongIdeasModal')) as FC<
  DeleteSongIdeasProps
>;
const DeleteAccountModal = loadable(() => import('components/modals/DeleteAccountModal')) as FC;
const DjDetailsModal = loadable(() => import('components/modals/DjDetailsModal')) as FC<
  DjDetailsModalProps
>;
const JoinEventModal = loadable(() => import('components/modals/JoinEventModal')) as FC;
const LeaveEventModal = loadable(() => import('components/modals/LeaveEventModal')) as FC;
const LogoutModal = loadable(() => import('components/modals/LogoutModal')) as FC;
const ManageDjsModal = loadable(() => import('components/modals/ManageDjsModal')) as FC<
  ManageDjsModalProps
>;
const RemoveEventContactModal = loadable(() =>
  import('components/modals/RemoveEventContactModal')
) as FC<RemoveEventContactModalProps>;
const RemoveFavoriteSectionModal = loadable(() =>
  import('components/modals/RemoveFavoriteSectionModal')
) as FC<RemoveFavoriteSectionModalProps>;
const SectionSongIdeasModal = lazy(() => import('components/modals/SectionSongIdeasModal'));
const PlaylistSongsModal = loadable(() => import('components/modals/PlaylistSongsModal')) as FC<
  PlaylistSongsModalProps<SectionBase, SectionSongIdeasPreviewVariables>
>;
const SendEventInviteModal = loadable(() => import('components/modals/SendEventInviteModal')) as FC<
  SendEventInviteModalProps
>;
const ShareModal = loadable(() => import('components/modals/ShareModal')) as FC<ShareModalProps>;
const CreateSectionModal = lazy(() => import('components/modals/CreateSectionModal'));
const SectionSettingsModal = lazy(() => import('components/modals/SectionSettingsModal'));
const CreateTemplateModal = loadable(() => import('components/modals/CreateTemplateModal')) as FC;
const ChangeUserCardModal = loadable(() => import('components/modals/ChangeUserCardModal')) as FC;
const UpdateUserModal = loadable(() => import('components/modals/UpdateUserModal')) as FC<
  UpdateUserModalProps
>;
const AssignMultiOpModal = loadable(() => import('components/modals/AssignMultiOpModal')) as FC<
  AssignMultiOpModalProps
>;
const ChangePasswordModal = loadable(() => import('components/modals/ChangePasswordModal')) as FC;
const ChangeNotificationModal = loadable(() =>
  import('components/modals/ChangeNotificationModal')
) as FC;
const DeleteSectionModal = loadable(() => import('components/modals/DeleteSectionModal')) as FC<
  DeleteSectionModalProps
>;
const SongModal = lazy(() => import('components/modals/SongModal'));
const ExportToSpotifyModal = lazy(() => import('components/modals/ExportToSpotifyModal'));
const ExportSongIdeasToSpotifyModal = loadable(() =>
  import('components/modals/ExportSongIdeasToSpotifyModal')
) as FC<ExportSongIdeasToSpotifyModalProps>;
const AddSongsToSectionFromSearchModal = lazy(() =>
  import('components/modals/AddSongsToSectionFromSearchModal')
);
const AddSongsToPlaylistFromSearchModal = loadable(() =>
  import('components/modals/AddSongsToPlaylistFromSearchModal')
) as FC<AddSongsToPlaylistFromSearchModalProps>;
const AddSongsFromMusicSourceModal = lazy(() =>
  import('components/modals/AddSongsFromMusicSourceModal')
);
const ChangeUserImageModal = loadable(() => import('components/modals/ChangeUserImageModal')) as FC<
  ChangeUserImageModalProps
>;
const AddSongFromLinkModal = loadable(() => import('components/modals/AddSongFromLinkModal')) as FC<
  AddSongFromLinkModalProps
>;
const AssignPlaylistModal = loadable(() => import('components/modals/AssignPlaylistModal')) as FC<
  AssignPlaylistModalProps
>;
const HotKeysPlayerModal = loadable(() => import('components/modals/HotKeysPlayerModal')) as FC;
const ExportToBeatsourceModal = loadable(() =>
  import('components/modals/ExportToBeatsourceModal')
) as FC<ExportToBeatsourceModalProps>;
const ImagePreviewModal = loadable(() => import('components/modals/ImagePreviewModal')) as FC<
  ImagePreviewModalProps
>;
const EventsPreviewModal = loadable(() => import('components/modals/EventsPreviewModal')) as FC<
  EventsPreviewModalProps
>;
const AddVideoByLinkModal = loadable(() => import('components/modals/AddVideoByLinkModal')) as FC<
  AddVideoByLinkModalProps
>;
const VideoPreviewModal = loadable(() => import('components/modals/VideoPreviewModal')) as FC<
  VideoPreviewModalProps
>;
const CreateAcademyLessonModal = loadable(() =>
  import('components/modals/CreateAcademyLessonModal')
) as FC<CreateAcademyLessonModalProps>;
const ReorderCoursesModal = loadable(() => import('components/modals/ReorderCoursesModal')) as FC;
const AddLessonVideoModal = loadable(() => import('components/modals/AddLessonVideoModal')) as FC<
  AddLessonVideoModalProps
>;
const ChangeEventTemplateModal = loadable(() =>
  import('components/modals/ChangeEventTemplateModal')
) as FC<ChangeEventTemplateModalProps>;
const PickBrandingColorModal = loadable(() =>
  import('components/modals/PickBrandingColorModal')
) as FC<PickBrandingColorModalProps>;
const QuestionContactsModal = loadable(() =>
  import('components/modals/QuestionContactsModal')
) as FC<QuestionContactsModalProps>;
const QuestionLinksModal = loadable(() => import('components/modals/QuestionLinksModal')) as FC<
  QuestionLinksModalProps
>;
const QuestionButtonModal = loadable(() => import('components/modals/QuestionButtonModal')) as FC<
  QuestionButtonModalProps
>;
const PickGifModal = loadable(() => import('components/modals/PickGifModal')) as FC<
  PickGifModalProps
>;

export const modalsConfig: Record<string, ModalRenderer> = {
  [Modals.pickImage]: ({ key, props }: MWDP) => <PickImageModal {...props} key={key} />,
  [Modals.createEvent]: ({ key, props }: MWDP) => <CreateNewEventModal {...props} key={key} />,
  [Modals.djNotes]: ({ key, props }: MWDP) => (
    <DjNotesModal {...(props as DjNotesModalProps)} key={key} />
  ),
  [Modals.generatePdfFile]: ({ key, props }: MWDP) => (
    <GenerateEventPdfModal {...(props as GenerateEventPdfModalProps)} key={key} />
  ),
  [Modals.chooseComputer]: ({ key, props }: MWDP) => (
    <ChooseComputerModal {...(props as ChooseComputerModalProps)} key={key} />
  ),
  [Modals.generatePlaylist]: ({ key, props }: MWDP) => (
    <GenerateEventPlaylistsModal {...(props as GenerateEventPlaylistsModalProps)} key={key} />
  ),
  [Modals.addEventContact]: ({ key, props }: MWDP) => <AddEventContactModal {...props} key={key} />,
  [Modals.editEventContact]: ({ key, props }: MWDP) => (
    <EditEventContactModal {...(props as EditEventContactModalProps)} key={key} />
  ),
  [Modals.changeLanguage]: ({ key, props }: MWDP) => <ChangeLanguageModal {...props} key={key} />,
  [Modals.createQuestion]: ({ key, props }: MWDP) => (
    <CreateQuestionModal {...(props as CreateQuestionModalProps)} key={key} />
  ),
  [Modals.confirmAction]: ({ key, props }: MWDP) => <ConfirmActionModal {...props} key={key} />,
  [Modals.clone]: ({ key, props }: MWDP) => (
    <CloneModal {...(props as CloneModalProps)} key={key} />
  ),
  [Modals.createPlaylist]: ({ key, props }: MWDP) => <CreatePlaylistModal {...props} key={key} />,
  [Modals.deletePlaylist]: ({ key, props }: MWDP) => (
    <DeleteSongIdeasModal {...(props as DeleteSongIdeasProps)} key={key} />
  ),
  [Modals.deleteAccount]: ({ key, props }: MWDP) => <DeleteAccountModal {...props} key={key} />,
  [Modals.djDetails]: ({ key, props }: MWDP) => (
    <DjDetailsModal {...(props as DjDetailsModalProps)} key={key} />
  ),
  [Modals.joinEvent]: ({ key, props }: MWDP) => <JoinEventModal {...props} key={key} />,
  [Modals.leaveEvent]: ({ key, props }: MWDP) => <LeaveEventModal {...props} key={key} />,
  [Modals.logOut]: ({ key, props }: MWDP) => <LogoutModal {...props} key={key} />,
  [Modals.manageDjs]: ({ key, props }: MWDP) => (
    <ManageDjsModal {...(props as ManageDjsModalProps)} key={key} />
  ),
  [Modals.removeEventContact]: ({ key, props }: MWDP) => (
    <RemoveEventContactModal {...(props as RemoveEventContactModalProps)} key={key} />
  ),
  [Modals.removeFavoriteSection]: ({ key, props }: MWDP) => (
    <RemoveFavoriteSectionModal {...(props as RemoveFavoriteSectionModalProps)} key={key} />
  ),
  [Modals.sectionSongIdeas]: ({ key, props }: MWDP) => (
    <SectionSongIdeasModal {...(props as SectionSongIdeasModalProps)} key={key} />
  ),
  [Modals.playlistSongs]: ({ key, props }: MWDP) => (
    <PlaylistSongsModal
      {...(props as PlaylistSongsModalProps<SectionBase, SectionSongIdeasPreviewVariables>)}
      key={key}
    />
  ),
  [Modals.sendEventInvite]: ({ key, props }: MWDP) => (
    <SendEventInviteModal {...(props as SendEventInviteModalProps)} key={key} />
  ),
  [Modals.share]: ({ key, props }: MWDP) => (
    <ShareModal {...(props as ShareModalProps)} key={key} />
  ),
  [Modals.createSection]: ({ key, props }: MWDP) => (
    <CreateSectionModal {...(props as CreateSectionModalProps)} key={key} />
  ),
  [Modals.sectionSettings]: ({ key, props }: MWDP) => (
    <SectionSettingsModal
      {...(props as SectionSettingsModalProps<TimelineSectionBase>)}
      key={key}
    />
  ),
  [Modals.createTemplate]: ({ key, props }: MWDP) => <CreateTemplateModal {...props} key={key} />,
  [Modals.changeUserCard]: ({ key, props }: MWDP) => <ChangeUserCardModal {...props} key={key} />,
  [Modals.updateUser]: ({ key, props }: MWDP) => (
    <UpdateUserModal {...(props as UpdateUserModalProps)} key={key} />
  ),
  [Modals.assignMultiOp]: ({ key, props }: MWDP) => (
    <AssignMultiOpModal {...(props as AssignMultiOpModalProps)} key={key} />
  ),
  [Modals.changePassword]: ({ key, props }: MWDP) => <ChangePasswordModal {...props} key={key} />,
  [Modals.changeNotification]: ({ key, props }: MWDP) => (
    <ChangeNotificationModal {...props} key={key} />
  ),
  [Modals.deleteSection]: ({ key, props }: MWDP) => (
    <DeleteSectionModal {...(props as DeleteSectionModalProps)} key={key} />
  ),
  [Modals.song]: ({ key, props }: MWDP) => <SongModal {...(props as SongModalProps)} key={key} />,
  [Modals.exportToSpotify]: ({ key, props }: MWDP) => (
    <ExportToSpotifyModal {...(props as ExportToSpotifyModalProps)} key={key} />
  ),
  [Modals.exportSongIdeasToSpotify]: ({ key, props }: MWDP) => (
    <ExportSongIdeasToSpotifyModal {...(props as ExportSongIdeasToSpotifyModalProps)} key={key} />
  ),
  [Modals.addSongsToSectionFromSearch]: ({ key, props }: MWDP) => (
    <AddSongsToSectionFromSearchModal
      {...(props as AddSongsToSectionFromSearchModalProps)}
      key={key}
    />
  ),
  [Modals.addSongsToPlaylistFromSearch]: ({ key, props }: MWDP) => (
    <AddSongsToPlaylistFromSearchModal
      {...(props as AddSongsToPlaylistFromSearchModalProps)}
      key={key}
    />
  ),
  [Modals.addSongsFromMusicSource]: ({ key, props }: MWDP) => (
    <AddSongsFromMusicSourceModal {...(props as AddSongsFromMusicSourceModalProps)} key={key} />
  ),
  [Modals.changeUserImage]: ({ key, props }: MWDP) => (
    <ChangeUserImageModal {...(props as ChangeUserImageModalProps)} key={key} />
  ),
  [Modals.addSongFromLink]: ({ key, props }: MWDP) => (
    <AddSongFromLinkModal {...(props as AddSongFromLinkModalProps)} key={key} />
  ),
  [Modals.assignPlaylist]: ({ key, props }: MWDP) => (
    <AssignPlaylistModal {...(props as AssignPlaylistModalProps)} key={key} />
  ),
  [Modals.hotKeysPlayer]: ({ key, props }: MWDP) => <HotKeysPlayerModal {...props} key={key} />,
  [Modals.exportToBeatsource]: ({ key, props }: MWDP) => (
    <ExportToBeatsourceModal {...(props as ExportToBeatsourceModalProps)} key={key} />
  ),
  [Modals.pickFiles]: ({ key, props }: MWDP) => <PickFilesModal {...props} key={key} />,
  [Modals.imagePreview]: ({ key, props }: MWDP) => (
    <ImagePreviewModal {...(props as ImagePreviewModalProps)} key={key} />
  ),
  [Modals.eventsPreview]: ({ key, props }: MWDP) => (
    <EventsPreviewModal {...(props as EventsPreviewModalProps)} key={key} />
  ),
  [Modals.addVideoByLink]: ({ key, props }: MWDP) => (
    <AddVideoByLinkModal {...(props as AddVideoByLinkModalProps)} key={key} />
  ),
  [Modals.videoPreiew]: ({ key, props }: MWDP) => (
    <VideoPreviewModal {...(props as VideoPreviewModalProps)} key={key} />
  ),
  [Modals.createAcademyLesson]: ({ key, props }: MWDP) => (
    <CreateAcademyLessonModal {...(props as CreateAcademyLessonModalProps)} key={key} />
  ),
  [Modals.reorderCourses]: ({ key, props }: MWDP) => <ReorderCoursesModal {...props} key={key} />,
  [Modals.addLessonVideo]: ({ key, props }: MWDP) => (
    <AddLessonVideoModal {...(props as AddLessonVideoModalProps)} key={key} />
  ),
  [Modals.changeEventTemplate]: ({ key, props }: MWDP) => (
    <ChangeEventTemplateModal {...(props as ChangeEventTemplateModalProps)} key={key} />
  ),
  [Modals.pickBrandingColor]: ({ key, props }: MWDP) => (
    <PickBrandingColorModal {...(props as PickBrandingColorModalProps)} key={key} />
  ),
  [Modals.customNotification]: ({ key, props }: MWDP) => (
    <CustomNotificationModal {...(props as CustomNotificationModalProps)} key={key} />
  ),
  [Modals.questionContacts]: ({ key, props }: MWDP) => (
    <QuestionContactsModal {...(props as QuestionContactsModalProps)} key={key} />
  ),
  [Modals.questionLinks]: ({ key, props }: MWDP) => (
    <QuestionLinksModal {...(props as QuestionLinksModalProps)} key={key} />
  ),
  [Modals.questionButton]: ({ key, props }: MWDP) => (
    <QuestionButtonModal {...(props as QuestionButtonModalProps)} key={key} />
  ),
  [Modals.pickGif]: ({ key, props }: MWDP) => (
    <PickGifModal {...(props as PickGifModalProps)} key={key} />
  ),
};
