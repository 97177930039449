import React, { FC } from 'react';
import classNames from 'classnames';

import { AppColoredBoxProps } from './interfaces';

import useStyles from './style';

const AppColoredBox: FC<AppColoredBoxProps> = ({ className }) => {
  const classes = useStyles();

  return <div className={classNames(classes.colorBox, className)} />;
};

export default AppColoredBox;
