import * as Yup from 'yup';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import i18n from 'i18n';
import { isQuestionMultiType } from 'services/questions/helpers';

import { QuestionType } from 'types/enums';
import { CreateQuestionValues } from './interfaces';

export const createQuestionValidationSchema = Yup.object().shape({
  question: Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(i18n.t('required'))
      .min(1),
    options: Yup.array().when('$settings', (settings, schema) =>
      isQuestionMultiType(settings.type)
        ? Yup.array().of(
            Yup.object().shape({
              title: Yup.string()
                .nullable()
                .when(['isOther'], {
                  is: isOther => !isOther,
                  then: Yup.string()
                    .trim()
                    .required(i18n.t('required')),
                }),
              images: Yup.array().when('$settings', (settings, schema) =>
                settings.optionImagesEnabled
                  ? schema.test(
                      i18n.t('required'),
                      (images: QuestionImage[]) => !!images?.filter(Boolean).length
                    )
                  : schema
              ),
            })
          )
        : schema
    ),
  }),
});

export const DEFAULT_QUESTION_OPTIONS = [
  { title: 'Option 1', images: [] },
  { title: 'Option 2', images: [] },
];

export const NEW_OPTION_PLACEHOLDER = { title: '', images: [] };

export const DEFAULT_QUESTION_DATA = {
  question: {
    title: '',
    images: [],
    files: [],
    options: DEFAULT_QUESTION_OPTIONS,
    youtubeLink: {
      link: null,
    },
    loomLink: {
      link: null,
    },
    vimeoLink: {
      link: null,
    },
    tenorLink: {
      link: null,
    },
    button: {
      text: null,
      link: null,
    },
  },
  settings: {
    type: QuestionType.header,
    hasOther: false,
    optionImagesEnabled: false,
    notifyMe: false,
  },
};

export const ALLOWED_FIELDS_TO_CREATE = ['question', 'settings'];

export const ALLOWED_FIELDS_TO_UPDATE = [...ALLOWED_FIELDS_TO_CREATE, 'selected', 'imagesToRemove'];

export const MIN_OPTIONS_COUNT = 2;

export const MAX_OPTIONS_WITH_IMAGES_COUNT = 6;

export const sortOtherOption = (op1: QuestionOption, op2: QuestionOption) => {
  if (op1.isOther && !op2.isOther) {
    return 1;
  } else if (!op1.isOther && op2.isOther) {
    return -1;
  } else {
    return 0;
  }
};

export const getPreparedQuestionPayload = (values: CreateQuestionValues, isEditMode: boolean) => {
  const type = values.settings.type;
  const withOptions = type === QuestionType.checkbox || type === QuestionType.radio;

  return {
    ...pick(values, ALLOWED_FIELDS_TO_CREATE),
    settings: withOptions
      ? values.settings
      : {
          ...values.settings,
          optionImagesEnabled: false,
          hasOther: false,
        },
    question: {
      ...omit(values.question, isEditMode ? '' : ['imagesToRemove', 'filesToRemove']),
      options: withOptions
        ? values.question.options
            .map(
              option =>
                !!option.title &&
                !option.isOther && {
                  title: option.title,
                  images: values.settings.optionImagesEnabled ? option.images?.filter(Boolean) : [],
                }
            )
            .filter(Boolean)
        : [],
      youtubeLink: {
        link: values.question.youtubeLink?.link,
      },
      loomLink: {
        link: values.question.loomLink?.link,
      },
      vimeoLink: {
        link: values.question.vimeoLink?.link,
      },
    },
  };
};
