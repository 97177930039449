import React, { Dispatch, SetStateAction } from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';

import DefaultSongUserPicture from 'resources/img/svg/songs/songUserDefault.svg';

import { isEventSection } from '../sections/helpers';
import {
  PREP_MODE_SONG_SHOW_MORE_AMOUNT,
  SONG_SOURCE_METADATA,
  SONGS_SORT_OPTIONS,
  SORTED_SONG_LINK_KEYS,
} from '../constants';

import { KeyCode, MusicImportSource, PrepModeSongExpansion } from 'types/enums';

export const getSongUserImageUrl = (song: SectionSong): string =>
  song?.creator?.image?.cropped?.small || DefaultSongUserPicture;

export const getSmallestAvailableThumbnail = (thumbnails: Maybe<Nullable<Thumbnails>>): string => {
  if (thumbnails) {
    if (get(thumbnails, 's180x180')) {
      return thumbnails.s180x180;
    } else if (get(thumbnails, 'max')) {
      return thumbnails.max;
    } else if (get(thumbnails, 'youtube')) {
      return thumbnails.youtube;
    } else if (get(thumbnails, 'original')) {
      return thumbnails.original;
    }
  }

  return '';
};

export const getLargestAvailableThumbnail = (
  thumbnails: Maybe<Nullable<Thumbnails>>
): Nullable<String> => {
  if (thumbnails) {
    if (get(thumbnails, 'max')) {
      return thumbnails.max;
    } else if (get(thumbnails, 'original')) {
      return thumbnails.original;
    } else if (get(thumbnails, 's180x180')) {
      return thumbnails.s180x180;
    } else if (get(thumbnails, 'youtube')) {
      return thumbnails.youtube;
    }
  }

  return null;
};

export const getFormattedSongArtistAndTitle = <TSong extends Nullable<SongBase>>(
  song: TSong
): string => {
  return [song?.title, song?.artist].filter(Boolean).join(' - ');
};

export const checkImages = (imgs: PlayListLogo[]) => {
  if (imgs[1]) return imgs[1].url;
  if (imgs[0]) return imgs[0].url;
};

const isEventSectionSongsResponse = (v: any): v is SectionSongsResponse => {
  return v && v.getSectionSongs !== undefined;
};

export const getSectionSongsData = (data: any): SectionSongsData => {
  if (isEventSectionSongsResponse(data)) {
    return data.getSectionSongs;
  }

  return {
    next: null,
    totalCount: 0,
    songs: [],
  };
};

const isPrepModeSongsResponse = (v: any): v is PrepModeSongsResponse => {
  return v && v.getPrepModeSongs !== undefined;
};

export const getPrepModeSongsData = (data: any): PrepModeSongsData => {
  if (isPrepModeSongsResponse(data)) {
    return data.getPrepModeSongs;
  }

  return {
    next: null,
    totalCount: 0,
    songs: [],
  };
};

export const isGetSongsFromSearchForSectionResponse = (
  v: any
): v is GetSongsFromSearchForSectionResponse => {
  return v && v.getSongs !== undefined;
};

export const isGetSongsFromSearchForSongIdeasResponse = (
  v: any
): v is GetSongsFromSearchForSongIdeasResponse => {
  return v && v.getSongsForSongIdeas !== undefined;
};

export const isSearchedSongForSection = (v: any): v is SearchedSongForSection => {
  return v && 'isInSection' in v;
};

export const isSearchedSongForSongIdeas = (v: any): v is SearchedSongForSongIdeas => {
  return v && 'isInSongIdeas' in v;
};

const isImportPlaylistToSongIdeasResponse = (v: any): v is ImportPlaylistToSongIdeasResponse => {
  return v && v.importPlaylistToSongIdeas !== undefined;
};

const isImportPlaylistToSectionResponse = (v: any): v is ImportPlaylistToSectionResponse => {
  return v && v.importPlaylistToSectionWeb !== undefined;
};

export const getImportPlaylistData = (data: any): Maybe<ImportPlaylistData> => {
  if (isImportPlaylistToSongIdeasResponse(data)) {
    return data.importPlaylistToSongIdeas;
  }

  if (isImportPlaylistToSectionResponse(data)) {
    return data.importPlaylistToSectionWeb;
  }
};

export const getSongsDataFromSearch = (data: any): SearchedSong[] => {
  if (isGetSongsFromSearchForSectionResponse(data)) {
    return data.getSongs;
  }

  if (isGetSongsFromSearchForSongIdeasResponse(data)) {
    return data.getSongsForSongIdeas;
  }

  return [];
};

export const isInSongList = (song: Nullable<SearchedSong>): boolean => {
  if (isSearchedSongForSection(song)) {
    return song.isInSection;
  }

  if (isSearchedSongForSongIdeas(song)) {
    return song.isInSongIdeas;
  }

  return false;
};

export const isDontPlay = (song: Nullable<SearchedSong>): boolean => {
  if (isSearchedSongForSection(song)) {
    return song.isDontPlay;
  }

  return false;
};

export const getSongKeyValueForDelete = (
  song: SearchedSong
): Nullable<KeyValuePair<string | string[]>> => {
  if (isSearchedSongForSection(song)) {
    return {
      key: 'songIds',
      value: [song.sectionSongId],
    };
  }

  if (isSearchedSongForSongIdeas(song)) {
    return {
      key: 'viboSongId',
      value: song.viboSongId!,
    };
  }

  return null;
};

export const getSearchedSongId = (song: SearchedSong): string => song.viboSongId || song.songUrl;

export const isSectionSongsLimitReached = (section: EventSection, songsToAdd = 1): boolean =>
  !!section.settings.songsLimit && section.songsCount + songsToAdd > section.settings.songsLimit;

export const isSingleSongSection = (section: EventSection): boolean =>
  section.settings.songsLimit === 1;

export const isSongsEnabled = (section: EventSection): boolean => section.settings.songsEnabled;

export const isSingleSongSectionWithSong = (section: SectionBase): boolean => {
  return isEventSection(section)
    ? section.songsCount === 1 && isSectionSongsLimitReached(section)
    : false;
};

export const canAddSongToSingleSongSection = (section: EventSection, songsToAdd = 1) =>
  isSingleSongSection(section) && songsToAdd === 1;

export const canAddSongsToSection = (section: EventSection, songsToAdd = 1): boolean =>
  isSongsEnabled(section) &&
  (!isSectionSongsLimitReached(section, songsToAdd) ||
    canAddSongToSingleSongSection(section, songsToAdd));

// todo: move this function to common helpers
export const createDoublyLinkedListItem = <T>(item: T, list: T[]): DoublyLinkedListItem<T> => {
  let prev, next;

  if (item && list.length) {
    prev = list[list.indexOf(item) - 1];
    next = list[list.indexOf(item) + 1];
  }

  return {
    prev,
    data: item,
    next,
  };
};

export const hasSectionSongsFilterValue = (filter: SectionSongsFilter): boolean => {
  return (
    !!filter.q ||
    typeof filter.hasComments !== 'undefined' ||
    typeof filter.hostLiked !== 'undefined' ||
    typeof filter.isMustPlay !== 'undefined' ||
    typeof filter.isFlagged !== 'undefined'
  );
};

export const getVisiblePrepModeSongFiles = (
  song: PrepModeSong,
  cardExpansion: PrepModeSongExpansion
): SongFile[] => {
  switch (cardExpansion) {
    case PrepModeSongExpansion.less:
      return getTopFilesForSong(song);
    case PrepModeSongExpansion.more:
      return getMoreFilesForSong(song);
    case PrepModeSongExpansion.all:
      return song.files;
  }
};

const getTopFilesForSong = (song: PrepModeSong): SongFile[] => {
  const selectedFiles = song.files.filter(file => {
    return file.isSelected || file.lastSelected;
  });

  if (selectedFiles.length) {
    return selectedFiles;
  }

  const topMatch: SongFile = get(song, 'files[0]');

  return topMatch ? [topMatch] : [];
};

const getMoreFilesForSong = (song: PrepModeSong): SongFile[] => {
  const selectedFiles = song.files.filter(file => {
    return file.isSelected;
  });

  if (selectedFiles.length >= PREP_MODE_SONG_SHOW_MORE_AMOUNT) {
    return selectedFiles;
  }

  return song.files.filter((file, index) => {
    return index < PREP_MODE_SONG_SHOW_MORE_AMOUNT || file.isSelected;
  });
};

export const getUpdatedSongsArr = (
  cachedSongs: SectionSong[],
  payload: UpdateSectionSongsInput,
  selectedIds: string[]
) => {
  return cachedSongs
    .map(song => {
      const updatedSong = song && selectedIds.includes(song._id) ? { ...song, ...payload } : song;

      return updatedSong;
    })
    .filter(Boolean);
};

export const getFilesWithToggledFileSelection = (
  song: SongFilesFragment,
  fileId: string,
  isSelected: boolean
): SongFile[] => song.files.map(file => (file.fileId === fileId ? { ...file, isSelected } : file));

export const getFilesWithoutRemovedFile = (song: SongFilesFragment, fileId: string): SongFile[] =>
  song.files.filter(file => file.fileId !== fileId);

export const handleSongLinksKeyDown = (
  song: DoublyLinkedListItem<Nullable<SongData>>,
  setSong: Dispatch<SetStateAction<Nullable<SongData>>>,
  // @ts-ignore
  youtubePlayer?: Nullable<YT.Player>
) => (event: React.KeyboardEvent<HTMLDivElement>): void => {
  switch (event.keyCode) {
    case KeyCode.arrowLeft:
      !!song.prev && setSong(song.prev);
      break;
    case KeyCode.arrowRight:
      !!song.next && setSong(song.next);
      break;
    case KeyCode.space:
      if (youtubePlayer) {
        // @ts-ignore
        youtubePlayer.getPlayerState() === YT.PlayerState.PLAYING
          ? youtubePlayer.pauseVideo()
          : youtubePlayer.playVideo();
      }
  }
};

export const getSongSortOption = (
  sort: Maybe<Nullable<TSort<SongsSortFields>>>
): Maybe<SongsSortOption> =>
  SONGS_SORT_OPTIONS.find(
    option => sort?.field === option.value?.field && sort?.direction === option.value?.direction
  );

export const getModalTitleKey = (type: MusicImportSource) => {
  switch (type) {
    case MusicImportSource.appleMusic:
      return 'importFromAppleMusic';
    case MusicImportSource.spotify:
      return 'importFromSpotify';
  }
};

export const getSongLinksWithMetadata = (links: Partial<SongLinks>): SongLinkWithMetadata[] =>
  compact(
    SORTED_SONG_LINK_KEYS.map(key => {
      const url: Maybe<Nullable<string>> = get(links, key);
      const metadata: Maybe<SongSourceMetadata> = get(SONG_SOURCE_METADATA, key);

      return url && metadata ? { key, url, ...metadata } : null;
    })
  );
