import React, { FC, useCallback, useContext, useMemo } from 'react';
import omit from 'lodash/omit';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import IconButton from 'components/buttons/IconButton';
import { NotificationsContext } from 'components/context/NotificationsContext';

import { ButtonIconType, Modals, NotificationStatus } from 'types/enums';
import { CustomNotificationModalProps } from 'components/modals/CustomNotificationModal';
import { EventCustomNotification } from 'components/context/NotificationsContext/interfaces';

import { useModal } from 'vibo-ui/Modal';

import useDecorStyles from 'components/buttons/IconButton/decorStyle';

const CustomNotifTools: FC<EventCustomNotification> = notification => {
  const decorStyles = useDecorStyles();
  const { t } = useTranslation();

  const { remove, create, update } = useContext(NotificationsContext);

  const { openModal } = useModal();

  const handleChangeNotif = useCallback(
    props => () => {
      openModal<CustomNotificationModalProps>({
        key: Modals.customNotification,
        props,
      });
    },
    [notification]
  );
  const handleRemoveNotif = useCallback(() => remove(notification._id), [notification._id]);

  const isSent = useMemo(() => notification.status === NotificationStatus.sent, [
    notification.status,
  ]);

  return (
    <div className="tools">
      {isSent ? null : (
        <IconButton
          onClick={handleChangeNotif({
            notification,
            update,
          })}
          title={t('editNotification')}
          type={ButtonIconType.edit}
          className={decorStyles.highlighted}
        />
      )}
      <IconButton
        onClick={handleChangeNotif({
          notification: omit(notification, '_id'),
          create,
        })}
        title={t('cloneNotification')}
        type={ButtonIconType.clone}
        className={decorStyles.highlighted}
      />
      {isSent ? null : (
        <IconButton
          onClick={handleRemoveNotif}
          title={t('deleteNotification')}
          type={ButtonIconType.delete}
          className={classNames(decorStyles.highlighted, 'danger')}
        />
      )}
    </div>
  );
};

export default CustomNotifTools;
