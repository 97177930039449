export const scrollToSelected = () => {
  const selectedOptionNext = document.querySelector('.viboSelect__option--is-selected + div + div');
  const selectedOption = document.querySelector('.viboSelect__option--is-selected');
  const target = selectedOptionNext ?? selectedOption;

  if (!!target) {
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }
};
