import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  songModal: {
    '&.mustPlay': {
      '& .modalBody': {
        background: 'var(--must-play-main)',
      },
    },

    '& .rc-dialog-title': {
      width: '95%',
    },

    '& .songLinksWithMetadata': {
      marginTop: '10px',
    },

    '& .emptyState': {
      padding: '50px 0',

      '& svg': {
        width: '200px',
      },
    },
  },

  songModalTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    '& .sectionName': {
      ...mixins.longText,
      maxWidth: '300px',
    },
  },

  linkIcon: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',

    '&:not(:last-child)': {
      margin: '0 8px 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 10px 0 0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '24px',
      height: '24px',
    },
  },

  playlistSongHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 29px',
    margin: '0 0 20px 0',

    '@media screen and (min-width: 1441px)': {
      padding: '0 36px',
    },
  },
}));

export default useStyles;
