import React, { FC, useRef, useState, useEffect, memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { LazyImage, LazyImageRenderPropArgs, RefArg } from 'react-lazy-images';

import Button from 'vibo-ui/Button';
import GradientCover from 'vibo-ui/GradientCover';
import DefaultImagePlaceholder from './DefaultImagePlaceholder';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { DEFAULT_GRADIENT_ID } from './constants';

import { ImageProps } from './interfaces';

import useStyles from './style';

const Image: FC<PropsWithChildren<ImageProps>> = ({
  src,
  className,
  children,
  placeholder,
  style,
  onClick,
  handleDelete,
  isLazy = true,
  isPickable = false,
  loading = 'eager',
  alt = 'image',
  debounceLoadMs = 300,
  gradientId = DEFAULT_GRADIENT_ID,
  ...rest
}) => {
  const classes = useStyles();

  const [isLoadError, setIsLoadError] = useState<boolean>(false);

  const imageRef = useRef<Nullable<HTMLImageElement>>(null);

  const defaultImageProps = {
    src,
    ...rest,
    loading,
    alt,
  };

  const handleLoadError = () => setIsLoadError(true);

  const imagePlaceholder =
    !!placeholder || (isLoadError && !children) ? (
      <DefaultImagePlaceholder>{placeholder}</DefaultImagePlaceholder>
    ) : (
      <GradientCover className={className} gradientId={gradientId}>
        {children}
      </GradientCover>
    );

  useEffect(() => {
    if (src) {
      setIsLoadError(false);
    } else {
      handleLoadError();
    }
  }, [src]);

  return (
    <div
      className={classNames('viboImage', classes.viboImage, className, {
        isPickable,
        isLoadError,
      })}
      onClick={onClick}
      style={style}
    >
      {isLoadError ? (
        imagePlaceholder
      ) : isLazy ? (
        <LazyImage
          src={src}
          debounceDurationMs={debounceLoadMs}
          error={() => {
            handleLoadError();

            return imagePlaceholder;
          }}
          placeholder={({ ref }: RefArg) => (
            <DefaultImagePlaceholder ref={ref}>{children}</DefaultImagePlaceholder>
          )}
          actual={({ imageProps }: LazyImageRenderPropArgs) => (
            <img
              {...imageProps}
              onError={handleLoadError}
              ref={imageRef}
              alt={imageProps.alt}
              className={classNames('imageActual intrinsic-item animated fadeIn')}
            />
          )}
          {...rest}
        />
      ) : (
        <img {...defaultImageProps} alt={alt} onError={handleLoadError} />
      )}
      {isPickable ? <Icon icon={IconmoonFont['camera-24']} className="pickPhotoIcon" /> : null}
      {!!handleDelete ? (
        <Button
          onClick={handleDelete}
          prefixIcon={IconmoonFont['delete-16']}
          className={classNames('removeImageBtn', classes.removeImageBtn)}
          shape="round"
          type="button"
        />
      ) : null}
    </div>
  );
};

export default memo<FC<PropsWithChildren<ImageProps>>>(Image);
