import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '24px',
    width: '24px',
    height: '24px',
    position: 'relative',

    '&:before': {
      zIndex: 1,
      backgroundColor: 'var(--background-photo)',
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
    },

    '& .playIcon': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: 3,
      color: 'var(--clr-white)',
      fontSize: '24px',
    },

    '& img': {
      zIndex: 2,
    },
  },
}));

export default useStyles;
