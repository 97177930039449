import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboScrollbar: {
    height: '100%',
    position: 'relative',
    maxHeight: '100%',
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '0 auto',

    '&::-webkit-scrollbar': {
      width: '6px',
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },

    '&.simple': {
      width: 'calc(100% + 16px)',
      marginRight: '-16px',
      paddingRight: '10px',
    },

    '&.keepBoxShadow': {
      width: 'calc(100% + 20px)',
      margin: '-4px -4px -4px -4px',
      padding: '4px 10px 4px 4px',
    },

    '&.inset': {},

    '&:not(.alwaysShowThumb)': {
      background: 'transparent',
    },

    '&.alwaysShowThumb': {
      '&::-webkit-scrollbar-thumb': {
        background: 'var(--primary-main)',
      },
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      marginRight: '5px',
      cursor: 'pointer',

      '&:hover': {
        background: 'var(--primary-main)',
      },
    },

    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        background: 'var(--primary-light)',
      },
    },
  },
}));

export default useStyles;
