import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  sectionBlocksHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 16px 0',
    borderRadius: '4px',
    padding: '4px 4px 4px 12px',

    '@media screen and (min-width: 1441px)': {
      padding: '6px 6px 6px 16px',
      margin: '0 0 12px 0',
    },
  },

  sectionName: {
    width: '100%',
    margin: '0',
    fontSize: '17px',
    lineHeight: '22px',

    '@media screen and (min-width: 1080px)': {
      maxWidth: '500px',
    },

    '@media screen and (min-width: 1280px)': {
      maxWidth: '580px',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '20px',
      maxWidth: '750px',
    },

    '@media screen and (min-width: 1600px)': {
      maxWidth: '800px',
    },
  },

  selectedProgress: {
    width: '74px',
    position: 'relative',

    '& .progressBar': {
      border: `1px solid var(--clr-frenchPass)`,
      backgroundColor: 'var(--primary-lighty)',
      height: '18px',
      width: '100%',

      '& .fill': {
        backgroundColor: `var(--primary-main)!important`,
      },

      '& .percentage': {
        display: 'none',
      },

      '@media screen and (min-width: 1401px) ': {
        height: '24px',
      },
    },

    '& .info': {
      userSelect: 'none',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '13px',
      lineHeight: '18px',

      '@media screen and (min-width: 1401px) ': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
}));

export default useStyles;
