import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from 'vibo-ui/Button';
import { NotificationsContext } from 'components/context/NotificationsContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';

const AddNotificationButton: FC<ButtonProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  const { create } = useContext(NotificationsContext);

  const { openModal } = useModal();

  const openCustomNotificationModal = useCallback(() => {
    openModal({
      key: Modals.customNotification,
      props: {
        create,
      },
    });
  }, []);

  return (
    <Button
      onClick={openCustomNotificationModal}
      prefixIcon={IconmoonFont['plus-16']}
      shape="round"
      size="lg"
      {...rest}
    >
      {t('addNotification')}
    </Button>
  );
};

export default AddNotificationButton;
