import { gql } from '@apollo/client';

export const TEMPLATE_PUBLIC = gql`
  fragment TemplatePublic on Template {
    isPublic
  }
`;

export const TEMPLATE_NOTIFS_COUNT = gql`
  fragment TemplateNotifsCountFragment on Template {
    customNotificationsCount
  }
`;
