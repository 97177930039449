import React, { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Checkbox from 'vibo-ui/Checkbox';
import ContentArea from 'components/common/ContentArea';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { CustomNotificationsAddresses } from 'types/enums';
import { CreateCustomNotifForm } from 'components/context/NotificationsContext/interfaces';

const RecipientsBlock: FC = () => {
  const { t } = useTranslation();

  const { values, errors, setFieldValue } = useFormikContext<CreateCustomNotifForm>();

  const handleChangeAdress = useCallback(
    e => {
      const value = e.currentTarget.name;

      const presentAdresses = values.addresses || [];

      const isEnabled = presentAdresses?.includes(value as CustomNotificationsAddresses);

      setFieldValue(
        `addresses`,
        isEnabled
          ? presentAdresses?.filter(adress => adress !== value)
          : [...presentAdresses, value]
      );
    },
    [values.addresses]
  );

  return (
    <ContentArea className="row" shadowed>
      <Icon icon={IconmoonFont['userV2-16']} />
      {Object.values(CustomNotificationsAddresses).map(option => (
        <label key={`notifications-address-${option}`}>
          <Checkbox
            onChange={handleChangeAdress}
            checked={values.addresses?.includes(option)}
            name={option}
          />
          {t(option)}
        </label>
      ))}
      {errors.addresses ? <span className="error">{errors.addresses}</span> : null}
    </ContentArea>
  );
};

export default RecipientsBlock;
