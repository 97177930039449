import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';

import Image from 'vibo-ui/Image';
import Button from 'vibo-ui/Button';
import FilePreview from '../FilePreview/FilePreview';
import VideoPreview from 'components/common/VideoPreview';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';
import { isQuestionHeader } from 'services/questions/helpers';

import { Modals } from 'types/enums';
import { ImagePreviewModalProps } from 'components/modals/ImagePreviewModal';

import useStyles from './style';

const QuestionMediaPreview: FC = () => {
  const classes = useStyles();

  const { question } = useContext(QuestionContext);

  const { openModal } = useModal();

  const imageUrl = useMemo(() => question.question.images?.[0]?.imageUrl || '', [
    question.question.images?.[0]?.imageUrl,
  ]);
  const questionFile = useMemo(() => question.question.files?.[0], [
    question.question.files?.[0]?.fileId,
  ]);

  const fileUrl = useMemo(() => questionFile?.fileUrl, [questionFile?.fileUrl]);
  const fileName = useMemo(() => questionFile?.fileName, [questionFile?.fileName]);
  const videoLink = useMemo(
    () =>
      question.question.youtubeLink?.link ||
      question.question.vimeoLink?.link ||
      question.question.loomLink?.link,
    [
      question.question.youtubeLink?.link,
      question.question.vimeoLink?.link,
      question.question.loomLink?.link,
    ]
  );
  const gifUrl = useMemo(() => question.question.tenorLink.link, [
    question.question.tenorLink.link,
  ]);
  const button = useMemo(() => question.question.button, [
    question.question.button?.text,
    question.question.button?.link,
  ]);

  const imagePreview = (imageUrl || gifUrl) as string;

  const openImagePreviewModal = () =>
    openModal<ImagePreviewModalProps>({
      key: Modals.imagePreview,
      props: {
        imageUrl: imagePreview,
      },
    });

  return !!imageUrl || !!fileUrl || videoLink || !!button?.text || !!gifUrl ? (
    <div
      className={classNames('questionMediaPreview', classes.questionMediaPreview, {
        isDescription: isQuestionHeader(question.settings.type),
      })}
    >
      {!!imagePreview ? (
        <Image className="imagePreview" src={imagePreview} onClick={openImagePreviewModal} />
      ) : null}
      {!!fileUrl ? <FilePreview fileName={fileName} fileUrl={fileUrl} /> : null}
      {!!videoLink ? <VideoPreview link={videoLink} /> : null}
      {!!button?.text && !!button.link ? (
        <Button href={button.link} target="_blank" displayType="bordered" shape="round" size="lg">
          {button.text}
        </Button>
      ) : null}
    </div>
  ) : null;
};

export default QuestionMediaPreview;
