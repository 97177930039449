import client, { cache } from '../client';

import { EVENT_NOTIFS_COUNT } from 'graphql/fragments/events';
import { GET_NOTIFICATIONS_COUNT } from 'graphql/queries/notifications';
import {
  EVENT_CUSTOM_NOTIFICATION,
  NOTIFICATION_READ,
  TEMPLATE_CUSTOM_NOTIFICATION,
} from 'graphql/fragments/notifications';

import { ListQuery } from 'types/graphql';
import { GetNotificationsCountResponse } from 'components/common/Header/TopBar/interface';
import {
  CreateCustomNotifForm,
  EventCustomNotification,
  EventNotificationsVariables,
  TemplateNotificationsVariables,
} from 'components/context/NotificationsContext/interfaces';
import { TEMPLATE_NOTIFS_COUNT } from 'graphql/fragments/templates';

export const readNotification = (id: string): void => {
  client.writeFragment({
    fragment: NOTIFICATION_READ,
    id: `Notification:${id}`,
    data: {
      isRead: true,
      __typename: 'Notification',
    },
  });
};

export const downgradeNotificationsCount = (readAll?: boolean): void => {
  const currentCount =
    client.readQuery<GetNotificationsCountResponse>({
      query: GET_NOTIFICATIONS_COUNT,
    })?.getNotificationsCount.total || 0;

  client.writeQuery<GetNotificationsCountResponse>({
    query: GET_NOTIFICATIONS_COUNT,
    data: {
      getNotificationsCount: {
        total: readAll || currentCount <= 0 ? 0 : currentCount - 1,
        __typename: 'NotificationsTotal',
      },
    },
  });
};

export const removeEventCustomNotifiaction = (customNotificationId: string) => {
  const cachedNotification = client.readFragment({
    id: `EventCustomNotification:${customNotificationId}`,
    fragment: EVENT_CUSTOM_NOTIFICATION,
  });

  if (!!cachedNotification) {
    cache.evict({
      id: `EventCustomNotification:${customNotificationId}`,
    });
  }
};

export const removeTemplateCustomNotifiaction = (customNotificationId: string) => {
  const cachedNotification = client.readFragment({
    id: `TemplateCustomNotification:${customNotificationId}`,
    fragment: TEMPLATE_CUSTOM_NOTIFICATION,
  });

  if (!!cachedNotification) {
    cache.evict({
      id: `TemplateCustomNotification:${customNotificationId}`,
    });
  }
};

export const updateNotifSentCache = (id: string, sentAt: string) => {
  const cachedNotification = client.readFragment({
    id: `EventCustomNotification:${id}`,
    fragment: EVENT_CUSTOM_NOTIFICATION,
  });

  if (!!cachedNotification) {
    cache.modify({
      id: `EventCustomNotification:${id}`,
      fields: {
        sentAt: () => sentAt,
      },
    });
  }
};

export const reorderCustomNotifiactions = ({
  key,
  query,
  variables,
  data,
}: {
  key: string;
  query: ListQuery;
  variables: EventNotificationsVariables | TemplateNotificationsVariables;
  data: EventCustomNotification[];
}) => {
  const cachedNotifications = client.readQuery({
    query,
    variables,
  });

  if (!!cachedNotifications) {
    client.writeQuery({
      query,
      variables,
      data: {
        [key]: data,
      },
    });
  }
};

export const decreaseEventNotifsCount = (eventId: string) => {
  const cachedEvent = client.readFragment({
    id: `Event:${eventId}`,
    fragment: EVENT_NOTIFS_COUNT,
  });

  if (!!cachedEvent) {
    cache.modify({
      id: `Event:${eventId}`,
      fields: {
        customNotificationsCount: () => cachedEvent.customNotificationsCount - 1,
      },
    });
  }
};

export const decreaseTemplateNotifsCount = (templateId: string) => {
  const cachedEvent = client.readFragment({
    id: `Template:${templateId}`,
    fragment: TEMPLATE_NOTIFS_COUNT,
  });

  if (!!cachedEvent) {
    cache.modify({
      id: `Template:${templateId}`,
      fields: {
        customNotificationsCount: () => cachedEvent.customNotificationsCount - 1,
      },
    });
  }
};

export const increaseEventNotifsCount = (eventId: string) => {
  const cachedEvent = client.readFragment({
    id: `Event:${eventId}`,
    fragment: EVENT_NOTIFS_COUNT,
  });

  if (!!cachedEvent) {
    cache.modify({
      id: `Event:${eventId}`,
      fields: {
        customNotificationsCount: () => cachedEvent.customNotificationsCount + 1,
      },
    });
  }
};

export const increaseTemplateNotifsCount = (eventId: string) => {
  const cachedEvent = client.readFragment({
    id: `Template:${eventId}`,
    fragment: TEMPLATE_NOTIFS_COUNT,
  });

  if (!!cachedEvent) {
    cache.modify({
      id: `Template:${eventId}`,
      fields: {
        customNotificationsCount: () => cachedEvent.customNotificationsCount + 1,
      },
    });
  }
};

export const updateEventNotif = ({ _id, ...rest }: CreateCustomNotifForm) => {
  const cachedNotification = client.readFragment({
    id: `EventCustomNotification:${_id}`,
    fragment: EVENT_CUSTOM_NOTIFICATION,
  });

  if (!!cachedNotification) {
    cache.modify({
      id: `EventCustomNotification:${_id}`,
      // @ts-ignore
      fields: {
        title: () => rest.title,
        input: () => rest.input,
        addresses: () => rest.addresses,
        sendingTimeSettings: () => rest.sendingTimeSettings,
        disabled: () => rest.disabled,
      } as unknown,
    });
  }
};

export const updateTemplateNotif = ({ _id, ...rest }: CreateCustomNotifForm) => {
  const cachedNotification = client.readFragment({
    id: `TemplateCustomNotification:${_id}`,
    fragment: TEMPLATE_CUSTOM_NOTIFICATION,
  });

  if (!!cachedNotification) {
    cache.modify({
      id: `TemplateCustomNotification:${_id}`,
      // @ts-ignore
      fields: {
        title: () => rest.title,
        input: () => rest.input,
        addresses: () => rest.addresses,
        sendingTimeSettings: () => rest.sendingTimeSettings,
        disabled: () => rest.disabled,
      } as unknown,
    });
  }
};
