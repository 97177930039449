import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  adminUsersPage: {
    backgroundColor: 'var(--background-default)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& .header': {
      height: '100%',
      maxHeight: '48px',
    },

    '& strong': {
      color: 'var(--text-primary)',
    },

    '& > .container': {
      height: 'calc(100% - 40px)',

      '& .containerContent': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },

      '@media screen and (min-width: 1441px)': {
        height: 'calc(100% - 48px)',
      },
    },

    '& .cellName': {
      width: '100%',

      '&:not(:last-child)': {
        margin: '0 8px 0 0',
      },

      '&.photo': {
        maxWidth: '64px',

        '& .viboImage': {
          width: '64px',
          height: '64px',
        },
      },

      '&.name': {
        ...mixins.longText,
        maxWidth: '300px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '500px',
        },
      },

      '&.role': {
        maxWidth: '200px',

        '& .viboButton': {
          padding: '0',
        },

        '@media screen and (min-width: 1441px)': {
          maxWidth: '200px',
        },
      },

      '&.email': {
        ...mixins.longText,
        maxWidth: '200px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '250px',
        },
      },

      '&.finance-status': {
        maxWidth: '230px',

        '& .viboSelect__control': {
          whiteSpace: 'nowrap',
          flexWrap: 'nowrap',
        },
      },
    },
  },

  usersHeader: {
    width: '100%',
    display: 'flex',
    padding: '0 6px 6px 6px',
  },

  userRow: {
    cursor: 'pointer',
    padding: '6px',
    height: '80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  roleOverlay: {
    margin: '0',

    '& .contentArea': {
      padding: '6px',
      display: 'flex',
      flexDirection: 'column',
    },
  },

  eventsWrap: {
    width: '100%',
    maxWidth: '1155px',
    display: 'flex',
    alignSelf: 'center',
  },

  events: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },

  eventsTable: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    width: '100%',
    paddingTop: '11px',
  },

  eventItem: {
    height: '80px',
    padding: '15px',
    transition: '0.2s',
    borderRadius: '4px',
    boxShadow: 'var(--shadows-4)',

    '&:hover': {
      boxShadow: 'var(--shadows-8)',
      cursor: 'pointer',
    },
  },

  eventHeader: {
    boxShadow: 'var(--shadows-4)',
    borderRadius: '4px',

    '& svg': {
      marginLeft: '5px',
    },
  },

  proTierLabel: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    lineHeight: 'inherit',
  },
}));

export default useStyles;
