import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  highlighted: {
    ...mixins.getStaticIconSize?.(20),
    borderRadius: '4px',
    color: 'var(--primary-main)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',

    '&.danger': {
      color: 'var(--error-main)',
    },

    '&:hover, &.hoverable-action:hover': {
      backgroundColor: 'var(--primaryLightToPrimaryMain)',

      '&, & svg, & .viboIcon': {
        color: 'var(--buttonActiveToWhite)!important',
      },
    },

    '@media screen and (min-width: 1441px)': {
      ...mixins.getStaticIconSize?.(24),
    },
  },
}));

export default useStyles;
