import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import YouTube from 'react-youtube';
import classNames from 'classnames';

import SongImage from 'components/image/SongImage';

import { ReactComponent as CaretLeftIcon } from 'resources/img/svg/common/caretLeft.svg';
import { ReactComponent as CaretRightIcon } from 'resources/img/svg/common/caretRight.svg';

import { getYouTubeIdFromWatchUrl } from 'services/common/videoHelpers';

import { SongPlayerIframeProps } from './interfaces';

import useStyles from './style';

const SongPlayerIframe: FC<SongPlayerIframeProps> = ({
  currentIndex,
  songsCount,
  song,
  setSong,
}) => {
  const classes = useStyles();

  const [error, setError] = useState<Nullable<number>>(null);

  const videoId = useMemo(
    () => !!song.links?.youtube && getYouTubeIdFromWatchUrl(get(song, 'links.youtube', '')),
    [song.links?.youtube]
  );

  const handleOpenPrevSong = useCallback(() => {
    setSong(currentIndex - 1);
  }, [currentIndex]);

  const handleOpenNextSong = useCallback(() => {
    setSong(currentIndex + 1);
  }, [currentIndex]);

  useEffect(() => {
    setError(null);
  }, []);

  return (
    <div className={classes.songPlayerIframe}>
      <div
        className={classNames(classes.switchSongIcon, {
          [classes.disabledIcon]: currentIndex === 0,
        })}
      >
        <CaretLeftIcon onClick={handleOpenPrevSong} />
      </div>
      {!!videoId && !error ? (
        // @ts-ignore
        <YouTube
          videoId={videoId}
          opts={{
            height: '390',
            width: '698',
            playerVars: {
              autoplay: 1,
              enablejsapi: 1,
            },
          }}
          onError={e => setError(e.data)}
          onEnd={handleOpenNextSong}
        />
      ) : (
        <SongImage
          src={song.thumbnails.max || song.thumbnails.s180x180}
          song={song}
          className={classes.thumbnail}
        />
      )}
      <div
        className={classNames(classes.switchSongIcon, {
          [classes.disabledIcon]: currentIndex === songsCount - 1,
        })}
      >
        <CaretRightIcon onClick={handleOpenNextSong} />
      </div>
    </div>
  );
};

export default SongPlayerIframe;
