import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  song: {
    padding: '10px',
    position: 'relative',

    '& .extra': {
      margin: '0 0 0 auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .iconButton': {
        color: 'var(--primary-main)',

        '&:not(:last-child)': {
          margin: '0 6px 0 0',
        },
      },
    },

    '& .viboImage': {
      '& .playIcon': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },

    '&.highlighted': {
      '&:after': {
        ...mixins.highlightedSongPseudo,
        border: `1px solid var(--primary-main)`,
      },
    },
  },
}));

export default useStyles;
