import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  defaultColumn: {
    whiteSpace: 'nowrap',
    color: 'var(--text-primary)',
  },
}));

export default useStyles;
