import React, { FC, useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import List, { DragResponse } from 'vibo-ui/List';
import Spinner from 'vibo-ui/Spinner';
import Scrollbar from 'vibo-ui/Scrollbar';
import MainNotifSettings from './MainNotifSettings';
import CustomNotification from './CustomNotification';
import NotifPageSubheader from './NotifPageSubheader';
import DefaultNotifSettings from './DefaultNotifSettings';
import AddNotificationButton from './AddNotificationButton';
import WithEventFeature from 'components/user/WithEventFeature';
import NoCustomNotifications from 'components/emptyStates/NoCustomNotifications';
import { EventContext } from 'components/context/EventContext';
import { EditableContext } from 'components/common/EditableContext';
import { NotificationsContext } from 'components/context/NotificationsContext';

import { CUSTOM_NOTIFICATIONS_LIMIT } from './constants';
import { getDestination } from 'services/common/reorderHelper';

import { TierFeatueName } from 'types/enums';
import { EventCustomNotification } from 'components/context/NotificationsContext/interfaces';

import useStyles from './style';

const NotificationsPage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { event } = useContext(EventContext);
  const { canEdit } = useContext(EditableContext);
  const { notifications, reorder, notificationsLoading } = useContext(NotificationsContext);

  const handleDragChange = (
    newData: EventCustomNotification[],
    { removedIndex, addedIndex }: DragResponse
  ) => {
    if (!isEqual(notifications, newData) && !!notifications) {
      const destinationIndex = getDestination(removedIndex, addedIndex);

      reorder?.({
        data: newData,
        source: {
          sourceCustomNotificationId: notifications[removedIndex]._id,
          targetCustomNotificationId:
            destinationIndex < 0 ? '' : notifications[destinationIndex]._id,
        },
      });
    }
  };

  return (
    <div className={classes.notificationsPage}>
      <NotifPageSubheader title={event?.title} />
      <Scrollbar>
        <MainNotifSettings />
        <h3>{t('defaultNotifications')}</h3>
        <DefaultNotifSettings />
        <WithEventFeature feature={TierFeatueName.custom_notifications}>
          <>
            <h3>
              {t('customNotifications', {
                extra: `(${event?.customNotificationsCount}/${CUSTOM_NOTIFICATIONS_LIMIT})`,
              })}
            </h3>
            {notificationsLoading && !notifications?.length ? (
              <Spinner />
            ) : !!notifications?.length ? (
              <List<EventCustomNotification>
                data={notifications}
                onDropCallback={handleDragChange}
                renderItem={notif => (
                  <CustomNotification {...notif} key={`custom-notification-${notif._id}`} />
                )}
                className="customNotifications"
                draggable={canEdit && !!reorder}
                dragHandleSelector=".draggableItem"
              />
            ) : (
              <NoCustomNotifications>
                {canEdit ? <AddNotificationButton /> : null}
              </NoCustomNotifications>
            )}
          </>
        </WithEventFeature>
      </Scrollbar>
    </div>
  );
};

export default NotificationsPage;
