import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Switch from 'vibo-ui/Switch';
import NotifStatus from './NotifStatus';
import CustomNotifTools from './CustomNotifTools';
import ContentArea from 'components/common/ContentArea';
import ConfirmActionButton from 'components/buttons/ConfirmActionButton';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { EventContext } from 'components/context/EventContext';
import { EditableContext } from 'components/common/EditableContext';
import { NotificationsContext } from 'components/context/NotificationsContext';

import { getDateWithTimezone } from 'services/common/dateHelpers';
import { parseToMagicFieldValue } from 'components/common/MagicField/constants';
import { CUSTOM_NOTIF_MAGIC_FIELDS } from 'components/modals/CustomNotificationModal/constants';

import {
  DisabledNotificationsForm,
  EventCustomNotification,
} from 'components/context/NotificationsContext/interfaces';
import { NotificationStatus } from 'types/enums';

import useStyles from '../style';

const CustomNotification: FC<EventCustomNotification> = notification => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { event } = useContext(EventContext);
  const { canEdit } = useContext(EditableContext);
  const { send, update } = useContext(NotificationsContext);
  const { _id, title, status, sendingTimeSettings, input, addresses, sentAt } = notification;

  const { values } = useFormikContext<DisabledNotificationsForm>();

  const parsedTitle = useMemo(() => parseToMagicFieldValue(title, CUSTOM_NOTIF_MAGIC_FIELDS), [
    title,
  ]);
  const parsedInput = useMemo(
    () => (!!input ? parseToMagicFieldValue(input, CUSTOM_NOTIF_MAGIC_FIELDS) : ''),
    [input]
  );

  const isSent = useMemo(() => status === NotificationStatus.sent, [status]);

  const isTimeValid = useMemo(() => status !== NotificationStatus.cannotBeSend, [status]);

  const sentAtTime = useMemo(
    () => getDateWithTimezone(sentAt, event?.timezone, 'MM/DD/YYYY h:mmA'),
    [sentAt, event?.timezone]
  );

  const toggleNotification = debounce((value: boolean) => {
    update({
      ...notification,
      disabled: !value,
    });
  }, 500);

  const isDisabled = useMemo(
    () => notification.disabled || values.disableAllPushNotifications || false,
    [notification.disabled, values.disableAllPushNotifications]
  );

  return (
    <ContentArea className={classNames('customNotification', classes.customNotification)} shadowed>
      <div className="top">
        {!!status ? <NotifStatus status={status} disabled={isDisabled} /> : null}
        <div className="mainInfo">
          <div className="title" dangerouslySetInnerHTML={{ __html: parsedTitle }} />
          {!!input ? (
            <div className="desc" dangerouslySetInnerHTML={{ __html: parsedInput }} />
          ) : null}
        </div>
        <div className="mainControls">
          {isSent ? (
            <span className="sentBadge">
              <Icon icon={IconmoonFont['checkCircleOutlined-16']} />
              {t('sentSuccessfully')}
            </span>
          ) : (
            <>
              {!!send ? (
                <ConfirmActionButton
                  action={() => send?.(_id)}
                  confirmText={t('confirm')}
                  previewBtnProps={{
                    shape: 'round',
                    size: 'lg',
                  }}
                >
                  <Button prefixIcon={IconmoonFont['send-16']} shape="round" size="lg">
                    {t('sendNow')}
                  </Button>
                </ConfirmActionButton>
              ) : null}
              <Switch
                defaultChecked={!notification.disabled}
                onClick={toggleNotification}
                disabled={!canEdit || values.disableAllPushNotifications}
              />
            </>
          )}
        </div>
      </div>
      <div
        className={classNames('extra-info timeSettings', {
          isTimeValid,
        })}
      >
        <Icon icon={IconmoonFont['clock-16']} />
        {isDisabled && !isSent ? (
          'Notification disabled'
        ) : (
          <>
            {t(
              !isTimeValid ? 'notificationWontBeSent' : isSent ? 'sentAt' : 'notificationWillBeSent'
            )}
            :
            {!isTimeValid ? (
              <span>{t('incorrectSendingSettingDate').toLowerCase()}</span>
            ) : isSent ? (
              <span>{sentAtTime}</span>
            ) : (
              <>
                {!!sendingTimeSettings ? (
                  !!sendingTimeSettings?.number ? (
                    <span>
                      <span>{sendingTimeSettings?.number}</span>
                      <span>
                        {t(`${sendingTimeSettings?.timeUnit}`, {
                          count: sendingTimeSettings?.number,
                        })}
                      </span>
                      <span>{sendingTimeSettings?.temporalOrder}</span>
                      <span>{t(`${sendingTimeSettings?.eventStatusDate}`)}</span>
                    </span>
                  ) : (
                    <span className="tbd">TBD</span>
                  )
                ) : null}
              </>
            )}
          </>
        )}
      </div>
      <div className="extra-info">
        <Icon icon={IconmoonFont['userV2-16']} />
        {t('whoWillBeNotified')}:
        {addresses?.map(adress => (
          <span key={`custom-notif-adress-${_id}-${adress}`}>
            {t(`${adress.slice(0, -1)}_plural`)}
          </span>
        ))}
        {canEdit ? <CustomNotifTools {...notification} /> : null}
      </div>
      {canEdit ? <Icon icon={IconmoonFont['dnd-16']} className="draggableItem" /> : null}
    </ContentArea>
  );
};

export default CustomNotification;
