import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  poveredBy: {
    borderTop: `1px solid var(--background-paper)`,
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'var(--clr-gray)',
    padding: '6px 16px',

    '& .viboIcon svg': {
      marginLeft: '5px',
      width: '55px',
      height: '18px',
    },
  },

  suggestionsMenu: {
    backgroundColor: `var(--background-paper)!important`,

    '& .menuItem': {
      color: 'var(--text-primary)',
      padding: '6px 8px!important',

      '&:hover': {
        color: `var(--text-primary)!important`,
        backgroundColor: `var(--primary-lighty)!important`,
      },

      '& .menuItemContent': {
        ...mixins.longText,
        display: 'block',
        maxWidth: '400px',
        fontWeight: 400,

        '@media screen and (min-width: 1441px)': {
          maxWidth: '600px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        padding: '10px 8px!important',
      },
    },

    '& .suggestion': {
      padding: '8px',
      textAlign: 'left',
      cursor: 'pointer',

      '&.suggestion-item--active, &:hover': {
        backgroundColor: 'red',
      },
    },
  },
}));

export default useStyles;
