import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloneTemplateButton from './CloneTemplateButton';

import { useMe } from 'graphql/hooks/user';

import { IconmoonFont } from 'vibo-ui/Icon';
import { CloneTemplateButtonProps } from './interfaces';

const CloneTemplateBtnByPublicity: FC<CloneTemplateButtonProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  const { user } = useMe();

  const isMytemplate = useMemo(() => rest.template.userId === user?._id, [
    rest.template.userId,
    user?._id,
  ]);

  return (
    <CloneTemplateButton
      {...rest}
      displayType={isMytemplate ? 'default' : 'bordered'}
      prefixIcon={IconmoonFont[isMytemplate ? 'clone-24' : 'plus-16']}
      children={isMytemplate ? null : t('addToMyTemplates')}
    />
  );
};

export default CloneTemplateBtnByPublicity;
