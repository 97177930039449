import React, { useState, FC, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';
import WithEventRole from 'components/user/WithEventRole';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';
import SectionBlockInfo from 'components/sections/blocks/SectionBlock/SectionBlockInfo';
import { TimelineSongsContext } from '../TimelineSongsContext';

import { ReactComponent as AppleMusicOriginalIcon } from 'resources/img/svg/appleMusicOriginal.svg';

import { ADD_SONG_TO_SECTION, IMPORT_PLAYLIST_TO_SECTION_WEB } from 'graphql/mutations/songs';

import { useModal } from 'vibo-ui/Modal';
import { useEvent } from 'components/context/EventContext';
import { getSectionSongsInfo } from 'services/sections/helpers';
import { isSingleSongSectionWithSong } from 'services/songs/helpers';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventUserType, Modals, MusicImportSource } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { AddSongFromLinkModalProps } from 'components/modals/AddSongFromLinkModal';
import { SectionSongIdeasModalProps } from 'components/modals/SectionSongIdeasModal';
import { AddSongsFromMusicSourceModalProps } from 'components/modals/AddSongsFromMusicSourceModal';
import { AddSongsToSectionFromSearchModalProps } from 'components/modals/AddSongsToSectionFromSearchModal';

import useStyles from './style';

const SongsBlockHeader: FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [replaceSongCallback, setReplaceSongCallback] = useState<Nullable<Function>>(null);

  const { songsOptions, variables, section, refetchSongs, listQueries, mutations } = useContext(
    TimelineSongsContext
  );

  const { isEventGuest, canAddSong = false } = useEvent();

  const { openModal, closeModal, openedModals } = useModal();

  const openSectionSongIdeasModal = useCallback(
    () =>
      openModal<SectionSongIdeasModalProps>({
        key: Modals.sectionSongIdeas,
        props: {
          variables,
          section,
          listQueries,
          mutations,
        },
      }),
    [variables, section, mutations, listQueries]
  );

  const openAddSongToSectionFromSearchModal = useCallback(() => {
    openModal<AddSongsToSectionFromSearchModalProps>({
      key: Modals.addSongsToSectionFromSearch,
      props: {
        section,
        // @ts-ignore
        variables,
      },
    });
  }, [
    variables.eventId,
    variables.sectionId,
    variables.templateId,
    section._id,
    section.name,
    section.songsCount,
    section.type,
    section.settings.songsLimit,
    openedModals.length,
  ]);
  const openReplaceSongModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('replaceSong'),
          children: t('replaceObjectSong', { obect: section.name }),
          submit: {
            text: t('replace'),
            onClick: handleReplaceSong,
          },
        },
      }),
    [section.name]
  );
  const openAddSongsFromMusicSourceModal = useCallback(
    (source: MusicImportSource) =>
      openModal<AddSongsFromMusicSourceModalProps>({
        key: Modals.addSongsFromMusicSource,
        props: {
          source,
          variables,
          importMutation: IMPORT_PLAYLIST_TO_SECTION_WEB,
          refetchPlaylist: refetchSongs,
          refetchQueries: ['sections', 'getSectionSongsStats', 'getSectionSongs'],
          shouldConfirmReplace: isSingleSongSectionWithSong(section),
          setIsConfirmReplaceVisible: openReplaceSongModal,
          setReplaceSongCallback,
        },
      }),
    []
  );
  const openAddSongFromLinkModal = useCallback(
    () =>
      openModal<AddSongFromLinkModalProps>({
        key: Modals.addSongFromLink,
        props: {
          mutation: ADD_SONG_TO_SECTION,
          variables,
          refetchPlaylist: refetchSongs,
          refetchQueries: ['sections', 'getSectionSongsStats', 'getSectionSongs'],
          shouldConfirmReplace: isSingleSongSectionWithSong(section),
          setIsConfirmReplaceVisible: openReplaceSongModal,
          setReplaceSongCallback: setReplaceSongCallback,
        },
      }),
    [variables, section._id, section.songsCount, section.settings.songsLimit]
  );
  const closeConfirmModal = useCallback(() => closeModal(Modals.confirmAction), []);

  const handleReplaceSong = useCallback(() => {
    replaceSongCallback && replaceSongCallback();
    closeConfirmModal();
  }, [replaceSongCallback]);

  return (
    <div className={classNames('songsBlockHeader', classes.songsBlockHeader)}>
      {children}
      <SectionBlockInfo
        title={t('songs')}
        info={getSectionSongsInfo(section, songsOptions.sectionSongsData.totalCount)}
      />
      <WithEventRole roles={[EventUserType.dj]}>
        <Button
          onClick={e => {
            e.stopPropagation();
            openSectionSongIdeasModal();
          }}
          prefixIcon={IconmoonFont['lightBulbOff-16']}
          shape="round"
          size="lg"
          data-gtm-target-id="timeline-section-details-songs-songideas"
        >
          {t('songIdeasAmount', { count: section.songIdeasCount })}
        </Button>
      </WithEventRole>
      {canAddSong ? (
        <>
          <WithEventRole roles={[EventUserType.guest, EventUserType.host]}>
            <Button
              onClick={e => {
                e.stopPropagation();
                openAddSongToSectionFromSearchModal();
              }}
              prefixIcon={IconmoonFont['lightBulbOff-16']}
              shape="round"
              size="lg"
            >
              {t('songIdeas')}
            </Button>
          </WithEventRole>
          <StopPropagationWrapper className="addSongsWrapper">
            <Dropdown
              overlay={
                <Menu selectable={false}>
                  <Menu.Item
                    onClick={openAddSongToSectionFromSearchModal}
                    icon={IconmoonFont['search-16']}
                    key="search"
                    data-gtm-target-id={'timeline-section-details-songs-add-song-search'}
                  >
                    {t('search')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={openAddSongFromLinkModal}
                    icon={IconmoonFont['link-16']}
                    key="link"
                    data-gtm-target-id={'timeline-section-details-songs-add-song-link'}
                  >
                    {t('songLink')}
                  </Menu.Item>
                  {isEventGuest ? null : (
                    <>
                      <Menu.Item
                        onClick={() => openAddSongsFromMusicSourceModal(MusicImportSource.spotify)}
                        icon={IconmoonFont['spotifyColorful-16']}
                        key="spotify"
                        data-gtm-target-id={'timeline-section-details-songs-add-song-spotify'}
                      >
                        {t('spotify')}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          openAddSongsFromMusicSourceModal(MusicImportSource.appleMusic)
                        }
                        icon={AppleMusicOriginalIcon}
                        key="appleMusic"
                        data-gtm-target-id={'timeline-section-details-songs-add-song-apple'}
                      >
                        {t('appleMusic')}
                      </Menu.Item>
                    </>
                  )}
                </Menu>
              }
              trigger="click"
              disableToggleClass
            >
              <Button
                prefixIcon={IconmoonFont['plus-16']}
                displayType="bordered"
                shape="round"
                size="lg"
              >
                {t('addSongs')}
              </Button>
            </Dropdown>
          </StopPropagationWrapper>
        </>
      ) : null}
    </div>
  );
};

export default SongsBlockHeader;
