import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  img: {
    '& svg': {
      color: 'var(--text-primary)',
      width: '50%',
      height: '50%',
    },
  },
}));

export default useStyles;
