import { createUseStyles } from 'react-jss';

import { inputsBySize } from 'resources/styles/inputs/style';

const useStyles = createUseStyles(() => ({
  magicFieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    gap: '4px',

    '& .viboToggleDropdown': {
      '& .viboButton .viboIcon': {
        color: 'var(--primary-main)',
      },
    },

    '& .closeWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      width: '100%',
      maxWidth: 'calc(100% - 28px)',
      position: 'relative',

      '& .magicField:not(:empty)': {
        // FOR SAFARI
        '&:not(:focus)': {
          backgroundColor: 'var(--background-paper)',
        },

        '& + .placeholder': {
          display: 'none!important',
        },
      },

      '& .placeholder': {
        position: 'absolute',
        left: '12px',
        color: 'var(--text-hint)',
        pointerEvents: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        maxWidth: 'calc(100% - 32px)',
      },
    },
  },

  magicField: {
    ...inputsBySize,
    width: '100%',
    minHeight: '30px',

    '&, + .placeholder': {
      fontSize: '13px',
      lineHeight: '28px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '36px',
      },
    },

    '& mf': {
      fontWeight: 700,
      padding: '2px 4px',
      borderRadius: '4px',
      whiteSpace: 'nowrap',
      backgroundColor: 'var(--sectionHover)',
      border: `1px solid var(--text-disabled)`,
      cursor: 'pointer',
      position: 'relative',

      '&:hover': {
        '&:after': {
          display: 'block',
        },
      },

      '&:after': {
        borderRadius: '4px',
        display: 'none',
        content: '"Remove"',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        lineHeight: '20px',
        backgroundColor: 'var(--sectionHover)',
        textAlign: 'center',
        color: 'var(--error-main)',

        '@media screen and (min-width: 1441px)': {
          lineHeight: '24px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '36px',
    },
  },
}));

export default useStyles;
