import React, { FC, memo, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Icon from 'vibo-ui/Icon';
import Tooltip from 'vibo-ui/Tooltip';
import Image from 'components/image/Image';
import NotificationBell from './NotificationBell';
import AppThemeButton from 'components/buttons/AppThemeButton';

import { ReactComponent as UserIconRoundedIcon } from 'resources/img/svg/userIconRounded.svg';
import { ReactComponent as SettingsGearIcon } from 'resources/img/svg/settingsGear-24.svg';

import { useMe } from 'graphql/hooks/user';
import { getSelectedMainMenuKeys } from 'services/common/routesHelper';
import { TOOLTIP_ALIGN } from './contstants';

import useStyles from './style';

const TopBar: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user } = useMe();

  const { push } = useHistory();

  const profileImage = useMemo(() => user?.imageUrl, [user?.imageUrl]);

  return (
    <div className={classes.topBar}>
      <AppThemeButton align={TOOLTIP_ALIGN} />
      <NotificationBell />
      <Menu
        getSelectedSidebarMenuKeys={getSelectedMainMenuKeys}
        selectable={false}
        styleModificator="siderTop"
      >
        <Menu.Item
          onClick={() => push('/settings')}
          key="/settings"
          data-gtm-target-id="header-top-settins-link"
        >
          <Tooltip overlay={t('settings')} type="primary" align={TOOLTIP_ALIGN} fillOverlay>
            <Icon icon={SettingsGearIcon} />
          </Tooltip>
        </Menu.Item>
        <Menu.Item
          onClick={() => push('/me')}
          key="/me"
          data-gtm-target-id="header-top-account-link"
        >
          <Tooltip overlay={t('tourAccount')} type="primary" align={TOOLTIP_ALIGN} fillOverlay>
            {!!profileImage ? (
              <Image
                src={profileImage}
                className={classes.profileImage}
                isLazy={false}
                alt="profile image"
              >
                <Icon icon={UserIconRoundedIcon} />
              </Image>
            ) : (
              <Icon icon={UserIconRoundedIcon} />
            )}
          </Tooltip>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default memo(TopBar);
