import React, { useContext, useMemo, ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Scrollbar from 'vibo-ui/Scrollbar';
import ContentArea from 'components/common/ContentArea';
import GearResult from 'components/emptyStates/GearResult';
import SectionBlocksHeader from '../../SectionBlocksHeader';
import TimelineNotesBlock from 'components/events/Timeline/TimelineNotesBlock';
import TimelineSongsBlock from 'components/events/Timeline/TimelineSongsBlock';
import SectionDescription from 'components/sections/SectionBlocksHeader/SectionDescription';
import TimelineQuestionsBlock from 'components/events/Timeline/TimelineQuestionsBlock';
import { EventContext } from 'components/context/EventContext';

import { isHeadlineSection } from 'services/sections/helpers';

import { SectionBlocksProps } from './interfaces';

import useStyles from './style';

const SectionBlocks = <TSection extends TimelineSectionBase>({
  section,
  sections,
  songsBlockProps,
  questionsBlockProps,
  notesBlockProps,
  updateSection,
  onEditClick,
}: SectionBlocksProps<TSection>): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isEventGuest, isEventHost } = useContext(EventContext);

  const isSongsShown = section.settings.songsEnabled;
  const isQuestionsShown = section.settings.questionsEnabled && !isEventGuest;
  const isNotesShown =
    section.settings.notesEnabled &&
    (isEventHost ? isEventHost && section.settings.notesVisibleForHosts : !isEventGuest);

  const isBlocksOn = isSongsShown || isQuestionsShown || isNotesShown;
  const isHeadline = useMemo(() => isHeadlineSection(section), [section.type]);

  return (
    <>
      <SectionBlocksHeader section={section} onEditClick={onEditClick} />
      <Scrollbar id="timeline-blocks">
        {!!section.sectionImage?.imageUrl ? (
          <ContentArea className={classNames(classes.sectionCoverImgWrapper)} shadowed>
            <img
              src={section.sectionImage?.imageUrl}
              className={classes.sectionCoverImg}
              alt="section cover"
            />
            {!!section.description ? (
              <SectionDescription description={section.description} />
            ) : null}
          </ContentArea>
        ) : null}
        {!!section.description && !section.sectionImage?.imageUrl ? (
          <SectionDescription description={section.description} />
        ) : null}
        {!isBlocksOn ? (
          isHeadline ? null : (
            <GearResult>{t('songsQuestionsNotesTurnedOff')}</GearResult>
          )
        ) : null}
        {isSongsShown ? (
          <TimelineSongsBlock
            {...songsBlockProps}
            section={section}
            sections={sections}
            key={`timeline-songs-block-${section._id}`}
          />
        ) : null}
        {isQuestionsShown ? (
          <TimelineQuestionsBlock
            {...questionsBlockProps}
            section={section}
            sections={sections}
            key={`timeline-qustions-block-${section._id}`}
          />
        ) : null}
        {isNotesShown ? (
          <TimelineNotesBlock
            {...notesBlockProps}
            section={section}
            sections={sections}
            // @ts-ignore
            updateSection={updateSection}
            key={`timeline-notes-block-${section._id}`}
          />
        ) : null}
      </Scrollbar>
    </>
  );
};

export default SectionBlocks;
