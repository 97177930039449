import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  templatePage: {
    background: 'var(--background-default)',
    height: '100%',
    width: '100%',

    '& > .container': {
      height: 'calc(100vh - 40px)!important',

      '& .containerContent': {
        height: '100%',
        minHeight: '100%',
      },

      '@media screen and (min-width: 1441px)': {
        height: 'calc(100vh - 50px)!important',
      },
    },
  },
}));

export default useStyles;
