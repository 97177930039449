import React, { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import NotificationsPage from 'components/notificationsPage';
import { NotificationsContextProvider } from 'components/context/NotificationsContext';

import {
  decreaseTemplateNotifsCount,
  increaseTemplateNotifsCount,
  removeTemplateCustomNotifiaction,
  updateTemplateNotif,
} from 'graphql/cache/notifications';

import { UPDATE_TEMPLATE } from 'graphql/mutations/template';
import { GET_TEMPLATE_CUSTOM_NOTIFICATIONS } from 'graphql/queries/notifications';
import {
  CREATE_TEMPLATE_CUSTOM_NOTIFICATION,
  UPDATE_TEMPLATE_CUSTOM_NOTIFICATION,
  REMOVE_TEMPLATE_CUSTOM_NOTIFICATION,
  REORDER_TEMPLATE_CUSTOM_NOTIFICATIONS,
} from 'graphql/mutations/notifications';

const TemplateNotifPage: FC = () => {
  const { id } = useParams<EventPageRouteParams>();

  const updateNotification = useCallback(data => {
    updateTemplateNotif(data.updateTemplateCustomNotification);
  }, []);

  const mutations = useMemo(
    () => ({
      create: CREATE_TEMPLATE_CUSTOM_NOTIFICATION,
      update: UPDATE_TEMPLATE_CUSTOM_NOTIFICATION,
      remove: REMOVE_TEMPLATE_CUSTOM_NOTIFICATION,
      reorder: REORDER_TEMPLATE_CUSTOM_NOTIFICATIONS,
      updateEvent: UPDATE_TEMPLATE,
    }),
    []
  );
  const queries = useMemo(
    () => ({
      notifications: GET_TEMPLATE_CUSTOM_NOTIFICATIONS,
      key: 'templateCustomNotifications',
    }),
    []
  );
  const cache = useMemo(
    () => ({
      removeNotification: removeTemplateCustomNotifiaction,
      decreaseNotifsCount: () => {
        decreaseTemplateNotifsCount(id);
      },
      increaseNotifsCount: () => {
        increaseTemplateNotifsCount(id);
      },
      updateNotification,
    }),
    [id]
  );
  const variables = useMemo(
    () => ({
      templateId: id,
    }),
    [id]
  );
  const refetchQueries = useMemo(() => ['templateCustomNotifications'], []);

  return (
    <NotificationsContextProvider
      value={{
        mutations,
        queries,
        cache,
        variables,
        refetchQueries,
      }}
    >
      <NotificationsPage />
    </NotificationsContextProvider>
  );
};

export default TemplateNotifPage;
