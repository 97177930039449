import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import GeneralNotifRow from './GeneralNotifRow';
import ContentArea from 'components/common/ContentArea';

import { DEFAULT_NOTIF_RECIPIENTS } from './constants';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventRelatedNotificationTypes } from 'types/enums';

const DefaultNotifSettings: FC = () => {
  const { t } = useTranslation();

  const defaultRecipientsLabel = useMemo(
    () => `${t('whoWillBeNotified')}: ${DEFAULT_NOTIF_RECIPIENTS}`,
    []
  );

  return (
    <ContentArea shadowed>
      <GeneralNotifRow
        icon={IconmoonFont['userJoined-24']}
        name={t('hostGuestJoinedEvent')}
        recipients={defaultRecipientsLabel}
        notifType={EventRelatedNotificationTypes.hostGuestJoinedEvent}
      />
      <GeneralNotifRow
        icon={IconmoonFont['userLeft-24']}
        name={t('hostGuestLeftEvent')}
        recipients={defaultRecipientsLabel}
        notifType={EventRelatedNotificationTypes.hostGuestLeftEvent}
      />
      <GeneralNotifRow
        icon={IconmoonFont['locked-24']}
        name={t('eventLockedForChanges')}
        recipients={`${defaultRecipientsLabel} Guests`}
        notifType={EventRelatedNotificationTypes.eventLocked}
      />
    </ContentArea>
  );
};

export default DefaultNotifSettings;
