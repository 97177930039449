import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  moreToolsSectionsMenu: {
    '& .rc-tooltip-inner': {
      padding: 0,
    },

    '& .rc-menu-item-disabled': {
      '& .viboIcon': {
        color: `var(--text-hint)!important`,
      },
    },
  },
}));

export default useStyles;
