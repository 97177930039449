import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  commentPreview: {
    ...mixins.longText,
    backgroundColor: 'var(--background-paper)',
    width: 'fit-content',
    fontSize: '14px',
    lineHeight: '21.07px',
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    color: 'var(--text-primary)',
    maxWidth: '98px',
    border: `1px solid var(--text-hint)`,
    borderRadius: '4px',
    flex: 'inherit!important',
    padding: '2px 6px',

    '@media screen and (min-width: 1242px)': {
      whiteSpace: 'pre-wrap',
    },

    '@media screen and (min-width: 1441px)': {
      maxWidth: '150px',
      padding: '4px 8px',
      lineHeight: '15.5px',
    },

    '@media screen and (min-width: 1537px)': {
      maxWidth: '160px',
    },
  },
}));

export default useStyles;
