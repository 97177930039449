import React, { FC, useCallback, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import ContentArea from 'components/common/ContentArea';
import FormInput from 'components/form/inputs/FormInput';
import ClickAwayListener from 'vibo-ui/ClickAwayListener';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import Select, { SimpleOption } from 'vibo-ui/Select';

import { EventStatusDate, TemporalOrder, TimeUnit } from 'types/enums';
import { CreateCustomNotifForm } from 'components/context/NotificationsContext/interfaces';

import { TIME_NUMBER_SUGGESTIONS, TIME_UNIT_MAX } from '../constants';

import useStyles from '../style';

const TimeBlock: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { values, setFieldValue } = useFormikContext<CreateCustomNotifForm>();

  const isTimeUnitOn = useMemo(() => !!values.sendingTimeSettings?.number, [
    values.sendingTimeSettings?.number,
  ]);

  const handleChangeTimeSetting = useCallback((option, field: string) => {
    const { value } = option as SimpleOption;

    setFieldValue(`sendingTimeSettings.${field}`, value);
  }, []);

  const handleFocusTimeNumber = useCallback(() => setIsOpen(true), []);
  const handleBlurTimeNumber = useCallback(() => setIsOpen(false), []);

  const isBeforeSelected = useMemo(
    () => values.sendingTimeSettings?.temporalOrder === TemporalOrder.before,
    [values.sendingTimeSettings?.temporalOrder]
  );
  const isEventCreatedDateSelected = useMemo(
    () => values.sendingTimeSettings?.eventStatusDate === EventStatusDate.eventCreatedDate,
    [values.sendingTimeSettings?.eventStatusDate]
  );

  const timeUnitOptions = useMemo(
    () =>
      Object.values(TimeUnit).map(unit => ({
        label: t(unit).toLocaleLowerCase(),
        value: unit,
      })),
    []
  );
  const timeOrderOptions = useMemo(
    () =>
      Object.values(TemporalOrder).map(order => ({
        label: t(order).toLocaleLowerCase(),
        value: order,
        isDisabled: order === TemporalOrder.before && isEventCreatedDateSelected,
      })),
    [isEventCreatedDateSelected]
  );
  const timeStatusOptions = useMemo(
    () =>
      Object.values(EventStatusDate).map(status => ({
        label: t(status).toLocaleLowerCase(),
        value: status,
        isDisabled: status === EventStatusDate.eventCreatedDate && isBeforeSelected,
      })),
    [isBeforeSelected]
  );

  return (
    <ContentArea className="row" shadowed>
      <Icon icon={IconmoonFont['clock-16']} />
      <ClickAwayListener onClickAway={handleBlurTimeNumber}>
        <FormInput
          name="sendingTimeSettings.number"
          onClick={handleFocusTimeNumber}
          min={0}
          max={TIME_UNIT_MAX}
          placeholder="00"
          type="number"
        />
        <Select
          value={values.sendingTimeSettings?.number}
          onChange={e => {
            handleChangeTimeSetting(e, 'number');
            handleBlurTimeNumber();
          }}
          options={TIME_NUMBER_SUGGESTIONS}
          components={{
            ValueContainer: () => null,
          }}
          styleModificator="asLink"
          menuPlacement="bottom"
          menuIsOpen={isOpen}
          className={classes.selectTimeSettingMenu}
          focusSelectedOption
          withCustomValueContainer
          hideDropdownIndicator
        />
      </ClickAwayListener>
      <Select
        value={values.sendingTimeSettings?.timeUnit}
        onChange={e => handleChangeTimeSetting(e, 'timeUnit')}
        options={timeUnitOptions}
        styleModificator="asLink"
        isDisabled={!isTimeUnitOn}
        hideDropdownIndicator
      />
      <Select
        value={values.sendingTimeSettings?.temporalOrder}
        onChange={e => handleChangeTimeSetting(e, 'temporalOrder')}
        options={timeOrderOptions}
        styleModificator="asLink"
        isDisabled={!isTimeUnitOn}
        hideDropdownIndicator
      />
      <Select
        value={values.sendingTimeSettings?.eventStatusDate}
        onChange={e => handleChangeTimeSetting(e, 'eventStatusDate')}
        options={timeStatusOptions}
        styleModificator="asLink"
        isDisabled={!isTimeUnitOn}
        hideDropdownIndicator
      />
    </ContentArea>
  );
};

export default TimeBlock;
