import { getCssColor } from 'components/context/ViboThemeContext/constants';

export const WAVE_CONFIG = {
  responsive: true,
  cursorWidth: 0,
  height: 40,
  barGap: 1,
  barWidth: 2,
};

export const VOLUME_STEP = 0.05;

export const HALF_VOLUME = 0.5;

export const MIN_VOLUME = 0;

export const MAX_VOLUME = 1;

export const normilizeVolume = (volume: number) => {
  if (volume > MAX_VOLUME) {
    return MAX_VOLUME;
  }

  if (volume < MIN_VOLUME) {
    return MIN_VOLUME;
  }

  return volume;
};

export const getWaveColor = () => getCssColor('--dustGrayToSilver');
