import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  flag: {
    margin: '0 8px',

    '@media screen and (min-width: 1500px)': {
      margin: '0 12px',
    },
  },

  isFlagged: {
    '& svg path': {
      fill: 'var(--error-main)',
    },
  },
}));

export default useStyles;
