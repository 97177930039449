import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  brick: {
    borderRadius: '4px',
    background: 'var(--overlay-skeleton)',
    position: 'relative',
    overflow: 'hidden',

    '&:before': {
      content: '""',
      position: 'absolute',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%)',
      height: '100%',
      width: '100%',
      zIndex: 1,
      animation: 'brick-run 1.2s infinite',
    },
  },

  '@global': {
    '@keyframes brick-run': {
      '0%': {
        transform: 'translateX(-100%)',
      },
      '100%': {
        transform: 'translateX(100%)',
      },
    },
  },
}));

export default useStyles;
