import React, { FC } from 'react';
import classNames from 'classnames';

import Tooltip from 'vibo-ui/Tooltip';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { InfoIconProps } from './interfaces';

import useStyles from './style';

const InfoIcon: FC<InfoIconProps> = ({ className, text = '' }) => {
  const classes = useStyles();

  return (
    <Tooltip overlay={text} type="primary" placement="top">
      <Icon
        icon={IconmoonFont['infoRotatedOutlined-16']}
        className={classNames('infoIcon', classes.infoIcon, className)}
      />
    </Tooltip>
  );
};

export default InfoIcon;
