import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  progressBar: {
    height: '16px',
    width: '30px',
    backgroundColor: 'var(--clr-gray)',
    position: 'relative',
    borderRadius: '4px',
    color: 'var(--primary-contrastText)',

    '& span': {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
    },
  },

  fill: {
    height: '100%',
    borderRadius: 'inherit',
    position: 'absolute',
  },

  progress: {
    margin: 'auto',
    fontWeight: 'bold',
  },
}));

export default useStyles;
