import pick from 'lodash/pick';

export const ALLOWED_FIELDS_TO_ANSWER = ['answer'];

export const getOptionsForUpdate = (options: QuestionOption[]) => {
  return options.map(option => pick(option, ['title', 'images']));
};

export const getQuestionToCompareUpdate = (question: Question) => {
  return {
    ...question.question,
    options: getOptionsForUpdate(question.question.options),
  };
};
