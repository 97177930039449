const COLORS = {
  statusRed: '#D11111',
  statusBlue: '#1A7EE3',
  statusGreen: '#08C004',
  statusPurple: '#9026AA',
  statusYellow: '#C79108',
  statusGrey: '#999999',
  statusBlack: 'var(--clr-black)',
};

export default COLORS;
