import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  preview: {
    padding: '12px',
    color: 'var(--text-primary)',

    '& p, & h1, & h2': {
      margin: '0',
    },

    '& .ql-editor': {
      padding: '0',
      fontFamily: 'Roboto, sans-serif',

      '& a': {
        color: 'var(--primary-main)',
      },

      '& li': {
        padding: '0!important',
      },
    },

    '&.placeholder': {
      color: 'var(--text-hint)',
    },
  },
}));

export default useStyles;
