import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  timelineLayout: {
    background: 'var(--background-default)',
    height: 'calc(100% - 60px)',

    '& .container': {
      height: '100%',

      '& .containerContent': {
        height: '100%',
      },
    },

    '@media screen and (min-width: 1441px)': {
      height: 'calc(100% - 90px)',
    },
  },

  content: {
    margin: '0',
    boxSizing: 'border-box',
    height: '100%',
    maxHeight: 'calc(100vh - 120px)',
    gap: 'var( --timeline-conten-gap)',
    display: 'flex',
    width: '100%',

    '@media screen and (min-width: 1441px) ': {
      maxHeight: 'calc(100vh - 140px)',
    },
  },

  left: {
    width: 'var(--timeline-sections-w)',
    minWidth: 'var(--timeline-sections-w)',
    position: 'relative',
    maxHeight: 'inherit',
  },

  right: {
    flex: 1,
    maxWidth: 'calc(100% - var(--timeline-sections-w) - var( --timeline-conten-gap))',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'inherit',

    '& > .viboScrollbar': {
      height: 'calc(100% - 66px)',
      display: 'flex',
      flexDirection: 'column',

      '& .emptyState': {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '12px',

        '& .stateIcon svg': {
          width: '180px',
          height: '180px',

          '@media screen and (min-width: 1441px)': {
            width: '256px',
            height: '256px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          gap: '16px',
        },
      },

      '& .sectionBlock': {
        marginBottom: '26px',

        '@media screen and (min-width: 1441px) ': {
          marginBottom: '36px',
        },
      },
    },
  },
}));

export default useStyles;
