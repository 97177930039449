import React, { FC, useCallback, useMemo, useRef } from 'react';
import YouTube from 'react-youtube';

import { getVideoIdByLink, isLoomLink, isVimdeoLink } from 'services/common/videoHelpers';
import { DEFAULT_OPTIONS, VIMEO_PLAYER_LINK } from './constants';

import { VideoPreviewModalProps } from './interfaces';

const Video: FC<VideoPreviewModalProps> = ({ videoLink, options = DEFAULT_OPTIONS }) => {
  const iframeRef = useRef<Nullable<HTMLIFrameElement>>(null);

  const isLoom = useMemo(() => isLoomLink(videoLink), [videoLink]);
  const isVimdeo = useMemo(() => isVimdeoLink(videoLink), [videoLink]);

  const videoId = useMemo(() => getVideoIdByLink(videoLink), [videoLink]);

  const iframeLink = useMemo(() => (isLoom ? videoLink : `${VIMEO_PLAYER_LINK}${videoId}`), [
    videoLink,
    videoId,
    isLoom,
  ]);

  const handlePlay = useCallback(e => {
    e.currentTarget.contentWindow?.postMessage({ method: 'play', value: true }, '*');
  }, []);

  return isLoom || isVimdeo ? (
    <iframe
      src={iframeLink}
      title={`iframe-video-${iframeLink}`}
      ref={iframeRef}
      allow="autoplay; muted; fullscreen;"
      onLoad={handlePlay}
      allowFullScreen
    />
  ) : (
    // @ts-ignore
    <YouTube
      videoId={videoId}
      // @ts-ignore
      opts={options}
    />
  );
};

export default Video;
