import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  customLabel: {
    width: 'fit-content',
    borderRadius: '4px',
    fontSize: '11px',
    lineHeight: '1px',
    padding: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .viboIcon': {
      marginRight: '2px',
    },

    '&.default': {
      color: 'var(--text-secondary)',
      border: `1px solid var(--text-secondary)`,
    },

    '&.success': {
      color: 'var(--success-main)',
      border: `1px solid var(--success-main)`,

      '&.filled': {
        color: 'var(--clr-white)',
        backgroundColor: 'var(--success-main)',
      },
    },

    '&.whiteBg': {
      backgroundColor: 'var(--background-dropDown)',
    },

    '&.gray': {
      color: 'var(--text-hint)',
      border: `1px solid var(--text-hint)`,

      '&.filled': {
        color: 'var(--clr-white)',
        backgroundColor: 'var(--text-hint)',
      },
    },

    '&.primary': {
      color: 'var(--primary-main)',
      border: `1px solid var(--primary-main)`,

      '&.filled': {
        color: 'var(--clr-white)',
        backgroundColor: 'var(--primary-main)',
      },
    },

    '&.sm': {
      height: '14px',
      fontSize: '9px',

      '@media screen and (min-width: 1441px)': {
        height: '16px',
        fontSize: '12px',
      },
    },

    '&.lg': {
      padding: '0 6px',
      height: '26px',
      fontSize: '11px',
      lineHeight: '14px',

      '@media screen and (min-width: 1441px)': {
        height: '30px',
        fontSize: '14px',
        lineHeight: '18px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '14px',
    },
  },
}));

export default useStyles;
