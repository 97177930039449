import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  prepModeSongsSubHeader: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    '& > *': {
      height: '24px',

      '&:not(:last-child):not(.viboButton)': {
        margin: '0 4px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 6px 0 0',
        },
      },

      '@media screen and (min-width: 1441px)': {
        height: '28px',
      },
    },

    '& > .viboSelect': {
      '& .highlighted': {
        color: 'var(--primary-main)',
      },
    },
  },

  markBestBtn: {
    marginRight: '12px',

    '& svg': {
      position: 'relative',
    },

    '&:active, &:focus': {
      color: 'var(--primary-main)',
    },
  },

  toggler: {
    padding: '0',

    '& .sufixIcon': {
      margin: '0 0 0 4px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 6px',
      },
    },
  },
}));

export default useStyles;
