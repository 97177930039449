import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  templatesPage: {
    background: 'var(--background-default)',
    height: '100%',
    padding: '0',
  },

  container: {
    height: '100%',
  },
}));

export default useStyles;
