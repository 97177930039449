import { createUseStyles } from 'react-jss';

const disabledOptionStyle = () => ({
  position: 'relative',
  userSelect: 'none',

  '&:after': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'var(--overlay-default)',
    borderRadius: '4px',
    left: 0,
    top: 0,
    zIndex: 5,
  },

  '& *': {
    pointerEvents: 'none',
  },
});

const useStyles = createUseStyles(() => ({
  eventLayoutPdf: {
    overflow: 'hidden',

    '& > .container > .containerContent': {
      flexDirection: 'row!important',
    },
  },

  promptIcon: {
    margin: '0 0 0 8px',

    '& svg': {
      width: '16px',
      heighT: '16px',
    },
  },

  settingOptions: {
    '& .settingsOption': {
      '&:not(:last-child)': {
        margin: '0 0 8px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 12px 0',
        },
      },
    },
  },

  settingsOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '13px 14px',
    backgroundColor: 'var(--background-paper)',
    color: 'var(--primary-main)',
    fontSize: '15px',
    lineHeight: '22.5px',
    cursor: 'pointer',

    '&.active': {
      backgroundColor: 'var(--primary-main)',
      color: 'var(--clr-white)',
      position: 'relative',
    },

    '&:hover:not(.active)': {
      backgroundColor: 'var(--sectionHover)',
    },

    '& .viboIcon': {
      margin: '0 8px 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 12px 0 0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '15px 16px',
      fontSize: '18px',
      lineHeight: '27px',
    },
  },

  content: {
    height: 'calc(100vh - 40px)',
    display: 'flex',
    flexDirection: 'column',
    width: '536px',

    '& > .viboScrollbar': {
      paddingTop: 0,

      '& > div:last-of-type': {
        marginBottom: '700px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '796px',
      height: 'calc(100vh - 48px)',
    },

    '@media screen and (min-width: 1441px) and (max-width: 1536px)': {
      width: '700px',
    },
  },

  notesContent: {
    color: 'var(--text-primary)',
    padding: '12px',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.56px',
    wordBreak: 'break-all',

    '& .emptyState': {
      padding: '0 0 40px 0',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '16px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  eventInfoContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',

    '& strong': {
      fontWeight: 700,
    },

    '& .eventInfoRow': {
      '&:not(:last-child)': {
        margin: '0 0 22px 0',
      },

      '& svg path': {
        fill: 'var(--text-secondary)',
      },
    },

    '& .viboIcon, & .eventTypeIcon': {
      color: 'var(--text-secondary)',
      margin: '0 12px 0 0',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '16px',
    },
  },

  eventInfoTime: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .name': {
      color: 'var(--text-secondary)',
      margin: '0 3px 0 0',
      fontSize: '13px',
      lineHeight: '19.5px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  eventInfoText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  eventInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.56px',
    color: 'var(--text-primary)',

    '& .eventInfoTime': {
      '&:not(:last-child)': {
        margin: '0 75px 0 0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  pdfContentHeader: {
    '& .viboButton': {
      margin: '0 0 0 auto',
    },
  },

  pdfSettings: {
    width: '288px',
    margin: '0 auto 0 0',

    '@media screen and (min-width: 1441px)': {
      width: '390px',
    },
  },

  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 34px 0',
    position: 'relative',
    userSelect: 'none',

    '&[data-spy-id="playlistsLayout"]': {
      '& .headerMain': {
        alignItems: 'center',
      },
    },

    '& .cellName': {
      display: 'block',
      paddingLeft: '7px',
      width: '96px',

      '&:not(:last-child)': {
        margin: '0 12px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 16px 0 0',
        },
      },

      '@media screen and (min-width: 1441px)': {
        width: '120px',
      },
    },

    '& .contentBlockHeader': {
      position: 'sticky',
      top: 0,
      zIndex: 10,
    },

    '& .viboList': {
      '& .cellName': {
        borderLeft: `1px solid var(--primaryLightToGray)`,
      },

      '& .pdfSectionRow': {
        height: '48px',

        '@media screen and (min-width: 1441px)': {
          height: '56px',
        },
      },
    },

    '& .pdfSectionsHeader, & .pdfSectionRow': {
      padding: '0 0 0 12px',

      '& .headerMain > .viboCheckbox, & > .viboCheckbox': {
        margin: '0 4px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 8px 0 0',
        },
      },

      '@media screen and (min-width: 1441px)': {
        padding: '0 0 0 16px',
      },
    },

    '& .pdfSectionRow': {
      '&:not(:last-child)': {
        borderBottom: `1px solid var(--primaryLightToGray)`,
      },
    },

    '& abbr': {
      fontSize: '17px',
      lineHeight: '24.09px',
      color: 'var(--text-hint)',
      display: 'block',
      textAlign: 'center',
      width: '100%',
      padding: '20px 0',

      '@media screen and (min-width: 1441px)': {
        fontSize: '20px',
        lineHeight: '28.34px',
      },
    },
  },

  contentBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 0 10px 13px',
    backgroundColor: 'var(--background-default)',
    margin: '0 0 3px -3px',
    width: 'calc(100% + 6px)',

    '&.disabled': {
      '& ~ .contentArea': {
        ...disabledOptionStyle(),
      },
    },

    '& h3': {
      fontSize: '17px',
      lineHeight: '24px',
      margin: '0 6px 0 0',

      '@media screen and (min-width: 1441px)': {
        fontSize: '20px',
        lineHeight: '28px',
        margin: '0 8px 0 0',
      },
    },

    '& .viboMenu': {
      margin: '0 0 0 auto',
    },
  },

  pdfDjs: {
    '&.short': {
      '& .djPreview .content': {
        display: 'block!important',
      },
    },

    '& .tooltipContent': {
      '&:not(:last-child)': {
        '& .djPreview:after': {
          content: '","',
        },
      },
    },

    '& .djPreview': {
      '& .djName': {
        maxWidth: 'none',
      },

      '& .viboImage': {
        display: 'none',
      },

      '& .content': {
        margin: '0',
      },
    },
  },
}));

export default useStyles;
