import { NotificationStatus } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';

export const NOTIF_ICON_BY_ENUM = {
  [NotificationStatus.sent]: IconmoonFont['checkedCircleFilled-16'],
  [NotificationStatus.pending]: IconmoonFont['notif-pendding-16'],
  [NotificationStatus.cannotBeSend]: IconmoonFont['infoCircleFilled-16'],
};

export const DEFAULT_NOTIF_RECIPIENTS = 'DJs Hosts';

export const CUSTOM_NOTIFICATIONS_LIMIT = 30;
