import React, { FC, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import SettingsItem from './SettingsItem';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { ViboThemeContext } from 'components/context/ViboThemeContext';

import { ViboThemeEnum } from 'components/context/ViboThemeContext/interfaces';
import { ThemeModeNames } from './interfaces';

import useStyles from './style';

const ThemeControl: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isSysThemeUsing, setTheme, setIsSysThemeUsing } = useContext(ViboThemeContext);

  const handleUpdateTheme = useCallback(
    theme => {
      setTheme?.(theme);
      isSysThemeUsing && setIsSysThemeUsing(false);
    },
    [isSysThemeUsing]
  );

  const handleUseSysTheme = useCallback(() => {
    setIsSysThemeUsing(true);
  }, []);

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={IconmoonFont['sun-24']} className={classes.generalIcon} />
        {t('theme')}
        <Menu
          className={classNames('themeMenu', {
            isSysThemeUsing,
          })}
          styleModificator="asTabsLg"
          selectable={false}
        >
          <Menu.Item
            onClick={() => handleUpdateTheme(ViboThemeEnum.light)}
            className={ThemeModeNames.light}
            key={ThemeModeNames.light}
          >
            {t('light')}
          </Menu.Item>
          <Menu.Item
            onClick={() => handleUpdateTheme(ViboThemeEnum.dark)}
            className={ThemeModeNames.dark}
            key={ThemeModeNames.dark}
          >
            {t('dark')}
          </Menu.Item>
          <Menu.Item
            onClick={handleUseSysTheme}
            className={ThemeModeNames.system}
            key={ThemeModeNames.system}
          >
            {t('systemDefault')}
          </Menu.Item>
        </Menu>
      </div>
    </SettingsItem>
  );
};

export default ThemeControl;
