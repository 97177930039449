import { Dispatch } from 'react';

export type TViboThemeContext = {
  toggleTheme: () => void;
  setTheme: Dispatch<ViboThemeEnum>;
  setModeColorsHex: Dispatch<ModeColorsHex>;
  resetToAppColor: () => void;
  setIsSysThemeUsing: Dispatch<boolean>;
  isSysThemeUsing: boolean;
  isReady: boolean;
};

export type ModeColorsHex = {
  light: string;
  dark: string;
};

export enum ViboThemeEnum {
  'light' = 'light',
  'dark' = 'dark',
}
