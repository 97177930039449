import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  layout: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'var(--background-paper)',
    display: 'flex',
    flexDirection: 'row',

    '& > .content': {
      width: '100%',
      overflow: 'hidden',
      height: '100%',
      position: 'relative',
    },
  },
}));

export default useStyles;
