import React, { FC, createContext, useCallback, useContext, useEffect } from 'react';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { FormikProvider } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import LoadingOverlay from 'components/common/LoadingOverlay';
import { EventContext } from '../EventContext';

import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { useFormikAutoSave } from 'graphql/hooks/form';
import { reorderCustomNotifiactions } from 'graphql/cache/notifications';
import { ALLOWED_FIELDS_UPDATE_NORIF } from './constants';

import { EventRelatedNotificationTypes, Modals } from 'types/enums';
import {
  CreateCustomNotifForm,
  DisabledNotificationsForm,
  EventNotificationsData,
  EventNotificationsResponse,
  ReorderCustomNotification,
  TNotificationsContext,
  TNotificationsContextProvider,
} from './interfaces';
import { useModal } from 'vibo-ui/Modal';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';

export const NotificationsContext = createContext<TNotificationsContext>(undefined!);

export const NotificationsContextProvider: FC<{ value: TNotificationsContextProvider }> = ({
  children,
  value,
}) => {
  const { t } = useTranslation();

  const { event, isLoading } = useContext(EventContext);

  const { openModal } = useModal();

  const { formik } = useFormikAutoSave<DisabledNotificationsForm>(
    {
      initialValues: {
        disabledNotifications: event?.settings?.disabledNotifications,
        disableAllPushNotifications: event?.settings?.disableAllPushNotifications,
      },
      onSubmit: payload => {
        const isChanged = !isEqual(
          {
            ...event?.settings,
            ...payload,
          },
          event?.settings
        );

        isChanged &&
          updateEvent({
            variables: {
              ...value.variables,
              payload: {
                settings: {
                  ...payload,
                },
              },
            },
          });
      },
    },
    2000
  );

  const { data: notificationsResponse, loading: notificationsLoading } = useQuery<
    EventNotificationsResponse
  >(value.queries.notifications, {
    variables: value.variables,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  // @ts-ignore
  const notifications = notificationsResponse?.[value.queries.key] as EventNotificationsData;

  const [updateEvent] = useMutation(value.mutations.updateEvent, {
    onError,
  });

  const [createCustomNotification] = useMutation(value.mutations.create, {
    onCompleted: () => {
      value.cache.increaseNotifsCount();

      toastNotify({
        text: t('notificationCreatedSuccessfully'),
      });
    },
    onError,
    refetchQueries: value.refetchQueries,
  });
  const [updateCustomNotification] = useMutation(value.mutations.update, {
    onCompleted: notifData => {
      value.cache.updateNotification(notifData);
      toastNotify({
        text: t('notificationUpdatedSuccessfully'),
      });
    },
    onError,
  });
  const [removeCustomNotification] = useMutation(value.mutations.remove, {
    onCompleted: () => {
      value.cache.decreaseNotifsCount();

      toastNotify({
        text: t('notificationDeletedSuccessfully'),
      });
    },
    onError,
  });
  const [reorderCustomNotifications] = useMutation(value.mutations.reorder, {
    onError,
  });

  const create = useCallback(
    (notifData: CreateCustomNotifForm) =>
      createCustomNotification({
        variables: {
          ...value.variables,
          payload: notifData,
        },
      }),
    [value.variables]
  );
  const update = useCallback(
    (notifData: CreateCustomNotifForm) => {
      updateCustomNotification({
        variables: {
          ...value.variables,
          customNotificationId: notifData._id,
          payload: pick(notifData, ALLOWED_FIELDS_UPDATE_NORIF),
        },
      });
    },
    [value.variables]
  );
  const remove = useCallback(
    (customNotificationId: string) => {
      removeCustomNotification({
        variables: {
          ...value.variables,
          customNotificationId,
        },
      });

      value.cache.removeNotification(customNotificationId);
    },
    [value.variables]
  );
  const reorder: ReorderCustomNotification = useCallback(
    ({ data, source }) => {
      reorderCustomNotifiactions({
        variables: value.variables,
        key: value.queries.key,
        query: value.queries.notifications,
        data,
      });
      reorderCustomNotifications({
        variables: {
          ...source,
          ...value.variables,
        },
      });
    },
    [value.variables]
  );

  const updateEventNotification = useCallback(
    (notifType: EventRelatedNotificationTypes) => {
      const disabledNotifications = formik.values.disabledNotifications || [];
      const isNotifOff = disabledNotifications?.includes(notifType);
      const newNotifSettings = isNotifOff
        ? disabledNotifications.filter(notif => notif !== notifType)
        : [...disabledNotifications, notifType];

      formik.setFieldValue('disabledNotifications', newNotifSettings);
    },
    [formik.values.disabledNotifications]
  );

  const openConfirmDeleteNotifModal = (customNotificationId: string) =>
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('deleteNotification'),
        children: t('sureYouWantDeleteNotification'),
        submit: {
          text: t('delete'),
          onClick: () => remove(customNotificationId),
        },
      },
    });

  useEffect(() => {
    formik.setFormikState(prev => ({
      ...prev,
      values: {
        disabledNotifications: event?.settings?.disabledNotifications,
        disableAllPushNotifications: event?.settings?.disableAllPushNotifications,
      },
    }));
  }, [event?.settings]);

  return (
    <NotificationsContext.Provider
      value={{
        send: value.send,
        create,
        update,
        remove: openConfirmDeleteNotifModal,
        reorder,
        updateEventNotification,
        notifications,
        notificationsLoading,
      }}
    >
      {isLoading || !event ? (
        <LoadingOverlay />
      ) : (
        <FormikProvider value={formik}>{children}</FormikProvider>
      )}
    </NotificationsContext.Provider>
  );
};
