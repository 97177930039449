import DOMPurify from 'dompurify';

import { MagicFieldOption } from './interfaces';

export const MF_START = '$';
export const MF_END = '$';
export const MF_START_REGEXP = '\\$';
export const MF_END_REGEXP = '\\$';

export const getSanitizedField = (html: string) =>
  DOMPurify.sanitize(html, {
    ALLOWED_ATTR: ['contenteditable'],
    ALLOWED_TAGS: ['mf'],
  });

export const getMagicFieldTag = (value: string) => `<mf contenteditable="false">${value}</mf>`;

export const parseToMagicFieldValue = (value: string, options?: MagicFieldOption[]): string => {
  let result = value;

  options?.forEach(option => {
    const regex = new RegExp(`${MF_START_REGEXP}${option.value}${MF_END_REGEXP}`, 'g');
    result = result.replace(regex, getMagicFieldTag(option.label));
  });

  return result;
};

export const parseFromMagicFieldValue = (html: string, options?: MagicFieldOption[]): string => {
  let result = html;

  options?.forEach(option => {
    const regex = new RegExp(`<mf[^>]*>${option.label}</mf>`, 'g');
    result = result.replace(regex, `${MF_START}${option.value}${MF_END}`);
  });

  return result;
};
