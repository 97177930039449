import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  prepModeSong: {
    width: '100%',
    margin: '0',
    transition: 'box-shadow .1s',
    overflow: 'hidden',

    '& .copyButton': {
      opacity: 0,
    },

    '& .songArtistNames, & .fileName': {
      ...mixins.longText,
      flexGrow: 'initial',
      maxWidth: '400px',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '600px',
      },

      '& .songName': {
        ...mixins.longText,
      },
    },

    '& .prepModeSong': {
      color: 'var(--text-primary)',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '16px',

      '& .prepModeMeidaLinks': {
        opacity: '0',
        pointerEvents: 'none',
        maxWidth: '0',
      },

      '& .flag': {
        width: '14px',
        height: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },
    },

    '& .prepModeFile': {
      marginTop: '4px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& .fileRight': {
        opacity: '0',
        pointerEvents: 'none',
        maxWidth: '0',
      },
    },

    '&:hover': {
      '& .copyButton': {
        opacity: 1,
      },

      '& .prepModeSongHiddenIcon': {
        display: 'flex',
      },

      '& .prepModeFile': {
        '& .fileRight': {
          opacity: '1',
          pointerEvents: 'all',
          maxWidth: 'fit-content',
        },
      },

      '& .prepModeSong': {
        '& .prepModeMeidaLinks': {
          opacity: '1',
          pointerEvents: 'all',
          maxWidth: 'fit-content',
        },

        '& .songArtistNames, & .fileName': {
          maxWidth: '250px',

          '@media screen and (min-width: 1441px)': {
            maxWidth: '450px',
          },
        },
      },
    },
  },

  expandedSong: {
    borderRadius: '4px',
    border: `1px solid var(--primary-main)`,
  },

  copyNameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .copyButton': {
      margin: '0 0 0 4px',
    },
  },

  index: {
    fontWeight: 500,
    margin: '0 30px 0 4px',
    width: '19px',
    fontSize: '11px',
    lineHeight: '15.4px',
    color: 'var(--text-secondary)',
    textAlign: 'center',

    '@media screen and (min-width: 1441px)': {
      margin: '0 30px 0 8px',
      width: '29px',
      fontSize: '14px',
      lineHeight: '19.6px',
    },
  },

  songLeft: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: '14px',
      minWidth: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        minWidth: '16px',
      },
    },
  },

  artistNames: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',

    '& .songName, & .artistName': {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '19.5px',
      width: 'fit-content',
      flexGrow: 'initial',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  songRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'var(--text-hint)',

    '& svg': {
      width: '26.2px',
      height: '26.2px',
      transform: 'translateY(-5px)',
    },
  },

  clickable: {
    cursor: 'pointer',
  },

  flagOutlined: {
    color: 'var(--text-secondary)',

    '&:hover': {
      color: 'var(--error-main)',
    },
  },

  flagFilled: {
    color: 'var(--error-main)',
  },

  noMatchesText: {
    marginTop: '4px',
    paddingLeft: '24px',
    color: 'var(--error-main)',
  },
}));

export default useStyles;
