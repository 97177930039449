import { setCssVar } from 'services/common/domHelpers';
import { getHexHsl } from 'services/common/colorsHelper';

export const VIBO_THEME_NAME = 'vibo_theme';

export const VIBO_FOLLOW_SYS_THEME = 'vibo_follow_sys_theme';

export const DEFAULT_VIBO_COLOR_HEX = '#1A7EE3';

export const DEFAULT_VIBO_COLOR_DARK_HEX = '#2D8FF1';

export const isDark = () => localStorage.getItem(VIBO_THEME_NAME) === 'dark';

export const appColor = () => (isDark() ? appDarkColor() : appLightColor());
export const appLightColor = () => getCssColor('--light-color');
export const appDarkColor = () => getCssColor('--dark-color');

export const updateThemeCssColors = (light: string, dark: string) => {
  const lightHsl = getHexHsl(light);
  const darkHsl = getHexHsl(dark);

  setCssVar('--light-color', light);
  setCssVar('--dark-color', dark);
  setCssVar('--light-hue', `${lightHsl.h}`);
  setCssVar('--dark-hue', `${darkHsl.h}`);
};

export const getCssColor = (name: string) =>
  getComputedStyle(document.body)
    .getPropertyValue(name)
    .trim();
