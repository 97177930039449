import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  prepModePage: {
    '& .sectionBlocksHeader': {
      margin: '0 0 14px 0',

      '@media screen and (min-width: 1441px) ': {
        margin: '0 0 16px 0',
      },
    },

    '& .timelineSectionsHeader > div': {
      '&.searchBarWrapper': {
        minWidth: 'auto',
      },
    },
  },

  eventTitle: {
    margin: '0 0 14px 0',
  },

  selectComputerBtn: {
    '& .content': {
      ...mixins.longText,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '& .label': {
      color: 'var(--text-secondary)',
      margin: '0 4px 0 0',
    },

    '& .name': {
      ...mixins.longText,
    },
  },
}));

export default useStyles;
