import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  visibilityLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    height: '20px',
    padding: '0 6px 0 4.5px',
    fontWeight: 400,
    fontSize: '11px!important',
    lineHeight: '15.4px',

    '&.outline': {
      backgroundColor: 'var(--background-paper)',

      '&.dj-type': {
        border: `1px solid var(--text-secondary)`,
        color: 'var(--text-secondary)',

        '& svg': {
          color: 'var(--text-secondary)',
        },
      },

      '&.host-type': {
        border: `1px solid ${'var(--primary-main)'}`,
        color: 'var(--primary-main)',

        '& svg': {
          color: 'var(--primary-main)',
        },
      },

      '&.public-type': {
        border: `1px solid var(--success-main)`,
        color: 'var(--success-main)',

        '& svg': {
          color: 'var(--success-main)',
        },
      },
    },

    '&.fill': {
      '&, & svg': {
        color: 'var(--clr-white)',
      },

      '&.dj-type': {
        color: 'var(--whiteToBlack)',
        backgroundColor: 'var(--text-secondary)',
      },

      '&.host-type': {
        backgroundColor: 'var(--primary-main)',
      },

      '&.public-type': {
        backgroundColor: 'var(--success-main)',
      },
    },

    '& .viboIcon': {
      marginRight: '2px',
    },

    '@media screen and (min-width: 1441px)': {
      height: '24px',
      fontSize: '14px!important',
      lineHeight: '21px',
    },
  },
}));

export default useStyles;
