import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  profilesInner: {
    display: 'flex',
    flexDirection: 'column',

    '& .viboIcon': {
      color: 'var(--text-secondary)',
    },

    '& .text': {
      fontSize: '11px',
      lineHeight: '16.55px',
      color: 'var(--text-secondary)',

      '@media screen and (min-width: 1441px)': {
        fontSize: '14px',
        lineHeight: '21.07px',
      },
    },
  },

  addLinkTitle: {
    margin: 'auto 0 4px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  deleteIcon: {
    cursor: 'pointer',
    color: 'var(--primary-main)',
    margin: '0 0 0 auto!important',

    '& svg': {
      width: '16px!important',
      height: '16px!important',
    },
  },

  selectLinks: {
    display: ' flex',
    flexDirection: 'row',
    flexWrap: 'wrap',

    '& .linkToSelect': {
      '&:not(:last-child)': {
        margin: '0 12px 0 0',
      },
    },
  },

  selectedLink: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 12px 0',

    '& .deleteIcon': {
      display: 'none',
    },

    '&:hover': {
      '& .deleteIcon': {
        display: 'block',
      },
    },
  },

  selectedLinkInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 3px 0',

    '& .viboIcon': {
      margin: '0 8px 0 0',
    },

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 9px 0',
    },
  },

  linkToSelect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  linksContainer: {
    padding: '12px',
    height: '75px',

    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
}));

export default useStyles;
