import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  eventsLayout: {
    background: 'var(--background-default)',
    height: '100%',
  },

  container: {
    padding: '0 15px',
    height: '100%',

    '& > .containerContent': {
      height: '100%',
    },
  },
}));

export default useStyles;
