import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  contentArea: {
    backgroundColor: 'var(--background-paper)',
    borderRadius: '4px',

    '&.v2': {
      backgroundColor: 'var(--background-paper)',
    },
  },

  shadowed: {
    boxShadow: 'var(--shadows-5)',
  },
}));

export default useStyles;
