import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboSwitchLabel: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '19.56px',
    color: 'var(--text-primary)',

    '&.withLabel': {
      width: '100%',

      '& .viboSwitch': {
        margin: '0 14px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 16px 0 0',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  viboSwitch: {
    '&.rc-switch': {
      position: 'relative',
      borderRadius: '18px',
      height: '20px',
      width: '40px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'var(--text-hint)',

      '&.rc-switch-disabled': {
        opacity: 0.3,
      },

      '& .rc-switch-inner-unchecked': {
        left: '22px',

        '@media screen and (min-width: 1441px)': {
          left: '28px',
        },
      },

      '& .rc-switch-inner': {
        position: 'absolute',
        height: '100%',
        fontSize: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        left: 0,
        color: 'var(--clr-white)',
        lineHeight: '19px',

        '& .viboIcon': {
          color: 'var(--background-paper)',
          fontSize: '10px!important',
        },

        '@media screen and (min-width: 1441px)': {
          fontSize: '10px',
          lineHeight: '22px',
        },
      },

      '&:after': {
        top: '50%',
        transform: 'translateY(-50%)',
        left: '1px',
        width: '16px',
        height: '16px',
        backgroundColor: 'var(--background-paper)',

        '@media screen and (min-width: 1441px)': {
          width: '20px',
          height: '20px',
        },
      },

      '&.rc-switch-checked:after': {
        left: '21px',

        '@media screen and (min-width: 1441px)': {
          left: '25px',
        },
      },

      '&:before': {
        content: '""',
        position: 'absolute',
        left: '-1px',
        top: '-1px',
        boxShadow: 'var(--shadows-6)',
        width: 'calc(100% + 2px)',
        height: 'calc(100% + 2px)',
        borderRadius: '18px',
      },

      '& .viboSpinner': {
        position: 'absolute',
        top: '50%',
        transform: 'translate(1px, -50%)',
        minWidth: '15px',
        minHeight: '15px',
        zIndex: 1,
        transition: 'left .35s',
      },

      '&.loading': {
        '& .viboSpinner': {
          color: 'var(--text-hint)',
          left: '0',
          opacity: 1,

          '& .viboIcon': {
            fontSize: '11px!important',

            '@media screen and (min-width: 1441px)': {
              fontSize: '14px!important',
            },
          },

          '@media screen and (min-width: 1441px)': {
            left: '2px',
          },
        },
      },

      '&.rc-switch-checked': {
        border: `1px solid var(--primary-main)`,
        backgroundColor: 'var(--primary-main)',
        position: 'relative',

        '&.loading .viboSpinner': {
          color: 'var(--primary-main)',
          left: '20px',

          '@media screen and (min-width: 1441px)': {
            left: '26px',
          },
        },

        '&:hover': {
          backgroundColor: 'var(--button-active)',
        },
      },

      '&:hover': {
        '& .rc-switch-inner-checked .viboIcon': {
          color: 'var(--whiteToBalticSeaDark)',
        },

        '&:after': {
          backgroundColor: 'var(--whiteToBalticSeaDark)',
        },
      },

      '&::after, &:hover::after': {
        animation: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        width: '48px',
        height: '24px',
      },
    },
  },

  stateIcon: {
    fontSize: 'inherit',
  },
}));

export default useStyles;
