import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';

import { useMe } from 'graphql/hooks/user';
import { useViboIntercom } from 'components/context/IntercomContext';
import { getSelectedSupportMenuKeys } from 'services/common/routesHelper';
import { COMMUNITY_FACEBOOK_LINK, HELP_CENTER_LINK } from 'services/constants';

import { IconmoonFont } from 'vibo-ui/Icon';

import useStyles from './style';

const SupportMenu: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { push } = useHistory();

  const { isDj, isAdmin } = useMe();
  const { toggleShowChat } = useViboIntercom();

  const toggleOpenMenu = () => setIsOpen(!isOpen);

  const handleOpenHelpCenter = () => window.open(HELP_CENTER_LINK, '_blank', 'noreferrer');

  const handleOpenCommunity = () => window.open(COMMUNITY_FACEBOOK_LINK, '_blank', 'noreferrer');

  return (
    <>
      <Menu.Item
        onClick={toggleOpenMenu}
        icon={IconmoonFont['headphonesWithMic-24']}
        title={t('support')}
        key="/support"
      >
        <span>{t('support')}</span>
      </Menu.Item>
      <Menu
        className={classNames('supportMenu', classes.supportMenu, {
          isOpen,
        })}
        getSelectedSidebarMenuKeys={getSelectedSupportMenuKeys}
        styleModificator="siderLeft"
        selectedKeys={[]}
      >
        <Menu.Item
          onClick={handleOpenHelpCenter}
          icon={IconmoonFont['helpCenter-16']}
          title={t('helpCenter')}
          key="/helpCenter"
        >
          <span>{t('helpCenter')}</span>
        </Menu.Item>
        <Menu.Item
          onClick={handleOpenCommunity}
          icon={IconmoonFont['community-16']}
          title={t('community')}
          key="/community"
        >
          <span>{t('community')}</span>
        </Menu.Item>
        <Menu.Item
          onClick={toggleShowChat}
          icon={IconmoonFont['questionCircleOutlined-16']}
          title={t('contactUs')}
          key="/contactUs"
        >
          <span>{t('contactUs')}</span>
        </Menu.Item>
        {isDj || isAdmin ? (
          <Menu.Item
            onClick={() => push('/marketing-assets')}
            icon={IconmoonFont['crown-16']}
            title={t('marketing')}
            key="/marketing-assets"
          >
            <span>{t('marketing')}</span>
          </Menu.Item>
        ) : null}
      </Menu>
    </>
  );
};

export default SupportMenu;
