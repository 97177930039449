import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  fileDetails: {
    background: 'var(--background-paper)',
    width: '100%',
    borderRadius: 'inherit',
  },

  detail: {
    display: 'flex',

    '& label': {
      color: 'var(--button-active)',
      fontSize: '14px',
    },

    '& span': {
      wordBreak: 'break-all',
      color: 'var(--text-primary)',
      fontSize: '14px',
      paddingLeft: '5px',
    },
  },
}));

export default useStyles;
