import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import Tooltip from 'vibo-ui/Tooltip';

import { NOTIF_ICON_BY_ENUM } from '../constants';

import { NotificationStatus } from 'types/enums';
import { NotifStatusProps } from '../interfaces';

import useStyles from '../style';

const NotifStatus: FC<NotifStatusProps> = ({ status, disabled }) => {
  const classes = useStyles();

  const text = useMemo(
    () => ({
      [NotificationStatus.cannotBeSend]:
        'Please check the date and notification timing: Notification cannot be sent.',
      [NotificationStatus.pending]: 'Notification pending to be sent',
      [NotificationStatus.sent]: 'Notification successfully sent',
    }),
    []
  );

  const showDisabledInfo = useMemo(() => disabled && status !== NotificationStatus.sent, [
    disabled,
    status,
  ]);

  return (
    <Tooltip overlay={showDisabledInfo ? 'Notification disabled' : text[status]} type="black">
      <Icon
        icon={
          showDisabledInfo ? IconmoonFont['closeCircleFilledLg-16'] : NOTIF_ICON_BY_ENUM[status]
        }
        className={classNames('notifStatus', classes.notifStatus, status, {
          disabled: showDisabledInfo,
        })}
      />
    </Tooltip>
  );
};

export default NotifStatus;
