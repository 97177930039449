import { gql } from '@apollo/client';

export const MARK_AS_READ = gql`
  mutation markAsRead($notificationIds: [ID!], $readAll: Boolean) {
    markAsRead(notificationIds: $notificationIds, readAll: $readAll)
  }
`;

export const CREATE_EVENT_CUSTOM_NOTIFICATION = gql`
  mutation createEventCustomNotification($eventId: ID!, $payload: CreateCustomNotificationInput!) {
    createEventCustomNotification(eventId: $eventId, payload: $payload) {
      _id
    }
  }
`;

export const CREATE_TEMPLATE_CUSTOM_NOTIFICATION = gql`
  mutation createTemplateCustomNotification(
    $templateId: ID!
    $payload: CreateCustomNotificationInput!
  ) {
    createTemplateCustomNotification(templateId: $templateId, payload: $payload) {
      _id
    }
  }
`;

export const UPDATE_EVENT_CUSTOM_NOTIFICATION = gql`
  mutation updateEventCustomNotification(
    $eventId: ID!
    $customNotificationId: ID!
    $payload: UpdateCustomNotificationInput!
  ) {
    updateEventCustomNotification(
      eventId: $eventId
      customNotificationId: $customNotificationId
      payload: $payload
    ) {
      _id
      title
      input
      sendingTimeSettings {
        number
        timeUnit
        temporalOrder
        eventStatusDate
      }
      addresses
      disabled
      status
    }
  }
`;

export const UPDATE_TEMPLATE_CUSTOM_NOTIFICATION = gql`
  mutation updateTemplateCustomNotification(
    $templateId: ID!
    $customNotificationId: ID!
    $payload: UpdateCustomNotificationInput!
  ) {
    updateTemplateCustomNotification(
      templateId: $templateId
      customNotificationId: $customNotificationId
      payload: $payload
    ) {
      _id
      title
      input
      sendingTimeSettings {
        number
        timeUnit
        temporalOrder
        eventStatusDate
      }
      addresses
      disabled
    }
  }
`;

export const REMOVE_EVENT_CUSTOM_NOTIFICATION = gql`
  mutation removeEventCustomNotification($eventId: ID!, $customNotificationId: ID!) {
    removeEventCustomNotification(eventId: $eventId, customNotificationId: $customNotificationId)
  }
`;

export const REMOVE_TEMPLATE_CUSTOM_NOTIFICATION = gql`
  mutation removeTemplateCustomNotification($templateId: ID!, $customNotificationId: ID!) {
    removeTemplateCustomNotification(
      templateId: $templateId
      customNotificationId: $customNotificationId
    )
  }
`;

export const REORDER_EVENT_CUSTOM_NOTIFICATIONS = gql`
  mutation reorderEventCustomNotifications(
    $eventId: ID!
    $sourceCustomNotificationId: ID!
    $targetCustomNotificationId: ID!
  ) {
    reorderEventCustomNotifications(
      eventId: $eventId
      sourceCustomNotificationId: $sourceCustomNotificationId
      targetCustomNotificationId: $targetCustomNotificationId
    )
  }
`;

export const REORDER_TEMPLATE_CUSTOM_NOTIFICATIONS = gql`
  mutation reorderTemplateCustomNotifications(
    $templateId: ID!
    $sourceCustomNotificationId: ID!
    $targetCustomNotificationId: ID!
  ) {
    reorderTemplateCustomNotifications(
      templateId: $templateId
      sourceCustomNotificationId: $sourceCustomNotificationId
      targetCustomNotificationId: $targetCustomNotificationId
    )
  }
`;

export const SEND_NOW = gql`
  mutation sendNow($eventId: ID!, $customNotificationId: ID!) {
    sendNow(eventId: $eventId, customNotificationId: $customNotificationId) {
      _id
      sentAt
      status
    }
  }
`;
