import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songItemActionsGroup: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  kebabWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    margin: '0 4px',
    color: 'var(--primary-main)',

    '& > .viboIcon': {
      transform: 'rotate(90deg)',
    },
  },

  kebabCircle: {
    color: 'var(--primary-contrastText)',
    borderRadius: '50%',
    background: 'var(--primary-main)',
  },

  like: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > svg': {
      transform: 'translateY(-6px)',
      width: '33.1px',
      height: '36px',
      marginRight: '2px',
    },

    '& > .count': {
      minWidth: 'auto',
    },
  },

  creatorImage: {
    margin: '0 4px',
    width: '24px!important',
    height: '24px!important',
  },
}));

export default useStyles;
