import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  headlineLabel: {
    height: '20px',
    backgroundColor: 'var(--background-paper)',

    '@media screen and (min-width: 1441px)': {
      height: '24px',
    },
  },
}));

export default useStyles;
