import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  iconButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    //for gtm tracking propper target
    '& > svg': {
      ...mixins.getStaticIconSize?.(14),
      pointerEvents: 'none',

      '@media screen and (min-width: 1441px)': {
        ...mixins.getStaticIconSize?.(16),
      },
    },
  },

  iconButtonTooltip: {
    pointerEvents: 'none',
  },

  disabledIcon: {
    color: 'var(--action-hover)',
    cursor: 'not-allowed !important',

    '&:hover': {
      color: 'var(--action-hover)',
    },
  },

  tooltipOverlay: {
    pointerEvents: 'none',
  },
}));

export default useStyles;
