import { LOOM_LINK_REGEX, VIMEO_LINK_REGEX, YOUTUBE_LINK_REGEX } from 'services/constants';

export const getYouTubeIdFromWatchUrl = (youtubeUrl: string): string => {
  const match = youtubeUrl.match(YOUTUBE_LINK_REGEX);

  return match?.[1] || '';
};

export const getLoomIdFromWatchUrl = (loomWatchUrl: string): string => {
  const match = loomWatchUrl.match(LOOM_LINK_REGEX);

  return match?.[1] || '';
};

export const getVimeoIdFromWatchUrl = (vimeoWatchUrl: string): string => {
  const match = vimeoWatchUrl.match(VIMEO_LINK_REGEX);

  return match?.[1] || '';
};

export const isLoomLink = (link?: string) => {
  return !!link ? link.includes('www.loom.com') : false;
};

export const isVimdeoLink = (link?: string) => {
  return !!link ? link.includes('vimeo.com') : false;
};

export const parsePastedVideoLink = (link: string) => {
  return isLoomLink(link) ? link.replace('share', 'embed') : link;
};

export const isYoutubeLink = (link?: string): boolean => {
  return !!link ? /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\//.test(link) : false;
};

export const getVideoIdByLink = (link?: string) => {
  if (!!link) {
    return !!link
      ? isLoomLink(link)
        ? getLoomIdFromWatchUrl(link)
        : isVimdeoLink(link)
        ? getVimeoIdFromWatchUrl(link)
        : getYouTubeIdFromWatchUrl(link)
      : undefined;
  }

  return undefined;
};
