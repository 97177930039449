import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  inputLabel: {
    color: 'var(--text-secondary)',

    '& .text': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '4px',
      fontSize: '13px',
      lineHeight: '19.56px',

      '& .viboIcon': {
        color: 'var(--text-secondary)',
        margin: '0 8px 0 0',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  disabled: {
    color: 'var(--text-disabled)',
  },

  req: {
    color: 'var(--primary-main)',
  },
}));

export default useStyles;
