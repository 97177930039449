import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  questions: {
    '&.isAnswering': {
      ...mixins.noClickableFilter,
    },

    '&.withMinHeight': {
      minHeight: '80px',

      '@media screen and (min-width: 1500px)': {
        minHeight: '102px',
      },
    },

    '& .draggableItemClassName, .question': {
      '&.smooth-dnd-draggable-wrapper': {
        overflow: 'visible',
      },
    },

    '& .smooth-dnd-container .draggableItemClassName': {
      position: 'relative',

      '&:first-child': {
        '&, & > .question': {
          borderRadius: '4px 4px 0 0',
        },
      },

      '&:last-child': {
        '&, & > .question': {
          borderRadius: '0 0 4px 4px',
        },
      },
    },
  },

  dropQuestionPlaceholder: {
    border: `2px dashed var(--primary-main)`,
    borderRadius: '4px',
  },

  dragQuestion: {
    '& .content > *': {
      '&:not(.questionHeader)': {
        display: 'none',
      },
    },

    '& .questionHeader': {
      margin: 0,
    },
  },

  hideDndIcon: {
    '& .dndIcon': {
      display: 'none!important',
    },
  },
}));

export default useStyles;
