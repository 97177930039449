import React, { FC, useCallback } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { useCopyToClipboard } from 'react-use';
import { useTranslation } from 'react-i18next';
import { toastNotify } from 'graphql/hooks/common';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown/Dropdown';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { updatePlaylistPublic } from 'graphql/cache/songIdeas';

import { GET_SONG_IDEAS_DEEP_LINK } from 'graphql/queries/songIdeas';
import { TOGGLE_PUBLIC_SONG_IDEAS } from 'graphql/mutations/songIdeas';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { ShareModalProps } from 'components/modals/ShareModal';
import { SharePlaylistButtonProps } from './interfaces';

const SharePlaylistButton: FC<SharePlaylistButtonProps> = ({ playlist }) => {
  const { t } = useTranslation();

  const [, copyToClipboard] = useCopyToClipboard();

  const { openModal } = useModal();

  const openShareModal = useCallback(
    () =>
      openModal<ShareModalProps>({
        key: Modals.share,
        props: {
          mutation: TOGGLE_PUBLIC_SONG_IDEAS,
          variables: {
            songIdeasId: playlist._id,
          },
          onCompleted: () => {
            if (!!playlist?._id) {
              updatePlaylistPublic(playlist);
            }
          },
          option: playlist,
          title: t(playlist?.isPublic ? 'makePlaylistPrivate' : 'makePlaylistPublic'),
          children: t(
            playlist?.isPublic ? 'makeSongIdeasPrivateModalText' : 'makeSongIdeasPublicModalText'
          ),
        },
      }),
    [playlist._id, playlist?.isPublic]
  );

  const playlistDeepLinkData = useQuery(GET_SONG_IDEAS_DEEP_LINK, {
    variables: {
      songIdeasId: playlist._id,
    },
    onError,
  });

  const playlistDeepLink = get(playlistDeepLinkData.data, 'getSongIdeasDeepLink');

  const handleGetPlaylistDeepLink = useCallback(() => {
    copyToClipboard(playlistDeepLink);
    toastNotify({
      text: t('copyPlaylistDeeplinkSuccess'),
    });
  }, [playlistDeepLink]);

  return (
    <Dropdown
      overlay={
        <Menu selectable={false}>
          {!!playlist?.deepLink ? (
            <Menu.Item
              onClick={handleGetPlaylistDeepLink}
              icon={IconmoonFont['link-16']}
              key={`copyLink`}
            >
              {t('copyLink')}
            </Menu.Item>
          ) : null}
          <Menu.Item onClick={openShareModal} icon={IconmoonFont['public-16']} key={`makePublic`}>
            {t(playlist?.isPublic ? 'makePrivate' : 'makePublic')}
          </Menu.Item>
        </Menu>
      }
      trigger="click"
      disableToggleClass
    >
      <Button prefixIcon={IconmoonFont['share-arrow-16']} shape="round" size="lg">
        {t('sharePlaylist')}
      </Button>
    </Dropdown>
  );
};

export default SharePlaylistButton;
