import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  maxCharsLimit: {
    opacity: '0',
    textAlign: 'right',
    width: '100%',
    color: 'var(--primary-main)',
    fontSize: '11px',
    lineHeight: '16.55px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '12px',
      lineHeight: '19.84px',
    },
  },
}));

export default useStyles;
