import React, { FC, useContext, useCallback } from 'react';

import Button from 'vibo-ui/Button';
import WithEventRole from 'components/user/WithEventRole';
import { EventContext } from 'components/context/EventContext';
import { EditableContext } from 'components/common/EditableContext';
import { TimelineQuestionsContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';

import { EventUserType, Modals } from 'types/enums';
import { CreateQuestionModalProps } from 'components/modals/CreateQuestionModal';
import { AddTimelineSectionButtonProps } from './interfaces';

const AddTimelineQuestionButton: FC<AddTimelineSectionButtonProps> = ({
  questionIdAfter,
  children,
  onClick,
  ...rest
}) => {
  const eventContext = useContext(EventContext);
  const timelineQuestionsContext = useContext(TimelineQuestionsContext);
  const { canEdit } = useContext(EditableContext);

  const { openModal } = useModal();

  const openCreateQuestionModal = useCallback(
    () =>
      openModal<CreateQuestionModalProps>({
        key: Modals.createQuestion,
        props: {
          timelineQuestionsContext,
          questionIdAfter,
          eventContext,
        },
      }),
    [timelineQuestionsContext, questionIdAfter, eventContext]
  );

  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      openCreateQuestionModal();
    },
    [questionIdAfter]
  );

  return (
    <WithEventRole roles={[EventUserType.dj]} extraCondition={canEdit}>
      <Button onClick={handleClick} {...rest}>
        {children}
      </Button>
    </WithEventRole>
  );
};

export default AddTimelineQuestionButton;
