import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Linkify from 'components/common/Linkify';
import CopyQuestionAnswer from 'components/questions/CopyQuestionAnswer';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import {
  QuestionActionsContext,
  QuestionContext,
} from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { QuestionLinksModalProps } from 'components/modals/QuestionLinksModal';

const LinkAnswer: FC = () => {
  const { t } = useTranslation();

  const { question, canAnswer } = useContext(QuestionContext);
  const { answerQuestion } = useContext(QuestionActionsContext);

  const { openModal } = useModal();

  const openQuestionLinksModal = () =>
    openModal<QuestionLinksModalProps>({
      key: Modals.questionLinks,
      props: {
        question: question,
        answerQuestion,
      },
    });

  const withAnswer = useMemo(() => !!question.answer?.link?.length, [
    question.answer?.link?.length,
  ]);

  return (
    <div
      className={classNames('linkAnswer', {
        withAnswer,
      })}
    >
      {withAnswer ? (
        <div className="rowWithIconGroup">
          {question.answer?.link.map((link, idx) => {
            return (
              <div className="rowWithIcon" key={`timline-question-answer-link-${idx}-${link}`}>
                <Icon icon={IconmoonFont['link-16']} />
                <Linkify>{link}</Linkify>
                <CopyQuestionAnswer value={link} />
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="answerButtons">
        <Button
          onClick={openQuestionLinksModal}
          disabled={!canAnswer}
          prefixIcon={IconmoonFont[withAnswer ? 'editPencil-16' : 'plus-16']}
          displayType="bordered"
          shape="round"
          size="lg"
        >
          {t(withAnswer ? 'editLinks' : 'addLinks')}
        </Button>
      </div>
    </div>
  );
};

export default LinkAnswer;
