import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button, { ButtonDecor } from 'vibo-ui/Button';

import { ConfirmActionButtonProps } from './interfaces';

import useStyles from './style';

const ConfirmActionButton: FC<ConfirmActionButtonProps> = ({
  action,
  children,
  className,
  confirmText,
  previewBtnProps,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isPreview, setIsPreview] = useState<boolean>(false);

  const togglePreview = () => setIsPreview(!isPreview);

  useEffect(() => {
    let previewTimer = null;

    if (isPreview) {
      previewTimer = setTimeout(() => {
        togglePreview();
      }, 2000);
    }

    return () => {
      !!previewTimer && clearTimeout(previewTimer);
    };
  }, [isPreview]);

  return (
    <div
      onClick={togglePreview}
      className={classNames('confirmActionButton', classes.confirmActionButton, className)}
      {...rest}
    >
      {isPreview ? (
        <Button
          onClick={action}
          displayType="primary"
          {...previewBtnProps}
          className={classNames(previewBtnProps?.className, ButtonDecor.success)}
        >
          {confirmText || t('clickComfirm')}
        </Button>
      ) : (
        children
      )}
    </div>
  );
};

export default ConfirmActionButton;
