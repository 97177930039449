import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  flag: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  unflagged: {
    color: 'var(--text-disabled)',
  },

  flagged: {
    color: 'var(--error-main)',
  },
}));

export default useStyles;
