export interface GetNotificationsResponse {
  getNotifications: {
    notifications: NotificationProps[];
    next: Nullable<Cursor>;
    totalCount: number;
  };
}

export interface GetNotificationsCountResponse {
  getNotificationsCount: {
    total: number;
    __typename?: string;
  };
}

export interface NotificationIconProps {
  notificationType: NotificationType;
}

export interface NotificationProps {
  _id: string;
  imageUrl: string;
  header: string;
  body: string;
  isRead: boolean;
  metadata: NotificationMetadata;
  notificationType: NotificationType;
  createdAt: string;
}

export enum NotificationType {
  'hostGuestJoinedEvent' = 'hostGuestJoinedEvent',
  'hostGuestLeftEvent' = 'hostGuestLeftEvent',
  'lockoutDate' = 'lockoutDate',
  'eventLocked' = 'eventLocked',
  'accountStatusChangedToLimited' = 'accountStatusChangedToLimited',
  'accountStatusChangedToChargeFailed' = 'accountStatusChangedToChargeFailed',
  'accountStatusChangedToActivated' = 'accountStatusChangedToActivated',
  'customNotification' = 'customNotification',
  'questionAnswered' = 'questionAnswered',
}

export type NotificationMetadata = {
  eventId: string;
  sectionId?: string;
  questionId?: string;
};
