import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  phonePreviewInput: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',

    '& .form-control': {
      padding: '0 10px',
      maxWidth: '200px',
      outline: 'none',
      height: '30px',
      backgroundColor: 'var(--background-default)',
      border: `1px solid var(--primary-light)`,

      '&:focus': {
        boxShadow: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        height: '36px',
      },
    },

    '& .flag-dropdown': {
      position: 'relative',

      '& .selected-flag': {
        padding: 0,
        width: '45px',

        '&:before': {
          display: 'none',
        },
      },

      '& .arrow': {
        borderTop: `4px solid var(--primary-main)`,

        '&.up': {
          borderBottom: `4px solid var(--primary-main)`,
        },
      },
    },

    '& .country-list': {
      bottom: 0,
      margin: 0,
      transform: 'translate(0px, 50px)',
      maxHeight: '126px',

      '& .search-box': {
        margin: 0,
      },

      '& .country': {
        padding: '6px 9px 6px 46px',

        '& .flag': {
          top: '4px',
          margin: 0,
          transform: 'scale(0.8)',
        },
      },
    },
  },
}));

export default useStyles;
