import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  videoPreview: {
    cursor: 'pointer',
    position: 'relative',

    '& .noPreviewImgText': {
      width: '80%',
      color: 'var(--clr-white)',
      position: 'absolute',
      bottom: '20%',
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      fontSize: '15px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '18px',
      },
    },

    '& > .viboIconSVG, & > .viboImage .viboIconSVG': {
      pointerEvents: 'none',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      width: '34px',

      '@media screen and (min-width: 1441px)': {
        width: '48px',
      },
    },

    '& > .viboImage': {
      borderRadius: '4px',
      backgroundColor: 'var(--dustyGrayToEmperor)',

      '&.isLoadError': {
        '& .gradient': {
          backgroundImage: 'none',
        },
      },

      '& img': {
        objectFit: 'cover',
        width: '100%',
      },
    },

    '& > div': {
      height: '100%',
    },

    '& iframe': {
      height: '100%',
      width: '100%',
      border: 'none',
    },
  },

  whiteIcon: {
    '&.viboIconSVG path': {
      fill: 'var(--clr-white)',
    },
  },
}));

export default useStyles;
