import React, { FC, useEffect, useMemo, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { useQuery, useMutation } from '@apollo/client';

import Spinner from 'vibo-ui/Spinner';

import { useMusicKit } from 'graphql/hooks/musickit';
import { removeMusicKitToken } from 'services/appleMusic/helpers';
import { VIBO_REFRESH_TOKEN_KEY, VIBO_TOKEN_KEY } from 'utils/constants';
import { GET_APPLE_MUSIC_DEVELOPER_TOKEN, CONNECT_APPLE_MUSIC } from 'graphql/mutations/appleMusic';

import useStyles from './style';

const AppleMusicConnect: FC = () => {
  const classes = useStyles();
  const [token = ''] = useQueryParam<string>('token');
  const [refreshToken = ''] = useQueryParam<string>('refreshToken');
  const [musicToken, setMusicToken] = useState<Nullable<string>>(null);

  const authContext = useMemo(
    () => ({
      headers: {
        [VIBO_TOKEN_KEY]: token,
        [VIBO_REFRESH_TOKEN_KEY]: refreshToken,
      },
    }),
    [token, refreshToken]
  );

  const { data } = useQuery<DeveloperTokenResponse>(GET_APPLE_MUSIC_DEVELOPER_TOKEN, {
    context: authContext,
    fetchPolicy: 'network-only',
    onError: () => {
      return window.location.replace(`${process.env.REACT_APP_APPLE_REDIRECT_URL}?success=false`);
    },
  });
  const developerToken: Maybe<string> = data?.getAppleMusicDeveloperToken.developerToken;

  const [connectAppleMusic] = useMutation<ConnectAppleMusicResponse, ConnectAppleMusicVariables>(
    CONNECT_APPLE_MUSIC,
    {
      onCompleted: response => {
        removeMusicKitToken();
        window.location.replace(
          `${process.env.REACT_APP_APPLE_REDIRECT_URL}?success=${response.connectAppleMusic}`
        );
      },
      onError: () => {
        disconnect();
        removeMusicKitToken();
        window.location.replace(`${process.env.REACT_APP_APPLE_REDIRECT_URL}?success=false`);
      },
    }
  );

  const { connect, disconnect, loaded } = useMusicKit({
    developerToken,
    onMusicToken: token => {
      setMusicToken(token);
    },
  });

  useEffect(() => {
    if (developerToken && loaded) {
      connect();
    }
  }, [developerToken, loaded]);

  useEffect(() => {
    if (musicToken) {
      connectAppleMusic({
        variables: {
          appleMusicAuth: {
            developerToken: developerToken!,
            musicUserToken: musicToken,
          },
        },
        context: authContext,
      });
    }
  }, [musicToken]);

  return (
    <div className={classes.appleMusicConnect}>
      <Spinner className="withSpace" />
    </div>
  );
};

export default AppleMusicConnect;
