import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  quillEditor: {
    color: 'var(--text-primary)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '& .quill': {
      height: '100%',
    },

    '& .quillToolbar': {
      display: 'none',
    },

    '& .ql-editor': {
      paddingBottom: '40px',

      '& h1, h2, h3, h4, h5, h6': {
        fontWeight: '400',
      },

      '& a': {
        color: 'var(--primary-main)',
      },

      '&.ql-blank::before': {
        fontStyle: 'normal',
        color: 'var(--text-hint)',
        left: '12px',
      },
    },

    '& *': {
      fontFamily: 'Roboto',
    },

    '& .ql-container.ql-snow': {
      border: 'none',
      height: '100%',
    },

    '& .ql-tooltip': {
      backgroundColor: 'var(--background-paper)',
      boxShadow: 'var(--shadows-3)',
      borderRadius: '4px',
      zIndex: 101,
      color: 'var(--text-primary)',

      '&.ql-flip': {
        transform: 'none',
      },

      '& .ql-action, & .ql-remove, & .ql-preview': {
        color: 'var(--primary-main)',
      },
    },

    '& .ql-toolbar': {
      border: 'none',
      backgroundColor: 'var(--background-default)',
      padding: '10px 25px',
      width: '100%',
      margin: '0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 100,
      position: 'sticky',
      bottom: '0px',

      '&:after': {
        display: 'none',
      },

      '& button': {
        padding: '0 5px',
        color: 'var(--text-secondary)',
        width: 'auto',
        height: '16px',

        '& .ql-stroke': {
          stroke: 'var(--text-secondary)',
        },

        '&:hover': {
          color: 'var(--primary-main)',

          '& .ql-stroke': {
            stroke: 'var(--primary-main)',
          },
        },
      },

      '& .ql-active': {
        color: `var(--primary-main)!important`,

        '& .ql-stroke': {
          stroke: `var(--primary-main)!important`,
        },
      },

      '& .ql-divider': {
        margin: '0 5px',
        borderRadius: '2px',
        padding: '0',
        width: '1px',
        backgroundColor: 'var(--primary-light)',
      },
    },
  },
}));

export default useStyles;
