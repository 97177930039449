import React, { FC, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Notes from 'components/common/Notes';

import NotesPreview from './NotesPreview';
import { EditingNotesContext, TimelineNotesContext } from './TimelineNotesContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';

import useStyles from './style';

const SectionNotes: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const editingContext = useContext(EditingNotesContext);
  const { updateSection, section, variables } = useContext(TimelineNotesContext);

  const isNoteChanged = section.note !== editingContext.notesText;

  const { openModal } = useModal();

  const openConfirmSavingModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('saveNotes'),
          children: t('notesChangesHaventBeenSaved'),
          submit: {
            text: t('saveChanges'),
            onClick: () => {
              updateSection({
                variables: {
                  ...variables,
                  payload: {
                    note: editingContext?.notesText,
                  },
                },
                update: discardChanges,
              });
            },
          },
          discard: {
            text: t('dontSave'),
            onClick: discardChanges,
          },
        },
      }),
    [variables, editingContext?.notesText]
  );

  const cancelEditing = useCallback(() => editingContext?.setIsEditing(false), []);

  const discardChanges = useCallback(() => {
    cancelEditing();
  }, []);

  return (
    <>
      {editingContext?.isEditing ? (
        <div
          onClick={isNoteChanged ? openConfirmSavingModal : cancelEditing}
          className={classes.savingOverlay}
        />
      ) : null}
      {editingContext?.isEditing ? (
        <Notes
          onChange={editingContext.setNotesText}
          className={classNames({
            [classes.invisible]: !editingContext?.isEditing,
          })}
          initialValue={section?.note || ''}
          autoFocus={editingContext?.isEditing}
          placeholder={`${t('addNotesAbout')} ${section.name}...`}
        />
      ) : (
        <NotesPreview />
      )}
    </>
  );
};

export default SectionNotes;
