import React, {
  FC,
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';

import { useMe } from 'graphql/hooks/user';
import {
  VIBO_THEME_NAME,
  DEFAULT_VIBO_COLOR_HEX,
  DEFAULT_VIBO_COLOR_DARK_HEX,
  VIBO_FOLLOW_SYS_THEME,
  isDark,
  updateThemeCssColors,
} from './constants';

import { ModeColorsHex, TViboThemeContext, ViboThemeEnum } from './interfaces';

export const ViboThemeContext = createContext<TViboThemeContext>(undefined!);

export const useViboTheme = () => useContext(ViboThemeContext);

export const ViboThemeProvider: FC = ({ children }) => {
  const localTheme = localStorage.getItem(VIBO_THEME_NAME) as ViboThemeEnum;
  const localFollowSysTheme = localStorage.getItem(VIBO_FOLLOW_SYS_THEME) as string;

  const { user } = useMe();

  const customAppLight = useMemo(() => user?.applicationColors?.customLightModeApplicationColor, [
    user?.applicationColors?.customLightModeApplicationColor,
  ]);
  const customAppDark = useMemo(() => user?.applicationColors?.customDarkModeApplicationColor, [
    user?.applicationColors?.customDarkModeApplicationColor,
  ]);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [isSysThemeUsing, setIsSysThemeUsing] = useState<boolean>(!!localFollowSysTheme || false);

  const setTheme = (newTheme: ViboThemeEnum) => {
    document.body?.setAttribute('theme', newTheme);

    localStorage.setItem(VIBO_THEME_NAME, `${newTheme}`);
  };

  const setModeColorsHex = ({ light, dark }: ModeColorsHex) => {
    if (!!light && !!dark) {
      updateThemeCssColors(light, dark);
    }
  };

  const resetToAppColor = useCallback(() => {
    updateThemeCssColors(
      customAppLight || DEFAULT_VIBO_COLOR_HEX,
      customAppDark || DEFAULT_VIBO_COLOR_DARK_HEX
    );
  }, [customAppLight, customAppDark]);

  const handleSyncSystemTheme = useCallback(
    ({ matches }: MediaQueryListEvent) => {
      isSysThemeUsing && setTheme(!!matches ? ViboThemeEnum.dark : ViboThemeEnum.light);
    },
    [isSysThemeUsing]
  );

  const toggleTheme = () => {
    setTheme(isDark() ? ViboThemeEnum.light : ViboThemeEnum.dark);
  };

  useEffect(() => {
    if (isSysThemeUsing) {
      localStorage.setItem(VIBO_FOLLOW_SYS_THEME, 'true');
    } else {
      localStorage.removeItem(VIBO_FOLLOW_SYS_THEME);
    }

    if (isSysThemeUsing) {
      setTheme(
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
          ? ViboThemeEnum.dark
          : ViboThemeEnum.light
      );
    }
  }, [isSysThemeUsing]);

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleSyncSystemTheme);

    document.body.setAttribute('theme', localTheme || ViboThemeEnum.light);

    setIsReady(true);
    resetToAppColor();

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleSyncSystemTheme);
    };
  }, []);

  return (
    <ViboThemeContext.Provider
      value={{
        isSysThemeUsing,
        isReady,
        toggleTheme,
        setTheme,
        setModeColorsHex,
        resetToAppColor,
        setIsSysThemeUsing,
      }}
    >
      {children}
    </ViboThemeContext.Provider>
  );
};
