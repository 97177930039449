import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    fontSize: '13px',
    lineHeight: '18.2px',
    color: 'var(--text-secondary)',
    margin: '0 0 16px 0',

    '& .dndIcon': {
      display: 'none',
      transform: 'translate(-100%, 50%)',
      position: 'absolute',
      top: '0px',
      left: 0,
      cursor: 'move',
      color: 'var(--primary-main)',
      fontSize: '14px',
      zIndex: 101,
      paddingRight: '4px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '14px',
      },
    },

    '& .questionIcon': {
      margin: '2px 6px 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '2px 8px 0 0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '14px',
      margin: '0 0 20px 0',
    },
  },

  text: {
    lineHeight: '18px',
    fontSize: '16px',
    fontWeight: 500,
    color: 'var(--text-primary)',
  },

  questionTitleInput: {
    backgroundColor: 'transparent',
    outline: 'none !important',
    boxShadow: 'var(--shadows-0)',
    color: 'var(--text-primary)',
    resize: 'none',
    padding: 0,
    paddingBottom: '2px',
    minHeight: '0px',
    minWidth: '15ch',
    fontWeight: 500,
    fontSize: '13px!important',
    lineHeight: '17.7px!important',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px!important',
      lineHeight: '21px!important',
    },

    '&:focus,&:focus:hover': {
      outline: 'none !important',
      resize: 'none',
    },

    '&:hover': {
      outline: 'none !important',
      resize: 'none',
    },
  },

  questionTitle: {
    width: '100%',
    wordBreak: 'break-word',
    marginRight: 'auto',
    minHeight: '24px',
    whiteSpace: 'break-spaces',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    zIndex: 100,

    '& textarea': {
      padding: '0!important',
    },

    '& textarea, & span:not(.copyButton)': {
      borderRadius: 'unset',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '18.2px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '21px',
      },
    },
  },

  questionTitleLimited: {
    maxWidth: '60%',

    '@media screen and (min-width: 1080px)': {
      maxWidth: '67%',
    },

    '@media screen and (min-width: 1242px)': {
      maxWidth: '71%',
    },
  },
}));

export default useStyles;
