import { createUseStyles } from 'react-jss';

import { inputsBySize } from 'resources/styles/inputs/style';

const useStyles = createUseStyles(() => ({
  textPreview: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...inputsBySize,

    '&.empty': {
      color: 'var(--text-hint)',
    },
  },
}));

export default useStyles;
