import React, { FC, useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';

import Switch from 'vibo-ui/Switch';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { EditableContext } from 'components/common/EditableContext';
import { NotificationsContext } from 'components/context/NotificationsContext';

import { DisabledNotificationsForm } from 'components/context/NotificationsContext/interfaces';
import { GeneralNotifRowProps } from './interfaces';

const GeneralNotifRow: FC<GeneralNotifRowProps> = ({
  icon,
  name,
  recipients,
  notifType,
  renderSwitch,
}) => {
  const { updateEventNotification } = useContext(NotificationsContext);
  const { canEdit } = useContext(EditableContext);

  const { values } = useFormikContext<DisabledNotificationsForm>();

  const isOn = useMemo(
    () => !!notifType && !values.disabledNotifications?.includes(notifType),

    [values.disabledNotifications, notifType]
  );

  return (
    <div className="row">
      <Icon icon={icon} />
      <span className="name">{name}</span>
      {!!recipients ? (
        <span className="recipients">
          <Icon icon={IconmoonFont['userV2-16']} />
          {recipients}
        </span>
      ) : null}
      {!!notifType ? (
        <Switch
          checked={isOn}
          onClick={() => updateEventNotification(notifType)}
          disabled={!canEdit || values.disableAllPushNotifications}
        />
      ) : (
        renderSwitch
      )}
    </div>
  );
};

export default GeneralNotifRow;
