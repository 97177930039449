import React, { FC, useContext } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Switch from 'vibo-ui/Switch';
import GeneralNotifRow from './GeneralNotifRow';
import InfoIcon from 'components/common/InfoIcon';
import ContentArea from 'components/common/ContentArea';
import { EditableContext } from 'components/common/EditableContext';

import { IconmoonFont } from 'vibo-ui/Icon';
import { DisabledNotificationsForm } from 'components/context/NotificationsContext/interfaces';

const MainNotifSettings: FC = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<DisabledNotificationsForm>();

  const { canEdit } = useContext(EditableContext);

  return (
    <ContentArea shadowed>
      <GeneralNotifRow
        icon={IconmoonFont['notifications-bell-24']}
        name={
          <>
            {`${t('notifications')} ${t(values?.disableAllPushNotifications ? 'off' : 'on')}`}
            <InfoIcon text={t('eventNotificationsInfo')} />
          </>
        }
        renderSwitch={
          <Switch
            defaultChecked={!values?.disableAllPushNotifications}
            onClick={newChecked => {
              setFieldValue('disableAllPushNotifications', !newChecked);
            }}
            disabled={!canEdit}
          />
        }
      />
    </ContentArea>
  );
};

export default MainNotifSettings;
