import React, { FC, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from 'vibo-ui/Button';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { appLightColor } from 'components/context/ViboThemeContext/constants';

import { UPDATE_UPLICATION_COLOR } from 'graphql/mutations/user';

import { Modals } from 'types/enums';
import { PickBrandingColorModalProps } from 'components/modals/PickBrandingColorModal/interfaces';

const PickBrandingColorButton: FC<ButtonProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  const { openedModals, openModal } = useModal();

  const [updateApplicationColor] = useMutation(UPDATE_UPLICATION_COLOR, {
    refetchQueries: ['getMe'],
    onError,
  });

  const openPickBrandingColorModal = useCallback(
    () =>
      openModal<PickBrandingColorModalProps>({
        key: Modals.pickBrandingColor,
        props: {
          defaultColor: appLightColor(),
          handleUpdateColor: applicationColor => {
            updateApplicationColor({
              variables: {
                payload: {
                  applicationColor,
                },
              },
            });
          },
        },
      }),
    [openedModals]
  );

  return (
    <Button
      onClick={openPickBrandingColorModal}
      displayType="link"
      shape="round"
      size="lg"
      {...rest}
    >
      {t('djBrandColor')}
    </Button>
  );
};

export default PickBrandingColorButton;
