import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  prepModeSongsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 6px 0',

    '& .iconButton': {
      backgroundColor: 'var(--primary-main)',
      margin: '0 0 0 4px',
      width: '24px',
      height: '24px',

      '& svg': {
        margin: '0 auto',
        color: 'inherit',
      },

      '&:hover': {
        backgroundColor: 'var(--button-active)',
      },

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 6px',
        width: '28px',
        height: '28px',
      },
    },

    '& .viboButton': {
      whiteSpace: 'nowrap',
      width: 'fit-content',
      padding: '0 4px',
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '19.5px',

      '@media screen and (min-width: 1441px)': {
        padding: '0 6px',
        fontSize: '16px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 8px 0',
    },
  },

  prepModeSongsSearch: {
    maxWidth: '110px',
    margin: '0 0 0 auto',

    '@media screen and (min-width: 1441px)': {
      maxWidth: '130px',
    },
  },
}));

export default useStyles;
