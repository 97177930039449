import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  proLabel: {
    display: 'flex',
  },

  proLabelTooltip: {
    maxWidth: '300px',

    '@media screen and (min-width: 1441px)': {
      maxWidth: '400px',
    },
  },
}));

export default useStyles;
