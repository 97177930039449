import React, { FC } from 'react';

import ShareTemplateButton from 'components/buttons/ShareTemplateButton';
import EventNotificationsButton from 'components/buttons/EventNotificationsButton';
import CloneTemplateBtnByPublicity from '../CloneTemplateButton/CloneTemplateBtnByPublicity';

import { TemplateHeaderExtraProps } from './interfaces';

const TemplateHeaderExtra: FC<TemplateHeaderExtraProps> = ({ template }) => {
  return (
    <>
      <EventNotificationsButton to={`/template/${template._id}/notifications`} />
      <CloneTemplateBtnByPublicity template={template} displayType="default" />
      {template?.canEdit ? <ShareTemplateButton template={template} /> : null}
    </>
  );
};

export default TemplateHeaderExtra;
