import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  appleMusicConnect: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'var(--background-paper)',
  },
}));
export default useStyles;
