import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  contentBottomArea: {
    marginTop: '8px',
  },

  eventContentScroll: {
    height: 'calc(100% - 84px)',
  },

  content: {
    display: 'flex',
    paddingBottom: '20px',

    '& h3': {
      margin: '0',
    },
  },

  contentLeft: {
    margin: '0 55px 0 0',

    '& > h3': {
      marginBottom: '12px',

      '@media screen and (min-width: 1441px)': {
        marginBottom: '16px',
      },
    },

    '& .viboImage': {
      '& .eventTypeIcon': {
        fontSize: '8rem!important',
      },
    },

    '& .contentArea': {
      padding: '12px',
    },

    '& > .viboButton': {
      width: '100%',
      padding: '10px',
      backgroundColor: 'var(--background-paper)',
      margin: '0 0 8px 0',

      '&.lgSize ': {
        height: '36px',
      },

      '& svg': {
        width: '20px',
        height: '20px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },

      '&:first-of-type': {
        marginTop: '20px',

        '@media screen and (min-width: 1441px)': {
          marginTop: '24px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 12px 0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      margin: '0 72px 0 0',
    },
  },

  contentRight: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    color: 'var(--text-primary)',
    fontSize: '13px',
    lineHeight: '19.5px',

    '& > h3': {
      padding: '0 0 0 16px',

      '@media screen and (min-width: 1441px)': {
        padding: '0 0 0 24px',
      },
    },

    '& p': {
      marginBottom: 0,
    },

    '& > .contentArea': {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px',
      margin: '12px 0 24px 0',
      gap: '20px',
      position: 'relative',

      '@media screen and (min-width: 1441px)': {
        padding: '24px',
        margin: '16px 0 30px 0',
        gap: '24px',
      },
    },

    '& .settingsRow': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .switchWithTooltip': {
        width: '100%',
      },

      '& .viboSwitchLabel, & .viboInputWrapper': {
        margin: '0 0 0 auto',
      },
    },

    '& .row': {
      '&:last-child': {
        margin: '0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

export default useStyles;
