import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songsBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    userSelect: 'none',

    // FOR GTM TRIGGER
    '& > .viboButton .viboIcon': {
      pointerEvents: 'none',
    },

    '& > .viboButton:not(.caret), & > .addSongsWrapper': {
      margin: '0 0 0 6px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 8px',
      },
    },
  },

  searchOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,

    //for gtm tracking propper target
    '& > *': {
      pointerEvents: 'none',
    },
  },

  spotifyIcon: {
    color: 'var(--clr-mountainMeadow)',
  },
}));

export default useStyles;
