import { cache } from 'graphql/client';
import { GET_ME } from 'graphql/queries/user';
import { TierByName } from 'types/enums';

export const getUserFullName = <T extends { firstName: string; lastName: string }>(
  user: Nullable<T>
): string => (user ? `${user.firstName} ${user.lastName}`.trim() : '');

export const setMe = (me: Nullable<User>) =>
  cache.writeQuery({
    query: GET_ME,
    data: {
      me,
    },
  });

export const isUserProTier = (user?: ViboUser) => {
  if (!user?.tier) {
    return false;
  }

  return user.tier[0].name === TierByName.PRO;
};
