import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  searchInputWrapper: {
    position: 'relative',

    '& .iconPrefix': {
      color: 'var(--text-hint)',
    },
  },

  viboSearchInput: {
    '&.viboInput': {
      border: `1px solid var(--text-hint)`,

      '&:focus, &:active': {
        backgroundColor: 'var(--background-paper)',
      },
    },
  },
}));

export default useStyles;
