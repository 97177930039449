import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  song: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '48px',
    padding: '0 8px 0 25px',
    userSelect: 'none',
    position: 'relative',

    '&.highlighted': {
      '&:after': {
        ...mixins.highlightedSongPseudo,
        border: `1px solid var(--primary-main)`,
      },
    },

    '&.timelineSong': {
      height: '100%',

      //for gtm tracking propper target
      '& .songImage, & .songIndex, & .action .viboIcon, & .iconButton svg': {
        pointerEvents: 'none',
      },

      '& .dndSongIcon': {
        position: 'absolute',
        left: '6px',
      },

      '& .songArtistNames': {
        gap: '2px',

        '@media screen and (min-width: 1441px)': {
          gap: '4px',
        },
      },

      '& .songImage': {
        width: '40px',
        height: '40px',

        '@media screen and (min-width: 1441px)': {
          width: '48px',
          height: '48px',
        },
      },

      '& .stopPropagationWrapper': {
        display: 'flex',
      },

      '& .copy, & .songCommentIcon, & .mustPlayIcon:not(.isMustPlay), & .flag:not(.isFlagged)': {
        opacity: 0,
      },

      '&:hover': {
        '& .copy, & .songCommentIcon, & .mustPlayIcon:not(.isMustPlay), & .flag:not(.isFlagged)': {
          opacity: 1,
          pointerEvents: 'all',
        },

        '& .iconButton, & .dndSongIcon, & .defaultLiked, & .viboCheckbox > .viboIcon:not(:hover)': {
          color: 'var(--primary-main)',
        },
      },
    },

    '& .creatorImage': {
      '& .gradient svg': {
        width: '10px',
        height: '10px',
        borderRadius: '0',
      },
    },

    '& .copy': {
      margin: '0 auto 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 auto 0 5px',
      },
    },

    '& .iconButton, & .dndSongIcon': {
      color: 'var(--text-hint)',

      '&:hover': {
        '& svg ': {
          color: 'var(--button-active)',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      height: '56px',
      padding: '0 8px 0 28px',
    },
  },

  songImage: {
    cursor: 'default',
    width: '28px',
    height: '28px',

    '&:hover': {
      boxShadow: 'none',
    },

    '@media screen and (min-width: 1441px)': {
      width: '36px',
      height: '36px',
    },
  },

  withComment: {
    '& .songArtistNames': {
      maxWidth: '150px',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '200px',
      },

      '@media screen and (min-width: 1537px)': {
        maxWidth: '250px',
      },
    },
  },

  songIndex: {
    width: '30px',
    textAlign: 'center',
    color: 'var(--text-secondary)',

    '@media screen and (min-width: 1500px)': {
      width: '36px',
    },
  },
}));

export default useStyles;
