import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  siderExtra: {
    padding: '6px 12px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: 'var(--background-paper)',
    margin: 'auto 0 0 0',

    '& .viboButton': {
      padding: '0',
      borderRadius: '44px',

      '&.smSize': {
        height: '20px',

        '@media screen and (min-width: 1441px)': {
          height: '24px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '6px 16px 16px',
    },
  },

  collapseButton: {
    padding: '0',
    borderRadius: '50%',
    width: 'fit-content',

    '&.smSize': {
      width: '20px',

      '@media screen and (min-width: 1441px)': {
        width: '24px',
      },
    },
  },

  themeButton: {
    margin: '10px 0 6px',

    '&.centrate .prefixIcon': {
      left: '2px',
      borderRadius: '50%',
      color: 'var(--background-dropDown)',
      backgroundColor: 'var(--balticSeaToWhite)',
      padding: '2px',
    },

    '&.centrate': {
      padding: '0 0 0 14px',
    },

    '@media screen and (min-width: 1441px)': {
      margin: '12px 0 16px 0',
    },
  },

  madeWithLove: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 0 0 0',
    minHeight: '20px',

    '&:not(.isPro)': {
      flexDirection: 'row',
      justifyContent: 'center',
      borderRadius: '44px',
      border: `1px solid var(--action-disabled)`,
      pointerEvents: 'none',
    },

    '&.closed': {
      border: `1px solid transparent`,
    },

    '@media screen and (min-width: 1441px)': {
      margin: '12px 0 0 0',
      minHeight: '24px',
    },
  },

  vibo: {
    color: 'var(--primary-main)',
    position: 'relative',

    '& svg': {
      width: '19.3px',
      height: '7.4px',

      '@media screen and (min-width: 1441px)': {
        width: '25.8px',
        height: '9.9px',
      },
    },
  },

  madeWith: {
    width: '47px',
    height: '8px',
    color: 'var(--emperorToWhite)',
    margin: '0 2px 0 3px',

    '@media screen and (min-width: 1441px)': {
      margin: '0 3px',
      width: '57.41px',
      height: '9.76px',
    },
  },

  viboHeart: {
    width: '12px',
    height: '12px',
    color: 'var(--error-main)',
  },

  madeWithViboPro: {
    height: '30px',

    '@media screen and (min-width: 1441px)': {
      height: '38px',
    },
  },
}));

export default useStyles;
