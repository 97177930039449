import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  sectionBlock: {
    '& .emptyState.noSongsFound, & .emptyState.noQuestions, & .emptyState.noSongs': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& *': {
        opacity: 1,
        transition: 'opacity .3s',
      },

      '& .description': {
        color: 'var(--text-hint)',
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '24.09px',
        margin: '0 6px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '20px',
          lineHeight: '30px',
          margin: '0 15px',
        },
      },

      '& .stateIcon svg': {
        width: '64px',
        height: '64px',
        margin: '0 0 0 6px',

        '@media screen and (min-width: 1441px)': {
          width: '76px',
          height: '76px',
          margin: '0 0 0 8px',
        },
      },
    },

    '&.blankEmptyState': {
      '& .emptyState': {
        '& *': {
          opacity: 0,
        },
      },

      '& .timelineSongsHeader': {
        ...mixins.noClickableFilter,
      },
    },

    '&.isEventGuest': {
      '& .caretButton': {
        display: 'none',
      },
    },

    '&:not(.collapsed)': {
      '& .sectionBlockHeader': {
        margin: '0 0 10px 0',
      },
    },

    '&.collapsed': {
      '& .sectionBlockHeader': {
        borderRadius: '4px',
      },
    },

    '& .sectionBlockHeader': {
      zIndex: 2,
    },

    '& .sectionBlockContent': {
      zIndex: 1,
    },
  },

  sectionBlockHeader: {
    position: 'sticky',
    top: '0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'padding .2s',
    backgroundColor: 'var(--background-default)',
    cursor: 'pointer',

    '&:before': {
      zIndex: 0,
      position: 'absolute',
      backgroundColor: 'var(--background-default)',
      content: '""',
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 4px)',
      left: '-4px',
      top: '-8px',
    },

    '& > *': {
      zIndex: 5,
    },
  },

  notShownContent: {
    '& .timelineSongsHeader': {
      top: 0,
      position: 'relative',
    },

    '& .questionTitle': {
      zIndex: 1,
    },

    '& .viboList': {
      position: 'absolute',
      width: '100%',
      top: '0',
    },
  },

  sectionBlockContent: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',

    '& > .emptyState, & > .viboSpinner': {
      height: '80px',

      '@media screen and (min-width: 1500px)': {
        height: '102px',
      },
    },
  },

  sectionBlockFooter: {
    width: '100%',
    backgroundColor: 'var(--background-paper)',
    borderRadius: '0 0 4px 4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 2,

    '& .viboButton': {
      width: '100%',
      fontSize: '13px',
      lineHeight: '19.56px',
      fontWeight: 400,
      borderRadius: '0 0 4px 4px',
      transition: 'none',

      '&.collapse': {
        '& .spinner': {
          display: 'none',
        },
      },

      '& > .viboIcon': {
        margin: '0 0 0 6px',
      },

      '&:not(:disabled):hover': {
        color: 'var(--action-hover)',
        backgroundColor: 'var(--sectionHover)',
      },

      '@media screen and (min-width: 1500px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  blockIcon: {
    display: 'flex',
    color: 'var(--primary-main)',
    padding: '5px',
    width: 'fit-content',
    cursor: 'pointer',
    margin: '0 6px 0 0',

    '@media screen and (min-width: 1500px)': {
      padding: '6px',
      margin: '0 8px 0 0',
    },

    '& svg': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1500px)': {
        width: '16px',
        height: '16px',
      },
    },
  },

  sectionBlockInfo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '17px',
    lineHeight: '21px',
    margin: '0 auto 0 0',
    fontWeight: 500,

    '@media screen and (min-width: 1500px)': {
      fontSize: '20px',
      lineHeight: '25.2px',
    },
  },

  title: {
    width: '100%',
  },

  extraInfo: {
    marginLeft: '4px',
  },

  caret: {
    margin: '0 8px 0 0',

    //for gtm tracking propper target
    '& > .viboIcon': {
      pointerEvents: 'none',
    },

    '@media screen and (min-width: 1441px)': {
      margin: '0 2px 0 0',
    },
  },
}));

export default useStyles;
