import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  personalInfoInner: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    columnGap: '13px',
    padding: '12px',
  },

  profileInfo: {
    margin: '0 0 100px 0',

    '& .contentArea > .viboTextareaWrapper .viboTextarea': {
      padding: 0,
      lineHeight: '19.5px',

      '@media screen and (min-width: 1441px)': {
        lineHeight: '24px',
      },
    },

    '& .inputLabel': {
      margin: '0 0 2px 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 10px 0',
      },
    },
  },

  nameBlock: {
    '& input': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '25px',
      margin: '0',
      height: 'auto',

      '@media screen and (min-width: 1441px)': {
        fontSize: '24px',
      },
    },
  },

  flexInfo: {
    flexDirection: 'column',
    display: 'flex',
    flexWrap: 'wrap',
  },

  userInfoInput: {
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '16px',

    '& + .limit': {
      transform: 'translateY(100%)',
    },
  },

  userInfoSwitcher: {
    marginTop: '20px',
  },

  showRealName: {
    margin: 'auto 0 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },

  phoneNumberLayout: {
    padding: '12px',

    '& span': {
      fontSize: '14px',
      color: 'var(--text-secondary)',
    },
  },

  websiteInfo: {
    padding: '12px',

    '& input': {
      zIndex: 1,
      fontSize: '16px',
    },

    '& > span': {
      fontSize: '14px',
      color: 'var(--text-secondary)',
    },
  },

  locationInfo: {
    padding: '12px',

    '& input': {
      fontSize: '16px',
      color: 'var(--text-primary)',
    },

    '& span': {
      fontSize: '14px',
      color: 'var(--text-secondary)',
    },
  },

  profilePicture: {
    display: 'flex',
    flexDirection: 'column',

    '&.canEdit': {
      cursor: 'pointer',
    },

    '& svg': {
      color: 'var(--primary-main)',
    },

    '& span': {
      fontSize: '11px',
      lineHeight: '16.55px',
      color: 'var(--text-secondary)',
      paddingBottom: '4px',

      '@media screen and (min-width: 1441px)': {
        paddingBottom: '6px',
        fontSize: '14px',
        lineHeight: '21.07px',
      },
    },

    '& .gradient span': {
      color: 'var(--primary-main)',
      fontSize: '18px!important',
      lineHeight: '24px!important',

      '@media screen and (min-width: 1441px)': {
        fontSize: '24px!important',
        lineHeight: '34px!important',
      },
    },

    '& img': {
      borderRadius: '4px',
    },
  },

  imageCover: {
    height: '288px',
    width: '288px',

    '& .gradient': {
      background: 'var(--selection-hover)',
    },

    '& svg': {
      width: '80px',
      height: '80px',
    },

    '& span': {
      color: 'var(--background-paper)',
      fontSize: '16px',
      fontWeight: '700',
    },

    '@media screen and (min-width: 1441px)': {
      height: '384px',
      width: '384px',
    },
  },

  brandingInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default useStyles;
