import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Tooltip from 'vibo-ui/Tooltip';
import Delete from '../SongActions/Delete';
import MoveTo from '../SongActions/MoveTo';
import MoveToSectionsMenu from '../SongActions/MoveTo/MoveToSectionsMenu';

import { useEvent } from 'components/context/EventContext';

import { UPDATE_SECTION_SONGS } from 'graphql/mutations/songs';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SectionType, SongItemMenuKey } from 'types/enums';
import { TimelineSongItemMenuProps } from './interfaces';

import useStyles from './style';

const SongActionsMenu: FC<TimelineSongItemMenuProps> = ({
  song,
  sections,
  variables,
  onMenuItemClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isSectionsOpened, setIsSectionsOpened] = useState(false);

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const { isDontPlayVisible } = useEvent();

  const [updateSong] = useMutation<UpdateSectionSongsData, UpdateSectionSongsVariables>(
    UPDATE_SECTION_SONGS,
    {
      refetchQueries: ['getSectionSongs'],
    }
  );

  return (
    <Menu styleModificator="asDropdown" selectable={false} activeKey="">
      <Menu.Item
        onClick={() => {
          updateSong({
            variables: {
              eventId,
              sectionId,
              payload: {
                isMustPlay: !song.isMustPlay,
              },
              songIds: [song._id],
            },
          });
          onMenuItemClick();
        }}
        icon={IconmoonFont['crown-16']}
        key={SongItemMenuKey.mustPlay}
      >
        {t('mustPlay')}
      </Menu.Item>
      {isDontPlayVisible ? (
        <MoveTo
          songs={[song]}
          render={(setIsMoveModalVisible, setTargetSection) => (
            <Menu.Item
              onClick={() => {
                const dontPlaySection: Maybe<EventSection> = sections.find(
                  section => section.type === SectionType.dontPlay
                );

                if (dontPlaySection) {
                  onMenuItemClick();
                  setTargetSection(dontPlaySection);
                  setIsMoveModalVisible(true);
                }
              }}
              icon={IconmoonFont['stop-16']}
              key={SongItemMenuKey.dontPlay}
            >
              {t('dontPlay')}
            </Menu.Item>
          )}
        />
      ) : null}
      <Tooltip
        overlay={
          isSectionsOpened ? (
            <MoveTo
              songs={[song]}
              render={(setIsMoveModalVisible, setTargetSection) => (
                <MoveToSectionsMenu
                  sections={sections}
                  sectionId={variables.sectionId}
                  onSelectSection={section => {
                    onMenuItemClick();
                    setTargetSection(section);
                    setIsMoveModalVisible(true);
                  }}
                />
              )}
              onDiscard={() => setIsSectionsOpened(false)}
            />
          ) : null
        }
        trigger={['click']}
        placement="leftTop"
        align={{
          offset: [0, 0],
        }}
        className={classes.selectSectionMenu}
      >
        <Menu.Item
          onClick={() => {
            setIsSectionsOpened(true);
          }}
          icon={IconmoonFont['arrowRight-16']}
          key={SongItemMenuKey.moveToSub}
        >
          {t('moveTo')}
        </Menu.Item>
      </Tooltip>
      <Delete
        songs={[song]}
        render={() => (
          <Menu.Item
            onClick={onMenuItemClick}
            icon={IconmoonFont['delete-16']}
            key={SongItemMenuKey.delete}
          >
            {t('delete')}
          </Menu.Item>
        )}
      />
    </Menu>
  );
};

export default SongActionsMenu;
