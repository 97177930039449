import React, { FC, createContext, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { useQuery, useReactiveVar } from '@apollo/client';

import { getPraparedUserWithTier, isBrandingActive } from 'utils/helpers/proTier';

import { isLoggedInVar } from 'graphql/cache';
import { GET_ME, GET_META } from 'graphql/queries/user';

import { TUserContext } from './interfaces';

export const UserContext = createContext<TUserContext>(undefined!);

export const UserContextProvider: FC = ({ children }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const { data: metaData, loading: metaLoading, refetch: refetchMeta } = useQuery(GET_META, {
    fetchPolicy: 'network-only',
    pollInterval: 3600000,
    skip: !isLoggedIn,
  });
  const meta: UserMeta = get(metaData, 'getMeta');

  const isBrandingAllowed = useMemo(() => isBrandingActive(meta?.allowedFeatures), [
    meta?.allowedFeatures,
  ]);

  const { data, loading: userLoading, refetch } = useQuery<UserResponse>(GET_ME, {
    skip: !isLoggedIn,
    fetchPolicy: 'cache-and-network',
  });

  const user: Maybe<User> = getPraparedUserWithTier(data?.me, isBrandingAllowed);

  const isDataPrepared = useMemo(() => !userLoading && !metaLoading, [userLoading, metaLoading]);

  useEffect(() => {
    document.addEventListener('visibilitychange', refetchMeta);

    return () => {
      document.removeEventListener('visibilitychange', refetchMeta);
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        isBrandingAllowed,
        allowedFeatures: meta?.allowedFeatures || [],
        tier: meta?.tier || [],
        refetchUser: () => {
          refetch();
        },
        isLoggedIn,
      }}
    >
      {isDataPrepared ? children : null}
    </UserContext.Provider>
  );
};
