import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '../EmptyState';

import { ReactComponent as NoCustomNotificationsIcon } from 'resources/img/svg/emptyStateNoCustomNotifications.svg';

const NoCustomNotifications: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <EmptyState size="lg" icon={NoCustomNotificationsIcon}>
      {t('noCustomNotificationsYet')}
      {children}
    </EmptyState>
  );
};

export default NoCustomNotifications;
