import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  themeButton: {
    margin: '0 0 0 16px',

    '&.smSize': {
      padding: 0,
    },

    '&.defaultDisplay': {
      '&, &:hover': {
        backgroundColor: 'var(--emperorToWhite)!important',
        color: 'var(--whiteToBlack)!important',
      },
    },

    '&.circleShape': {
      width: '20px',
      height: '20px',

      '@media screen and (min-width: 1441px)': {
        width: '24px',
        height: '24px',
      },
    },

    '& svg': {
      fill: 'none',
    },
  },
}));

export default useStyles;
