import { Dispatch, useEffect, useState, ClipboardEvent } from 'react';
import get from 'lodash/get';

export const getChildIndex = (element: HTMLElement): Nullable<number> => {
  const parent = element?.parentNode;

  if (element && parent?.children) {
    return Array.from(parent?.children).indexOf(element);
  }

  return null;
};

export const setSelection = (element: HTMLElement, offset: number): void => {
  const range = document.createRange();
  const selection = window.getSelection();

  range.setStart(element.childNodes[0], offset);
  range.collapse(true);

  selection?.removeAllRanges();
  selection?.addRange(range);
};

export const setSelectionToEnd = (element: HTMLElement) => {
  element.focus();

  setTimeout(() => {
    const range = document.createRange();
    const selection = window.getSelection();

    if (selection) {
      selection.removeAllRanges();
      range.selectNodeContents(element);
      range.collapse(false);
      selection.addRange(range);
    }
  });
};

export const setCursorToEnd = (element: HTMLTextAreaElement) => {
  element?.setSelectionRange(element.value.length, element.value.length);
};

export const escapeHtml = (html?: string): string => {
  const tagRegex = /<[^>]*>.*?<\/[^>]*>/g;

  if (html) {
    const el = document.createElement('div');

    el.innerHTML = html.replaceAll(tagRegex, '');

    return el.innerText;
  }

  return '';
};

export const escapeMagicFields = (html?: string): string => {
  const doubleBracesRegex = /\$(.*?)\$/g;

  if (!!html) {
    const el = document.createElement('div');

    el.innerHTML = html?.replaceAll(doubleBracesRegex, '');

    return el.innerText;
  }

  return '';
};

export const focusEndEditableElement = (el?: HTMLElement) => {
  const selection = window.getSelection();

  if (selection && el) {
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
    el.focus();
  }
};

export interface StickyElementProps {
  el?: Nullable<Element>;
  scrollTrackEl?: Nullable<Element>;
  forceUpdate?: boolean;
  setControlObserver?: Dispatch<Nullable<() => void>>;
}

export const useStickyElement = ({
  el,
  scrollTrackEl,
  forceUpdate,
  setControlObserver,
}: StickyElementProps) => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const handleObserve = () => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (isSticky !== !e.isIntersecting && e.intersectionRatio !== 0) {
          const handleUpdate = () => {
            const isIntersecting = !e.isIntersecting;

            e.target.classList.toggle('sticky', isIntersecting);
            setIsSticky(isIntersecting);
          };

          if (!!scrollTrackEl) {
            (scrollTrackEl?.scrollTop > 3 || forceUpdate) && handleUpdate();
          } else {
            handleUpdate();
          }
        }
      },
      {
        threshold: [1],
      }
    );
    el && observer.observe(el);
  };

  useEffect(() => {
    scrollTrackEl && handleObserve && scrollTrackEl.addEventListener('scroll', handleObserve);

    return () => {
      scrollTrackEl && handleObserve && scrollTrackEl.removeEventListener('scroll', handleObserve);
    };
  }, [scrollTrackEl]);

  useEffect(() => {
    handleObserve();
  }, []);

  useEffect(() => {
    setControlObserver?.(handleObserve);
  }, [el, handleObserve]);

  return {
    isSticky,
  };
};

export const isChildFocused = (element: HTMLElement) => element.contains(document.activeElement);

export const getUploadImage = async (
  image: Nullable<string> | ArrayBuffer
): Promise<Nullable<File>> =>
  await fetch(`${image}`)
    .then(res => res.blob())
    .then(blob => new File([blob], 'image.jpg', { type: 'image/jpeg' }))
    .catch(() => null);

export const getScrollParent = (id: string) => {
  return document.getElementById(id);
};

export const scrollToElSmooth = (scrollId: string, elementId: string) => {
  const scroll = getScrollParent(scrollId);
  const element = document.getElementById(elementId);
  const spyElement = document.querySelector(`[data-spy-id=${elementId}]`);

  const target = (element || spyElement) as HTMLElement;

  if (!!target && !!scroll) {
    scroll?.scrollTo({
      top: target.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export const checkElementsInFocus = (selectors: string[]) => {
  return selectors.some(selector => !!document.querySelectorAll(`${selector}:focus`).length);
};

export const insertHtmlToMagicField = (html: string, element: HTMLElement, id: string) => {
  const sel = window.getSelection();

  try {
    if (!!sel && sel.rangeCount > 0) {
      const range = sel?.getRangeAt(0);
      const selClassname = get(sel?.anchorNode, 'className');
      const selId = get(sel?.anchorNode, 'id');
      const selParentId = get(range?.startContainer.parentNode, 'id');
      const rangeClassName = get(range?.startContainer.parentNode, 'className');

      if (
        !!range &&
        (selId === id || selParentId === id) &&
        (`${selClassname}`.includes('magicField') || `${rangeClassName}`.includes('magicField'))
      ) {
        range.deleteContents();

        const el = document.createElement('div');
        el.innerHTML = html;

        const frag = document.createDocumentFragment();
        let node;
        let lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }

        range.insertNode(frag);

        if (lastNode) {
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }

        // @ts-ignore
        return sel.focusNode.innerHTML || null;
      }
    }

    const el = document.createElement('div');
    el.innerHTML = html;

    !!el.firstChild && element?.appendChild(el.firstChild);

    return element.innerHTML || null;
  } catch {}
};

export const setCssVar = (name: string, value: string) =>
  document.body.style.setProperty(name, value);

export const handlePasteOnlyText = (
  e: ClipboardEvent<HTMLElement>,
  callback?: (e: ClipboardEvent<HTMLElement>) => void
) => {
  e.preventDefault();

  const text = e.clipboardData.getData('text');
  const selection = window.getSelection();

  if (selection?.rangeCount) {
    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(document.createTextNode(text));

    return callback?.(e);
  }
};
