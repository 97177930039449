import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboOverlayWrapper: {
    '& > .rc-menu, & > .clickAwayListener > .rc-menu': {
      margin: '0',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '19.56px',
      padding: '0',
      color: 'var(--primary-main)',
      overflow: 'hidden',
      backgroundColor: 'var(--background-paper)',

      '& > .rc-menu-item, & div > .rc-menu-item': {
        padding: '2.5px 8px',

        '& > .menuItemContent, & .action': {
          '& > .viboIcon': {
            marginRight: '6px',

            '&.viboIconSVG': {
              width: '14px',
              height: '14px',

              '@media screen and (min-width: 1441px)': {
                width: '16px',
                height: '16px',
              },
            },
          },
        },

        '&:not(.rc-menu-item-disabled)': {
          cursor: 'pointer',
        },

        '&:hover': {
          backgroundColor: 'var(--button-active)',

          '&, & .menuItemContent > .viboIcon': {
            '&, &:before': {
              color: 'var(--background-paper)',
            },
          },
        },
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    '&.blue': {
      border: `1px solid 'var(--clr-white)',`,

      '& .rc-menu-item': {
        backgroundColor: 'var(--primary-main)',
        color: 'var(--clr-white)',

        '&:hover': {
          backgroundColor: `var(--button-active)!important`,
          color: 'var(--clr-white)',
        },
      },
    },
  },

  viboToggleDropdownDefault: {
    backgroundColor: 'transparent',
    display: 'flex',
  },

  viboToggleDropdown: {
    width: 'fit-content',

    '& > button': {
      padding: '5px 8px',
      fontSize: '13px',
      lineHeight: '19.56px',
      cursor: 'pointer',
      background: 'none',
      border: 'none',

      '&.lgSize': {
        minHeight: '28px',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
}));

export default useStyles;
