import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const thumbStyles = () => ({
  backgroundColor: 'var(--primary-main)',
  borderRadius: '50%',
  height: '8px',
  width: '8px',
  transition: 'all .2s',
});

const useStyles = createUseStyles(() => ({
  songPlayerWrapper: {
    width: '100%',
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 90,
    fontSize: '13px',
    paddingLeft: '120px',

    '&.isSiderClosed': {
      paddingLeft: '44px',

      '@media screen and (min-width: 1441px)': {
        paddingLeft: '56px',
      },
    },

    '& .playBtn': {
      padding: 0,

      '&.smSize': {
        height: 'auto',
      },
    },

    '& .closeBtn, & .volumeBtn': {
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },

    '& .container': {
      position: 'relative',

      '& .containerContent': {
        height: '64px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },

    '& .logo': {
      color: 'var(--primary-main)',
      margin: '0 20px 0 0',

      '& > svg': {
        width: '98px',

        '@media screen and (min-width: 1441px)': {
          width: '128px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        margin: '0 24px 0 0',
      },
    },

    '& .info': {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto 0 0',
      userSelect: 'none',

      '& .name, & .artist': {
        ...mixins.longText,
        maxWidth: '22ch',
        color: 'var(--text-primary)',
        whiteSpace: 'nowrap',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '32ch',
        },
      },

      '& .artist': {
        fontWeight: 'bold',
      },
    },

    '& .seek': {
      color: 'var(--text-secondary)',
      margin: '0 0 0 8px',
    },

    '& .progress': {
      width: '240px',
      margin: '0 20px',

      '&::part(cursor)': {
        height: '100px',
        top: '50px',
        borderRadius: '4px',
        border: '1px solid #fff',
      },

      '&::part(cursor):after': {
        content: '"🏄"',
        fontSize: '1.5em',
        position: 'absolute',
        left: '0',
        top: '-28px',
        transform: 'translateX(-50%)',
      },

      '& wave': {
        cursor: 'pointer',

        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },

      '@media screen and (min-width: 1441px)': {
        width: '370px',
        margin: '0 24px',
      },
    },

    '& .volume': {
      // ...progressFillStyles(50),

      '-webkit-appearance': 'none',
      height: '2px',
      backgroundRepeat: 'no-repeat',
      width: '70px',
      borderRadius: '2px',
      margin: '0 12px 0 0',
      backgroundImage: `linear-gradient(to right, var(--primary-main) calc(var(--volume-progress) * 100%), var(--dustGrayToSilver) calc(var(--volume-progress) * 100%))`,

      '&:hover': {
        '&::-webkit-slider-thumb': {
          cursor: 'ew-resize',
          height: '14px',
          width: '14px',
        },
      },

      '&::-webkit-slider-runnable-track, &::-moz-range-track': {
        '-webkit-appearance': 'none',
        background: 'transparent',
        boxShadow: 'none',
        border: 'none',
      },

      '&::-webkit-slider-thumb': {
        ...thumbStyles(),
        '-webkit-appearance': 'none',
        appearance: 'none',
      },

      '&::-moz-range-thumb': {
        ...thumbStyles(),
      },

      '&::-ms-thumb': {
        ...thumbStyles(),
      },

      '&:focus': {
        outline: 'none',
        border: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        width: '100px',
        margin: '0 16px 0 0',
      },
    },

    '& .volumeBtn': {
      margin: '0 12px 0 6px',

      '&.noVolume': {
        color: 'var(--text-secondary)',

        '& .viboIconSVG': {
          position: 'relative',

          '&:before': {
            content: '""',
            backgroundColor: 'var(--text-secondary)',
            width: '2px',
            height: '130%',
            position: 'absolute',
            transform: 'rotate(30deg) translate(-2px, -2px)',
          },
        },
      },

      '&.oneBar': {
        '& .viboIconSVG': {
          '& g > path:nth-child(odd)': {
            opacity: 0,
          },
        },
      },

      '@media screen and (min-width: 1441px)': {
        margin: '0 16px 0 10px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      paddingLeft: '150px',
    },
  },
}));

export default useStyles;
