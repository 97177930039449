import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  fileLeft: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '0',
    whiteSpace: 'nowrap',

    '& .progressBar': {
      height: '14px',
      width: '24px',
      fontSize: '9px',
      lineHeight: '16px',
      fontWeight: 700,
      margin: '0 4px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 5px 0 8px',
        height: '16px',
        width: '30px',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },

  fileName: {
    fontSize: '13px',
    lineHeight: '19.5px',
    color: 'var(--text-secondary)',

    '&': {
      ...mixins.longText,
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  songWrapper: {
    width: '99%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .copy': {
      opacity: 1,
    },

    '& .viboCheckbox': {
      '&:before': {
        display: 'none',
      },
    },

    '& > svg': {
      marginRight: '4px',
      minWidth: '16px',
      color: 'var(--primary-main)',
      cursor: 'default',

      '&:hover': {
        color: 'var(--action-hover)',
      },
    },

    '& label, & label > span:last-child': {
      display: 'flex',
      alignItems: 'center',

      '& > div': {
        marginRight: '4px',
        cursor: 'default',
      },

      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },

  fileRight: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
  },
}));

export default useStyles;
