import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  djCard: {
    width: 'auto',
    height: '238px',
    borderRadius: '4px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },

  djNameWrapper: {
    height: '36px',
    padding: '0 15px',
    backgroundColor: 'var(--background-paper)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  djPhoto: {
    width: '100%',
    height: '100%',
    borderRadius: '0',
  },

  djName: {
    width: '100%',
    height: '20px',
  },
}));

export default useStyles;
