import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AddNotificationButton from './AddNotificationButton';
import EventTitle from 'components/events/Timeline/EventTitle';
import WithEventFeature from 'components/user/WithEventFeature';
import PageContentHeader from 'components/common/PageContentHeader';
import CloneTemplateButton from 'components/template/CloneTemplateButton';
import { EventContext } from 'components/context/EventContext';
import { EditableContext } from 'components/common/EditableContext';

import { IconmoonFont } from 'vibo-ui/Icon';
import { TierFeatueName } from 'types/enums';
import { NotifPageSubheaderProps } from './interfaces';

const NotifPageSubheader: FC<NotifPageSubheaderProps> = ({ title }) => {
  const { t } = useTranslation();

  const { event } = useContext(EventContext);
  const { canEdit } = useContext(EditableContext);

  return (
    <PageContentHeader
      extra={
        <WithEventFeature feature={TierFeatueName.custom_notifications}>
          {canEdit ? (
            <AddNotificationButton />
          ) : (
            <CloneTemplateButton
              template={(event as unknown) as Template}
              displayType={'bordered'}
              prefixIcon={IconmoonFont['plus-16']}
              children={t('addToMyTemplates')}
            />
          )}
        </WithEventFeature>
      }
    >
      <EventTitle name={title} />
    </PageContentHeader>
  );
};

export default NotifPageSubheader;
