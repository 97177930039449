import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  prepModeSection: {
    '& .markDone': {
      display: 'none',
      fontWeight: 500,

      '&:active, &:focus': {
        color: 'var(--primary-main)',
      },

      '&:active:hover, &:focus:hover': {
        color: 'var(--action-hover)',
      },
    },

    '&:hover .markDone': {
      display: 'block',
    },

    '& .sectionBottom': {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },

  status: {
    padding: '0 8px 0 6px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    '& div': {
      display: 'flex',
      alignItems: 'center',

      '& > div:first-child': {
        minWidth: '20px',
        display: 'flex',
      },
    },
  },

  progressBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'inherit',
    width: 'fit-content',

    '&.done': {
      color: 'var(--success-main)',
    },

    '& .viboIcon': {
      color: 'inherit',
      margin: ' 0 4px 0 0',
    },
  },

  isDoneIcon: {
    '&.isDone': {
      color: 'var(--success-main)',
    },
  },
}));

export default useStyles;
