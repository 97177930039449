import React, { FormikProvider } from 'formik';
import classNames from 'classnames';

import { FormProps } from './interfaces';

import useStyles from './style';

const Form = <T extends {}>({ className, formik, children, ...rest }: FormProps<T>) => {
  const classes = useStyles();

  return (
    <form
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      className={classNames('formWithFormik', classes.formWithFormik, className)}
      {...rest}
    >
      <FormikProvider value={formik}>{children}</FormikProvider>
    </form>
  );
};

export default Form;
