import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  triggerIcon: {
    '& svg': {
      transform: 'rotate(90deg)',
    },
  },

  sectionToolsOverlay: {
    '& .overlayWrapper': {
      margin: '-24px 0 0 24px',

      '@media screen and (min-width: 1441px)': {
        margin: '-28px 0 0 28px',
      },
    },
  },

  sectionToolsMenu: {
    //for gtm tracking propper target
    '& .menuItem': {
      '& *': {
        pointerEvents: 'none',
      },
    },
  },
}));

export default useStyles;
