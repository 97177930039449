import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  filePlayStats: {
    width: '110px',
    letterSpacing: '0.38px',
  },

  header: {
    fontWeight: 500,
    marginBottom: '4px',
  },

  stat: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',

    '& svg': {
      width: '16px',
      height: '16px',
      marginRight: '4px',
    },

    '& span': {
      color: 'var(--text-secondary)',
      fontSize: '14px',
    },
  },
}));

export default useStyles;
