import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboButton: {
    fontFamily: `'Roboto', sans-serif`,
    userSelect: 'none',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '14px',
    color: 'var(--text-primary)',
    transition: 'color .1s, background-color .1s, border .1s',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    position: 'relative',
    backgroundColor: 'transparent',

    '& > span': {
      width: '100%',
    },

    '&.xsSize': {
      height: '20px',
      padding: '0 6px',

      '@media screen and (min-width: 1441px)': {
        height: '24px',
      },
    },

    '&.smSize': {
      height: '24px',
      padding: '0 6px',

      '@media screen and (min-width: 1441px)': {
        height: '28px',
      },
    },

    '&.lgSize': {
      height: '30px',
      padding: '0 8px',

      '@media screen and (min-width: 1441px)': {
        height: '36px',
        padding: '0 10px',
      },
    },

    '&.primaryColor': {
      color: 'var(--primary-main)',
    },

    '& .prefixIcon, & .sufixIcon': {
      fontSize: '14px',
      padding: '0',
      display: 'inline-block',

      '&.viboIconSVG': {
        width: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
        },
      },

      '& svg': {
        fill: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
      },
    },

    '&[disabled]': {
      cursor: 'not-allowed',
    },

    '&.defaultDisplay': {
      border: 'none',

      '&[disabled]': {
        color: 'var(--text-disabled)',
      },

      '&:not(:disabled)': {
        '&:hover': {
          backgroundColor: 'var(--primary-lighty)',
        },
      },

      '&.danger': {
        '&, &:not([disabled]):hover': {
          color: 'var(--error-main)',
        },

        '&.borderedDisplay:not([disabled]):hover': {
          color: 'var(--error-main)',
          border: `1px solid var(--error-main)`,
        },

        '&[disabled]': {
          color: 'var(--error-light)',
          border: `1px solid var(--error-light)`,
        },
      },
    },

    '&.borderedDisplay': {
      border: `1px solid var(--text-primary)`,
      backgroundColor: 'var(--background-paper)',

      '&[disabled]': {
        border: `1px solid var(--text-disabled)`,
        color: 'var(--text-disabled)',
      },

      '&:not(:disabled)': {
        '&:hover': {
          backgroundColor: 'var(--primary-lighty)',
        },
      },

      '&.danger': {
        '&, &:not([disabled]):hover': {
          color: 'var(--error-main)',
          border: `1px solid var(--error-main)`,
        },

        '&[disabled]': {
          color: 'var(--error-light)',
          border: `1px solid var(--error-light)`,
        },
      },
    },

    '&.primaryDisplay': {
      color: 'var(--whiteToBalticSeaDark)',
      backgroundColor: 'var(--primary-main)',
      border: '1px solid transparent',

      '&[disabled]': {
        backgroundColor: 'var(--button-disabled)',
      },

      '&:not(:disabled)': {
        '&:hover': {
          backgroundColor: 'var(--button-active)',
        },
      },

      '&.success': {
        backgroundColor: 'var(--clr-mountainMeadow)',

        '&[disabled]': {
          backgroundColor: 'var(--text-hint)',
        },

        '&:not([disabled]):hover': {
          backgroundColor: 'var(--clr-malachite)',
        },
      },

      '&.danger': {
        backgroundColor: 'var(--error-main)',

        '&[disabled], &:not([disabled]):hover': {
          backgroundColor: 'var(--error-light)',
        },
      },
    },

    '&.linkDisplay': {
      border: 'none',
      backgroundColor: 'transparent',

      '&[disabled]': {
        color: 'var(--text-disabled)',
      },

      '&:not(:disabled)': {
        '&:hover': {
          color: 'var(--button-active)',
        },
      },

      '&.danger': {
        color: 'var(--error-main)',

        '&[disabled], &:not([disabled]):hover': {
          color: 'var(--error-light)',
        },
      },

      '&.success': {
        color: 'var(--success-main)',

        '&:not([disabled]):hover': {
          color: 'var(--success-light)',
        },
      },
    },

    '&.roundShape': {
      borderRadius: '4px',
    },

    '&.circleShape': {
      display: ' inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',

      '&.smSize': {
        minWidth: '20px',
        maxWidth: '20px',
        minHeight: '20px',
        maxHeight: '20px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '24px',
          maxWidth: '24px',
          minHeight: '24px',
          maxHeight: '24px',
        },
      },

      '&.lgSize': {
        minWidth: '32px',
        maxWidth: '32px',
        minHeight: '32px',
        maxHeight: '32px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '36px',
          maxWidth: '36px',
          minHeight: '36px',
          maxHeight: '36px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  withPrefixIcon: {
    '&.centrate': {
      position: 'relative',
      justifyContent: 'center',

      '& .prefixIcon': {
        position: 'absolute',
        left: '8px',
      },
    },
  },

  withSufixIcon: {
    '&.centrate': {
      position: 'relative',
      justifyContent: 'center',

      '& .prefixIcon': {
        position: 'absolute',
        right: '8px',
      },
    },
  },

  alignedContent: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  spinner: {
    height: 'auto',
    width: 'auto',
    maxHeight: '16px',

    '& .container': {
      padding: '0',
    },
  },

  prefixIcon: {
    '&.withChildren': {
      margin: '0 5px 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 6px 0 0',
      },
    },
  },

  sufixIcon: {
    margin: '0 0 0 7px',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 0 8px',
    },
  },
}));

export default useStyles;
