import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  maxCharsLimit: {
    opacity: '0',
    textAlign: 'right',
    width: '100%',
    fontSize: '12px',
    lineHeight: '19.84px',
    color: 'var(--primary-main)',
  },
}));

export default useStyles;
