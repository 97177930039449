import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  timelineSection: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '&.headline': {
      margin: '16px 0 0 0',

      '& .section': {
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: 'none',
        border: '1px solid transparent',
        backgroundColor: 'transparent',

        '& .sectionTop': {
          width: '100%',
          margin: 'auto 0',

          '& .textPreview.lg, .viboInputWrapper input': {
            maxWidth: '260px',

            '@media screen and (min-width: 1441px)': {
              maxWidth: '360px',
            },
          },
        },

        '& .sectionBottom': {
          display: 'none',
          flexDirection: 'row',
          alignItems: 'center',
        },

        '&:hover': {
          border: `1px solid var(--primary-light)`,
          backgroundColor: 'var(--sectionHover)',
        },
      },

      '@media screen and (min-width: 1441px)': {
        margin: '24px 0 0 0',
      },
    },

    '& .clickableSectionTime': {
      cursor: 'pointer',
    },

    '& .dndSectionIcon': {
      color: 'var(--primary-main)',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '4px',
      opacity: 0,
    },

    '& .section': {
      '& .sectionTop': {
        '& .viboTextareaWrapper': {
          '& .closeWrapper': {
            display: 'flex',
          },
        },
      },

      '& .sectionBottom': {
        '& .iconsWrapper, & .sectionLabels': {
          height: '26px',

          '@media screen and (min-width: 1441px)': {
            height: '30px',
          },
        },

        '& .sectionLabels': {
          marginLeft: 'auto',
          gap: '4px',
          display: 'flex',
          flexDirection: 'row',

          '& > div': {
            height: '100%',
          },
        },

        '& .proLabel.customLabel': {
          padding: '0 6px',
        },
      },
    },

    '&:hover': {
      '& .dndSectionIcon': {
        opacity: 1,
      },
    },
  },

  addInTheMiddleBtn: {
    width: '100%',
    padding: '0',
    opacity: 0,
    position: 'absolute',
    zIndex: 99,

    '&:not(.withId)': {
      top: 0,
      transform: 'translateY(-50%)',
    },

    '&.withId': {
      bottom: 0,
      transform: 'translateY(50%)',
    },

    '& .prefixIcon': {
      zIndex: 3,
      pointerEvents: 'none',
      width: '12px!important',
      height: '12px!important',

      '& circle:first-child, & path': {
        fill: 'var(--background-paper)',
      },

      '@media screen and (min-width: 1441px)': {
        width: '20px!important',
        height: '20px!important',
      },
    },

    '&:hover, &:active, &:focus': {
      opacity: 1,
    },

    '&.defaultDisplay': {
      border: 'none',
      backgroundColor: 'transparent',

      '&:hover': {
        border: 'none',
        backgroundColor: 'transparent',
      },
    },

    '&.smSize': {
      height: '20px',

      '@media screen and (min-width: 1441px)': {
        height: '12px',
      },
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1px',
      backgroundColor: 'var(--button-active)',
    },
  },

  iconWithContent: {
    marginRight: '4px',
  },

  blockInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.56px',
    height: '100%',
    color: 'inherit',

    '&.visibility': {
      '& .visibilityLabel': {
        padding: 0,
        color: 'inherit',
        border: 'none',
        backgroundColor: 'transparent',
        height: 'auto',
      },
    },

    '& svg': {
      color: 'inherit',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  iconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid var(--primary-lighty)',
    borderRadius: '4px',
    color: 'var(--text-secondary)',

    '& .blockInfo': {
      padding: '0 4px',

      '@media screen and (min-width: 1441px)': {
        padding: '0 6px',
      },
    },
  },

  sectionMiddle: {
    '& .songArtistNames': {
      maxWidth: '100%',
      width: '100%',
    },

    '& .songName': {
      ...mixins.longText,
      maxWidth: '240px',
      flexGrow: 'initial',
      whiteSpace: 'nowrap',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '310px',
      },
    },

    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  editableSectionName: {
    transform: 'translateX(-4px)',

    '&, & .textPreview, &.viboTextarea': {
      padding: '4px 6px!important',
      fontSize: '15px!important',
      lineHeight: '19.5px!important',

      '@media screen and (min-width: 1441px)': {
        fontSize: '18px!important',
        lineHeight: '24px!important',
      },
    },

    '&.isDontPlay': {
      border: 'none!important',

      '&:hover': {
        backgroundColor: 'transparent!important',
      },
    },

    '&:not(.isDontPlay)': {
      '&.textPreview, &.viboTextarea': {
        fontWeight: 500,
        border: '1px solid transparent',

        '&:hover:not(.disabled):not(:disabled)': {
          border: `1px solid var(--primary-main)`,
          backgroundColor: 'var(--background-paper)',
        },
      },
    },
  },
}));

export default useStyles;
