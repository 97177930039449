import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  radio: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 8px 0 0',
    fontSize: '13px',
    lineHeight: '14px',
    color: 'var(--text-primary)',

    '&.disabled': {
      color: 'var(--text-hint)',
      cursor: 'not-allowed',
    },

    '&:hover': {
      '& > .viboIcon::after': {
        borderRadius: '50%',
      },
    },

    '@media screen and (min-width: 1441px)': {
      lineHeight: '16px',
      fontSize: '16px',
    },
  },

  viboRadioGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .radio': {
      margin: '0',

      '&:not(:last-child)': {
        margin: '0 8px 0 0',
      },
    },
  },

  vertical: {
    flexDirection: 'column',
    alignItems: 'start',

    '& .radio:not(:last-child)': {
      margin: '0 0 8px 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 12px 0',
      },
    },
  },
}));

export default useStyles;
