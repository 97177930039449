import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  infoIcon: {
    cursor: 'pointer',
    color: 'var(--primary-main)',
  },
}));

export default useStyles;
