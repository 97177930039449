import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  timelineSongModalHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 29px',
    margin: '0 0 15px 0',

    '& .songArtistNames': {
      maxWidth: '380px',
      margin: '0 6px 0 0',

      '& .songName': {
        margin: '0 0 4px 0',
      },

      '& .artistName': {
        color: 'var(--text-secondary)',
      },

      '@media screen and (min-width: 1441px)': {
        maxWidth: '460px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '0 36px',
    },
  },

  flagIcon: {
    margin: '0 10px 0 0',

    '& svg': {
      width: '16px',
      height: '16px',
    },
  },

  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  songTitle: {
    marginLeft: '10px',
    fontSize: '18px',
  },

  secondRow: {
    position: 'relative',
    fontSize: '14px',
    margin: '10px 0 0 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .viboIcon': {
      color: 'var(--primary-main)',
      margin: '0 10px 0 0',
    },
  },

  flagFilled: {
    color: 'var(--error-main)',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  flagOutlined: {
    color: 'var(--text-secondary)',

    '&:hover ': {
      color: 'var(--error-main)',
      cursor: 'pointer',
    },
  },

  commentTitle: {
    fontWeight: 400,
    lineHeight: '25px',
    color: 'var(--clr-gray)',
    marginBottom: '3px',
  },
}));

export default useStyles;
