import React, { FC, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import EventLayout from '../EventLayout';
import NotificationsPage from 'components/notificationsPage';
import { NotificationsContextProvider } from 'components/context/NotificationsContext';

import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import {
  decreaseEventNotifsCount,
  increaseEventNotifsCount,
  removeEventCustomNotifiaction,
  updateEventNotif,
  updateNotifSentCache,
} from 'graphql/cache/notifications';

import { UPDATE_EVENT } from 'graphql/mutations/events';
import { GET_EVENT_CUSTOM_NOTIFICATIONS } from 'graphql/queries/notifications';
import {
  CREATE_EVENT_CUSTOM_NOTIFICATION,
  UPDATE_EVENT_CUSTOM_NOTIFICATION,
  REMOVE_EVENT_CUSTOM_NOTIFICATION,
  REORDER_EVENT_CUSTOM_NOTIFICATIONS,
  SEND_NOW,
} from 'graphql/mutations/notifications';

import { NotificationStatus, Page } from 'types/enums';

const EventNotifPage: FC = () => {
  const { t } = useTranslation();

  const { id } = useParams<EventPageRouteParams>();

  const [sendNow] = useMutation(SEND_NOW, {
    update: (_, { data }) => {
      const sentAt = get(data, 'sendNow.sentAt');

      sentAt && updateNotifSentCache(id, sentAt);
    },
    onCompleted: () => {
      toastNotify({
        text: t('notificationSentSuccessfully'),
      });
    },
    onError,
  });

  const updateNotification = useCallback(data => {
    updateEventNotif(data.updateEventCustomNotification);
  }, []);

  const mutations = useMemo(
    () => ({
      create: CREATE_EVENT_CUSTOM_NOTIFICATION,
      update: UPDATE_EVENT_CUSTOM_NOTIFICATION,
      remove: REMOVE_EVENT_CUSTOM_NOTIFICATION,
      reorder: REORDER_EVENT_CUSTOM_NOTIFICATIONS,
      updateEvent: UPDATE_EVENT,
    }),
    []
  );
  const queries = useMemo(
    () => ({
      notifications: GET_EVENT_CUSTOM_NOTIFICATIONS,
      key: 'eventCustomNotifications',
    }),
    []
  );
  const cache = useMemo(
    () => ({
      removeNotification: removeEventCustomNotifiaction,
      decreaseNotifsCount: () => {
        decreaseEventNotifsCount(id);
      },
      increaseNotifsCount: () => {
        increaseEventNotifsCount(id);
      },
      updateNotification,
    }),
    [id]
  );
  const variables = useMemo(
    () => ({
      eventId: id,
    }),
    [id]
  );
  const refetchQueries = useMemo(() => ['eventCustomNotifications'], []);

  const send = useCallback(
    (customNotificationId: string) => {
      sendNow({
        variables: {
          eventId: id,
          customNotificationId,
        },
        optimisticResponse: {
          sendNow: {
            _id: customNotificationId,
            status: NotificationStatus.sent,
            sentAt: Date.now(),
            __typename: 'EventCustomNotification',
          },
        },
      });
    },
    [id]
  );

  return (
    <EventLayout page={Page.notifications}>
      <NotificationsContextProvider
        value={{
          send,
          mutations,
          queries,
          cache,
          variables,
          refetchQueries,
        }}
      >
        <NotificationsPage />
      </NotificationsContextProvider>
    </EventLayout>
  );
};

export default EventNotifPage;
