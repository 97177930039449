import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songPlayerIframe: {
    margin: 'auto 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '280px',

    '& iframe': {
      border: '0',
      borderRadius: '4px',
      maxWidth: '100%',
      maxHeight: '280px',

      '@media screen and (min-width: 1441px)': {
        maxHeight: '390px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '396px',
    },
  },

  switchSongIcon: {
    color: 'var(--primary-main)',
    cursor: 'pointer',
  },

  disabledIcon: {
    cursor: 'default',

    '& svg': {
      '& path': {
        stroke: 'var(--text-disabled)',
      },
    },
  },

  links: {
    fontSize: '16px',
    display: 'flex',
    height: 'auto',
    alignItems: 'center',
    margin: '6px auto 0 auto',

    '& > div': {
      display: 'flex',

      '& svg': {
        height: '20px',
        width: '20px',

        '&:hover': {
          cursor: 'pointer',
        },

        '@media screen and (min-width: 1441px)': {
          height: '24px',
          width: '24px',
        },
      },
    },
  },

  thumbnail: {
    '&.songImage': {
      height: '280px',
      width: '280px',

      '@media screen and (min-width: 1441px)': {
        height: '390px',
        width: '390px',
      },

      '& img, & .intrinsic-item': {
        borderRadius: '4px',
      },
    },
  },
}));

export default useStyles;
