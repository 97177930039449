import React, { FC, useContext, MouseEvent } from 'react';
import classNames from 'classnames';

import Checkbox from 'vibo-ui/Checkbox';
import CopyQuestionAnswer from 'components/questions/CopyQuestionAnswer';
import MultiplyOptionContent from '../MultiOption/MultiplyOptionContent';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { CheckBoxOptionProps } from './interfaces';

import useStyles from 'components/questions/common/style';

const Option: FC<CheckBoxOptionProps> = ({ index, value, handleChange }) => {
  const classes = useStyles();

  const { canAnswer, question } = useContext(QuestionContext);

  const checked = question?.answer?.selectedOptions.includes(`${index}`);

  return (
    <div
      className={classNames('multiplyOption', classes.multiplyOption, {
        isOther: value.isOther,
        withAnswer: checked,
      })}
      onClick={(e: MouseEvent) => e.stopPropagation()}
    >
      <Checkbox
        className={classNames(classes.multiplyOptionContent)}
        checked={checked}
        value={`${index}`}
        onChange={e => {
          handleChange(e.target);
        }}
        disabled={!canAnswer || !value.title}
      />
      <MultiplyOptionContent value={value} index={index} />
      <CopyQuestionAnswer value={value.title} />
    </div>
  );
};

export default Option;
