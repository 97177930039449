import React, { FC, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';

import { useMe } from 'graphql/hooks/user';
import { getFullName } from 'services/users/helpers';
import { hmacSha256Hex } from 'services/common/stringHelpers';

import { isLoggedInVar } from 'graphql/cache';

import { FirstEventData, TIntercomContext } from './interfaces';

export const IntercomContext = createContext<TIntercomContext>(undefined!);

export const useViboIntercom = () => useContext(IntercomContext);

export const IntercomContextProvider: FC = ({ children }) => {
  const [firstEventData, setFirstEventData] = useState<Nullable<FirstEventData>>(null);
  const [isOpen, setIsOpen] = useState(false);

  const { boot, shutdown, show, hide } = useIntercom();

  const { user } = useMe();

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const startIntercom = async (action?: Function) =>
    await new Promise(resolve => {
      shutdown();
      resolve(null);
    }).then(() => {
      action?.();

      // @ts-ignore
      window.Intercom('onHide', () => {
        setIsOpen(false);
      });
      // @ts-ignore
      window.Intercom('onShow', () => {
        setIsOpen(true);
      });
    });

  const toggleShowChat = useCallback(() => {
    if (isOpen) {
      hide();
    } else {
      show();
    }
  }, [isOpen]);

  const bootUser = async () => {
    if (!!user) {
      await hmacSha256Hex(`${user?._id}`).then(userHash => {
        boot({
          name: getFullName(user),
          email: user.email,
          userId: user._id,
          phone: !!user?.phoneNumber ? `+${user?.phoneCode} ${user?.phoneNumber}` : '',
          userHash,
          customAttributes: {
            role: user.role,
            paymentStatus: user.djSettings?.financeStatus,
            djType: user.djSettings?.type,
            ...firstEventData,
          },
        });
      });
    }
  };
  const bootVisitor = () => boot();

  useEffect(() => {
    startIntercom(isLoggedIn ? bootUser : bootVisitor);
  }, [
    isLoggedIn,
    firstEventData?.eventId,
    user?.djSettings?.financeStatus,
    user?.djSettings?.type,
    user?._id,
  ]);

  return (
    <IntercomContext.Provider value={{ firstEventData, setFirstEventData, toggleShowChat }}>
      {children}
    </IntercomContext.Provider>
  );
};

// OLD CHAT FACEBOOK
//   useEffect(() => {
//     return () => {
//       const fbChat = document.getElementById('fb-root');

//       fbChat?.remove();
//     };
//   }, []);
//<div className="fb-customerchat">
//  <MessengerCustomerChat
//    pageId={process.env.REACT_APP_FACEBOOK_PAGEID}
//    appId={process.env.REACT_APP_FACEBOOK_APPID}
//    shouldShowDialog={false}
//    themeColor={'#0084FF'}
//    loggedInGreeting={'Hi, how can we help?'}
//    loggedOutGreeting={'Bye'}
//    greetingDialogDisplay={'show'}
//    version="2.11"
//    language="en_US"
//    autoLogAppEvents
//    xfbml
//  />
//</div>
