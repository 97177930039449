import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const useStyles = createUseStyles(() => ({
  djPreview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .djName': {
      ...mixins.longText,
      maxWidth: '150px',
      color: 'var(--text-primary)',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 6px',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 0 8px',
    },
  },

  djLogo: {
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    borderRadius: '50%',

    '& .gradientCover, & .gradient': {
      width: '100%',
      height: '100%',

      '& svg': {
        color: 'var(--background-paper)',
        width: '8px',
        height: '8px',
        margin: 'auto',
        transform: 'translateY(-.5px)',

        '@media screen and (min-width: 1441px)': {
          width: '10px',
          height: '10px',
        },
      },

      '& .imageDefaultPlaceholder': {
        '& svg': {
          margin: '0!important',
        },
      },
    },

    '& div, img': {
      borderRadius: '50%',
    },

    '@media screen and (min-width: 1441px)': {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',
      maxHeight: '24px',
    },
  },
}));

export default useStyles;
