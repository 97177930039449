import React, { FC, memo, useRef, useMemo, useEffect } from 'react';
import pick from 'lodash/pick';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { FormikProvider } from 'formik';

import QuestionHeader from '../QuestionHeader';
import AddQuestionInMiddle from '../AddQuestionInMiddle';
import WithEventRole from 'components/user/WithEventRole';
import QuestionMediaPreview from '../QuestionMediaPreview';
import ContentArea from 'components/common/ContentArea/ContentArea';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import {
  QuestionContext,
  useQuestionActions,
  useTimelineQuestions,
} from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useFormikAutoSave } from 'graphql/hooks/form';
import { useTargetingQuestion } from 'graphql/hooks/questions';
import { getQuestionBody } from 'services/questions/getQuestionBody';
import { getPreparedQuestionPayload } from 'components/modals/CreateQuestionModal/constants';
import { ALLOWED_FIELDS_TO_ANSWER, getQuestionToCompareUpdate } from './constants';

import { EventUserType } from 'types/enums';
import { QuestionProps } from './interfaces';

import useStyles from './style';

const QuestionComponent: FC<QuestionProps> = ({ question }) => {
  const classes = useStyles();

  const { answerQuestion, updateQuestion } = useQuestionActions();
  const { canAnswer } = useTimelineQuestions();

  const { targetQuestionId, clearQuestionTarget } = useTargetingQuestion();

  const QuestionBody = useMemo(() => getQuestionBody(question.settings.type), [question]);

  const questionRef = useRef<Nullable<HTMLDivElement>>(null);

  const { formik } = useFormikAutoSave<UpdateQuestionForm>(
    {
      initialValues: question,
      onSubmit: values => {
        if (!isEqual(values.settings, question.settings)) {
          return updateQuestion(question._id)(
            getPreparedQuestionPayload({ ...values, question: question.question }, false),
            []
          );
        }

        if (!isEqual(values.answer, question.answer)) {
          return answerQuestion(question._id)(pick(values, ALLOWED_FIELDS_TO_ANSWER));
        }

        if (
          !isEqual(
            getQuestionToCompareUpdate(values as Question),
            getQuestionToCompareUpdate(question)
          )
        ) {
          return updateQuestion(question._id)(getPreparedQuestionPayload(values, false));
        }
      },
    },
    1000
  );

  useEffect(() => {
    if (targetQuestionId === question._id && questionRef.current) {
      questionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      clearQuestionTarget();
    }
  }, [targetQuestionId]);

  useEffect(() => {
    formik.setValues(question);
  }, [
    question._id,
    question.isAnswered,
    question.settings.type,
    question.settings.hasOther,
    question.settings.optionImagesEnabled,
    question.settings.notifyMe,
  ]);

  return (
    <QuestionContext.Provider
      value={{
        question,
        canAnswer,
      }}
    >
      <FormikProvider value={formik}>
        <ContentArea
          className={classNames('question', `${question.settings.type}Question`, classes.question)}
          ref={questionRef}
          tabIndex={0}
        >
          <WithEventRole roles={[EventUserType.dj]}>
            <Icon icon={IconmoonFont['dnd-16']} className="dndIcon draggableItem" />
          </WithEventRole>
          <div className={classNames('content', classes.content)}>
            <QuestionHeader />
            <QuestionMediaPreview />
            {!!QuestionBody ? <QuestionBody /> : null}
          </div>
        </ContentArea>
        <AddQuestionInMiddle questionId={question._id} />
      </FormikProvider>
    </QuestionContext.Provider>
  );
};

export default memo(QuestionComponent);
