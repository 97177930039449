import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  notificationsPage: {
    '& .viboScrollbar': {
      paddingBottom: '100px',
      maxHeight: 'calc(100vh - 100px)!important',

      '& > .contentArea': {
        color: 'var(--text-primary)',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        margin: '0 0 20px 0',

        '&:has(.row)': {
          gap: '20px',

          '@media screen and (min-width: 1441px)': {
            gap: '24px',
          },
        },

        '& .row': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',

          '& .name': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '19.5px',

            '& .infoIcon': {
              color: 'var(--text-secondary)',
              margin: '0 6px',

              '@media screen and (min-width: 1441px)': {
                margin: '0 8px',
              },
            },

            '@media screen and (min-width: 1441px)': {
              fontSize: '18px',
              lineHeight: '23.4px',
            },
          },

          '& .recipients': {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-40%)',
            color: 'var(--text-secondary)',
            width: '30%',
            whiteSpace: 'nowrap',

            '& .viboIcon': {
              margin: '0 6px 0 0',

              '@media screen and (min-width: 1441px)': {
                margin: '0 8px 0 0',
              },
            },
          },

          '& > .viboIcon': {
            color: 'var(--text-secondary)',
            margin: '0 8px 0 0',

            '@media screen and (min-width: 1441px)': {
              margin: '0 12px 0 0',
            },
          },

          '& > .viboSwitchLabel': {
            margin: '0 0 0 auto',
          },
        },

        '@media screen and (min-width: 1441px)': {
          padding: '24px',
          margin: '0 0 24px 0',
        },
      },

      '& > h3': {
        margin: 0,
        fontSize: '17px',
        lineHeight: '22px',
        padding: '0 0 12px 20px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '20px',
          lineHeight: '26px',
          padding: '2px 0 16px 24px',
        },
      },

      '& > .emptyState .description': {
        display: 'flex',
        flexDirection: 'column',

        '& .viboButton': {
          margin: '20px 0 0 0',

          '@media screen and (min-width: 1441px)': {
            margin: '24px 0 0 0',
          },
        },
      },

      '& .customNotifications': {
        '&, & .smooth-dnd-container': {
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',

          '& .smooth-dnd-draggable-wrapper': {
            overflow: 'visible',
          },

          '@media screen and (min-width: 1441px)': {
            gap: '24px',
          },
        },
      },

      '@media screen and (min-width: 1441px)': {
        maxHeight: 'calc(100vh - 130px)!important',
      },
    },
  },

  customNotification: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '15px 20px 20px 20px',
    position: 'relative',

    '& :is(& > div) > .viboIcon': {
      marginRight: '6px',

      '&:not(.notifStatus)': {
        color: 'var(--text-secondary)',
      },

      '&.notifStatus': {
        marginTop: '3px',
      },

      '@media screen and (min-width: 1441px)': {
        marginRight: '8px',
      },
    },

    '& .draggableItem': {
      color: 'var(--primary-main)',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      opacity: 0,
    },

    '&:hover': {
      '& .extra-info': {
        '& .tools': {
          opacity: 1,
        },
      },

      '& .draggableItem': {
        opacity: 1,
      },
    },

    '& .top': {
      display: 'flex',
      flexDirection: 'row',

      '& .viboButton.success': {
        minWidth: '93px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '114px',
        },
      },
    },

    '& .mainInfo': {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      margin: '0 auto 0 0',

      '& .title': {
        fontSize: '15px',
        lineHeight: '20px',

        '& mf': {
          fontWeight: 700,
        },

        '@media screen and (min-width: 1441px)': {
          fontSize: '18px',
          lineHeight: '23px',
        },
      },

      '& .desc': {
        color: 'var(--text-secondary)',
        fontSize: '11px',
        lineHeight: '15px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
          lineHeight: '21px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        gap: '16px',
      },
    },

    '& .mainControls': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '12px',
      height: 'fit-content',

      '& .sentBadge': {
        color: 'var(--success-main)',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        gap: '16px',
      },
    },

    '& .extra-info': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: 'var(--text-secondary)',
      fontSize: '13px',
      lineHeight: '17px',

      '& .tools': {
        opacity: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 0 0 auto',
        gap: '8px',
        color: 'var(--primary-main)',

        '@media screen and (min-width: 1441px)': {
          gap: '12px',
        },
      },

      '&.timeSettings': {
        '&:not(.isTimeValid)': {
          '& > span': {
            color: 'var(--error-light)',
          },
        },

        '&.isTimeValid > span:not(.tbd)': {
          textTransform: 'lowercase',
        },
      },

      '& > span': {
        margin: '0 0 0 6px',
        color: 'var(--text-primary)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '6px',

        '@media screen and (min-width: 1441px)': {
          gap: '8px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '21px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      gap: '20px',
      padding: '18px 24px 24px 24px',
    },
  },

  notifStatus: {
    height: 'fit-content',

    '&:not(.disabled)': {
      '&.sent': {
        color: 'var(--success-main)',
      },

      '&.pending': {
        color: 'var(--warning-main)',
      },

      '&.cannotBeSend': {
        color: 'var(--error-light)',
      },
    },

    '&.disabled': {
      color: 'var(--text-hint)',
    },
  },
}));

export default useStyles;
