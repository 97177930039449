import { useQueryParam } from 'use-query-params';

export const useTargetingQuestion = () => {
  const [targetQuestionId, setTargetQuestionId] = useQueryParam<string | undefined>(
    'targetQuestionId'
  );

  const handleTargetQuestion = (id: string) => !!id && setTargetQuestionId(id);

  const clearQuestionTarget = () => setTargetQuestionId(undefined);

  return {
    targetQuestionId,
    handleTargetQuestion,
    clearQuestionTarget,
  };
};
