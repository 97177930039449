import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Tooltip from 'vibo-ui/Tooltip';
import { ViboThemeContext } from 'components/context/ViboThemeContext';

import { ReactComponent as SunIcon } from 'resources/img/svg/sun.svg';
import { ReactComponent as MoonIcon } from 'resources/img/svg/moon.svg';

import { isDark } from 'components/context/ViboThemeContext/constants';

import { AppThemeButtonProps } from './interfaces';

import useStyles from './style';

const AppThemeButton: FC<AppThemeButtonProps> = ({ align }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isDarkMode, setIsDarkMode] = useState(isDark());

  const { toggleTheme } = useContext(ViboThemeContext);

  const handleChangeTheme = useCallback(() => {
    setIsDarkMode(!isDarkMode);
    toggleTheme();
  }, [isDarkMode]);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'theme') {
          setIsDarkMode(isDark());
        }
      });
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['theme'],
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Tooltip
      overlay={t(isDarkMode ? 'lightMode' : 'darkMode')}
      type={isDarkMode ? 'white' : 'black'}
      align={align}
      fillOverlay
    >
      <Button
        onClick={handleChangeTheme}
        className={classNames('themeButton', classes.themeButton)}
        prefixIcon={isDarkMode ? SunIcon : MoonIcon}
        shape="circle"
      />
    </Tooltip>
  );
};

export default AppThemeButton;
