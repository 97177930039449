import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songIndex: {
    fontSize: '13px',
    lineHeight: '18.2px',
    fontWeight: 500,
    color: 'var(--text-secondary)',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

export default useStyles;
