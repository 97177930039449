import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoSongsIcon } from 'resources/img/svg/emptyStateNoSongs.svg';

const NoIdeasSearchResults: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <EmptyState size="lg" icon={EmptyStateNoSongsIcon}>
      {children || t('noResults')}
    </EmptyState>
  );
};

export default NoIdeasSearchResults;
