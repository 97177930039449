import { createContext, useContext } from 'react';

import { TQuestionActionsContext, TQuestionContext, TTimelineQuestionsContext } from './interfaces';

export const TimelineQuestionsContext = createContext<TTimelineQuestionsContext>(undefined!);

export const QuestionActionsContext = createContext<TQuestionActionsContext>(undefined!);

export const QuestionContext = createContext<TQuestionContext>(undefined!);

export const useQuestion = () => useContext(QuestionContext);

export const useQuestionActions = () => useContext(QuestionActionsContext);

export const useTimelineQuestions = () => useContext(TimelineQuestionsContext);
