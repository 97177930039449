import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  templateTimeline: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& .timelineSongsBlock': {
      '& .sectionBlockContent': {
        display: 'none',
      },
    },

    '& .sectionTitle': {
      marginBottom: '4px',
    },

    '& .customSongTab': {
      position: 'relative',
      right: '0',
    },

    '& .customQuestion': {
      position: 'relative',
      right: '0',
    },

    '& .delete': {
      display: 'none',
      marginRight: '10px ',
      position: 'relative',
      zIndex: '10',
    },

    '& .error': {
      textAlign: 'end',
      color: 'var(--error-main)',
      position: 'absolute',
      paddingRight: '25px',
      right: '0',
    },

    '& .errorInput': {
      borderBottom: `1px solid var(--error-main)!important`,
    },

    '& .limit': {
      textAlign: 'right',
      paddingRight: '25px',
      color: 'var(--primary-main)',
      display: 'none',
      position: 'absolute',
      right: '0',
    },

    '& .title': {
      fontSize: '16px',
      color: 'var(--text-secondary)',
    },

    '& .subTitle ': {
      fontSize: '16px',
      color: 'var(--text-secondary)',
    },
  },
}));

export default useStyles;
