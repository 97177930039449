export const DEFAULT_OPTIONS = {
  height: '444',
  width: '801',
  playerVars: {
    autoplay: 1,
    enablejsapi: 1,
  },
};

export const VIMEO_PLAYER_LINK = `https://player.vimeo.com/video/`;
