import { createUseStyles } from 'react-jss';

export const createGradientStyles = createUseStyles({
  viboGradient: {
    position: 'relative',

    '& .viboIcon': {
      color: 'var(--clr-white)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },

  gradient1: {
    backgroundImage: 'linear-gradient(225deg, #C0F7FB 0%, #2B6FF2 100%);',
  },

  gradient2: {
    backgroundImage: 'linear-gradient(225deg, #FEBD6F 0%, #FD5BC0 100%);',
  },

  gradient3: {
    backgroundImage: 'linear-gradient(225deg, #FF97BE 0%, #FDF621 100%);',
  },

  gradient4: {
    backgroundImage: 'linear-gradient(225deg, #FDF621 0%, #D5B604 100%);',
  },

  gradient5: {
    backgroundImage: 'linear-gradient(226.31deg, #64FFDD 0%, #FBA2CC 97.82%);',
  },

  gradient6: {
    backgroundImage: 'linear-gradient(225deg, #B4FF56 0%, #18DDE9 100%);',
  },

  gradient7: {
    backgroundImage: 'linear-gradient(225deg, #B9F045 0%, #0FA275 100%);',
  },

  gradient8: {
    backgroundImage: 'linear-gradient(225deg, #B3145B 0%, #FF7F6E 100%);',
  },

  gradient9: {
    backgroundImage: 'linear-gradient(225deg, #1C66B9 0%, #B264F7 85.42%);',
  },
});
