import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songIdeasPage: {
    height: '100%',
    background: 'var(--background-default)',
    padding: '0',

    '& > .container': {
      height: 'calc(100% - 40px)',

      '& .containerContent': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },

      '@media screen and (min-width: 1441px)': {
        height: 'calc(100% - 48px)',
      },
    },

    '& .emptyState': {
      margin: '5% 0 0 0',

      '& .description': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& .viboButton': {
          minWidth: '256px',
          margin: '25px 0 0 0',

          '&:last-child': {
            margin: '11px 0 0 0',
          },
        },
      },
    },
  },

  songIdeasContainer: {
    '& > .containerContent': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
