import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import ReactHtmlParser from 'react-html-parser';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';
import MaxCharsLimit from '../MaxCharsLimit';
import InputErrors from 'vibo-ui/common/InputErrors';

import {
  escapeHtml,
  escapeMagicFields,
  handlePasteOnlyText,
  insertHtmlToMagicField,
  setSelectionToEnd,
} from 'services/common/domHelpers';
import {
  getMagicFieldTag,
  getSanitizedField,
  MF_END,
  MF_START,
  parseFromMagicFieldValue,
  parseToMagicFieldValue,
} from './constants';

import { IconmoonFont } from 'vibo-ui/Icon';
import { MagicFieldProps } from './interfaces';

import useInputStyles from 'resources/styles/inputs/style';
import useStyles from './style';

const MagicField: FC<MagicFieldProps> = ({
  onChange,
  id,
  options,
  withUnderline,
  showMaxLength,
  maxLength,
  placeholder,
  value = '',
  errors = [],
  autoFocus = false,
  onlyUniqueFields = false,
}) => {
  const inputClasses = useInputStyles();
  const classes = useStyles();

  const [defaultValue, setDefaultValue] = useState('');
  const [currentValue, setCurrentValue] = useState(value || '');

  const fieldRef = useRef<Nullable<HTMLDivElement>>(null);

  const noHtmlValueLength = useMemo(() => escapeMagicFields(escapeHtml(currentValue)).length, [
    currentValue,
  ]);

  const handleSelectChange = useCallback(
    (value: string) => () => {
      const extendedField =
        !!fieldRef.current &&
        insertHtmlToMagicField(`${getMagicFieldTag(value)}&nbsp;`, fieldRef.current, id);

      !!extendedField && setCurrentValue(extendedField);
    },
    [fieldRef.current]
  );

  const handleChangeField = useCallback(e => {
    const field = e.currentTarget;
    const sanitizedValue = getSanitizedField(e.currentTarget.innerHTML);

    // FOR SAFARI
    if (field.innerHTML === '<br>' || field.innerHTML === '<br/>') {
      field.innerHTML = '';
    }

    setCurrentValue(sanitizedValue);
  }, []);

  const disabledOptions = useMemo(
    () =>
      onlyUniqueFields && !!options?.length
        ? options?.filter(
            ({ value, label }) =>
              currentValue?.includes(getMagicFieldTag(label)) ||
              currentValue?.includes(`${MF_START}${value}${MF_END}`)
          )
        : [],
    [currentValue, options, onlyUniqueFields]
  );

  const handleKeyDown = useCallback(e => {
    if (
      e.key === 'Enter' ||
      ((e.ctrlKey || e.metaKey) && ['b', 'i', 'u'].includes(e.key.toLowerCase()))
    ) {
      e.preventDefault();
    }
  }, []);

  const handleClick = useCallback(e => {
    const target = e.target;

    if (target.tagName === 'MF' && target.getAttribute('contenteditable') === 'false') {
      target.remove();
      handleChangeField(e);
    }
  }, []);

  useEffect(() => {
    !isEqual(value, currentValue) && onChange(parseFromMagicFieldValue(currentValue, options));
  }, [currentValue, options]);

  useEffect(() => {
    if (!!value) {
      const parsedValue = parseToMagicFieldValue(value, options);
      const sanitizedValue = getSanitizedField(parsedValue);

      setDefaultValue(sanitizedValue);
    }

    if (autoFocus && !!fieldRef.current) {
      setSelectionToEnd(fieldRef.current);
    }
  }, []);

  return (
    <div
      className={classNames(
        'magicFieldWrapper',
        classes.magicFieldWrapper,
        inputClasses.viboUnderlineWrapper,
        {
          [`${inputClasses.underline} underline`]: withUnderline,
          errorsPresent: !!errors?.filter(Boolean).length,
        }
      )}
    >
      <div className="closeWrapper">
        <div
          // onInput={handleChangeField}
          onKeyUp={handleChangeField}
          className={classNames(
            'magicField viboTextarea',
            classes.magicField,
            withUnderline
              ? [`withUnderline ${inputClasses.withUnderline}`]
              : [`withBorder ${inputClasses.withBorder}`]
          )}
          onKeyDown={handleKeyDown}
          onPaste={e => handlePasteOnlyText(e, handleChangeField)}
          ref={fieldRef}
          id={id}
          tabIndex={1}
          onClick={handleClick}
          contentEditable
        >
          {ReactHtmlParser(defaultValue)}
        </div>
        <span className="placeholder">{placeholder}</span>
        {errors?.filter(Boolean)?.length ? (
          <InputErrors errors={errors} />
        ) : showMaxLength ? (
          <MaxCharsLimit maxLength={maxLength} valueLength={noHtmlValueLength} />
        ) : null}
      </div>
      {!!options?.length ? (
        <Dropdown
          overlay={
            <Menu styleModificator="asDropdown" selectable={false}>
              {options.map(option => (
                <Menu.Item
                  onClick={handleSelectChange(option.label)}
                  disabled={disabledOptions.some(({ value }) => value === option.value)}
                  key={`magic-field-option-${option.value}`}
                >
                  {option.label}
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomRight"
          trigger="click"
          disableToggleClass
        >
          <Button prefixIcon={IconmoonFont['magicField-16']} displayType="link" type="button" />
        </Dropdown>
      ) : null}
    </div>
  );
};

export default MagicField;
