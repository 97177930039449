import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  loadingOverlay: {
    pointerEvents: 'none',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& .viboSpinner': {
      color: 'var(--light-color)',
    },

    '&.fillOverlay': {
      '&:before': {
        backgroundColor: 'var(--overlay-default)',
      },
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
    },
  },
}));

export default useStyles;
