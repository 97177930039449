import * as Yup from 'yup';
import pick from 'lodash/pick';

import { escapeHtml, escapeMagicFields } from 'services/common/domHelpers';

import {
  CustomNotificationsAddresses,
  CustomNotifMagicField,
  EventStatusDate,
  TemporalOrder,
  TimeUnit,
} from 'types/enums';
import {
  CreateCustomNotifForm,
  EventCustomNotification,
} from 'components/context/NotificationsContext/interfaces';

export const ALLOWED_FIELDS_FOR_UPDATE = [
  'title',
  'input',
  'sendingTimeSettings',
  'addresses',
  '_id',
];

export const DEFAULT_CUSTOM_NOTIF = {
  title: '',
  input: '',
  sendingTimeSettings: {
    number: (undefined as unknown) as number,
    timeUnit: TimeUnit.day,
    temporalOrder: TemporalOrder.before,
    eventStatusDate: EventStatusDate.eventDate,
  },
  addresses: [CustomNotificationsAddresses.hosts],
};

export const EMPTY_TIME_SETTINGS = {
  number: undefined,
  timeUnit: null,
  temporalOrder: null,
  eventStatusDate: null,
};

export const CUSTOM_NOTIF_MAGIC_FIELDS = [
  { value: CustomNotifMagicField.firstName, label: 'First name' },
  { value: CustomNotifMagicField.lastName, label: 'Last name' },
  { value: CustomNotifMagicField.DJName, label: 'DJ name' },
  { value: CustomNotifMagicField.eventDate, label: 'Event date' },
  { value: CustomNotifMagicField.eventTitle, label: 'Event title' },
  { value: CustomNotifMagicField.lockoutDate, label: 'Lockout date' },
];

export const CUSTON_NOTIF_TITLE_MIN = 3;
export const CUSTON_NOTIF_TITLE_MAX = 30;

export const CUSTON_NOTIF_INPUT_MAX = 100;

export const customNitifValidationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Notification title is required')
    .test('title', `Must be less than ${CUSTON_NOTIF_TITLE_MAX} characters`, val => {
      const parsedValue = escapeMagicFields(escapeHtml(val));

      return parsedValue?.length <= CUSTON_NOTIF_TITLE_MAX;
    })
    .test('title', `Minimum ${CUSTON_NOTIF_TITLE_MIN} characters required`, val => {
      const parsedValue = escapeMagicFields(escapeHtml(val));

      return parsedValue?.length >= CUSTON_NOTIF_TITLE_MIN;
    }),
  input: Yup.string()
    .trim()
    .test('input', `Must be less than ${CUSTON_NOTIF_INPUT_MAX} characters`, val => {
      const parsedValue = escapeMagicFields(escapeHtml(val));

      return parsedValue?.length <= CUSTON_NOTIF_INPUT_MAX;
    }),
  addresses: Yup.array()
    .min(1, 'At least one recipient should be selected')
    .required('Required'),
});

export const TIME_UNIT_MAX = 99;

export const TIME_NUMBER_SUGGESTIONS = Array.from({ length: 99 }, (_, index) => {
  const value = index + 1;

  return {
    label: value,
    value,
  };
});

export const getPreparedCustomNotif = (data: CreateCustomNotifForm): CreateCustomNotifForm => {
  const sendingTimeSettings = !!data.sendingTimeSettings?.number
    ? data.sendingTimeSettings
    : EMPTY_TIME_SETTINGS;

  return {
    ...data,
    sendingTimeSettings,
    title: escapeHtml(data.title),
    input: escapeHtml(data.input),
  };
};

export const getInitNotifData = (notification: Partial<EventCustomNotification>) => {
  const data = pick(notification, ALLOWED_FIELDS_FOR_UPDATE);
  const newSettings = data.sendingTimeSettings;
  const defaultSettings = DEFAULT_CUSTOM_NOTIF.sendingTimeSettings;

  const sendingTimeSettings = {
    number: newSettings?.number || defaultSettings.number,
    timeUnit: newSettings?.timeUnit || defaultSettings.timeUnit,
    temporalOrder: newSettings?.temporalOrder || defaultSettings.temporalOrder,
    eventStatusDate: newSettings?.eventStatusDate || defaultSettings.eventStatusDate,
  };

  return {
    ...data,
    sendingTimeSettings,
  };
};
