import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  imageDefaultPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '100%',

    '& svg': {
      margin: 'auto',
    },

    '& span': {
      position: 'absolute',
      bottom: '16px',
      fontSize: '16px',
      fontWeight: 500,
      color: 'var(--background-paper)',
    },
  },
}));

export default useStyles;
