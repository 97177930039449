import { gql } from '@apollo/client';

import { NOTIFICATION } from 'graphql/fragments/notifications';

export const GET_NOTIFICATIONS = gql`
  query getNotifications($pagination: PaginationInput) {
    getNotifications(pagination: $pagination) {
      notifications {
        ...NotificationFragment
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
  ${NOTIFICATION}
`;

export const GET_NOTIFICATIONS_COUNT = gql`
  query getNotificationsCount {
    getNotificationsCount {
      total
      __typename
    }
  }
`;

export const GET_EVENT_CUSTOM_NOTIFICATIONS = gql`
  query eventCustomNotifications($eventId: ID!) {
    eventCustomNotifications(eventId: $eventId) {
      _id
      title
      input
      sendingTimeSettings {
        number
        timeUnit
        temporalOrder
        eventStatusDate
      }
      addresses
      disabled
      status
      sentAt
      sendingTime
    }
  }
`;

export const GET_TEMPLATE_CUSTOM_NOTIFICATIONS = gql`
  query templateCustomNotifications($templateId: ID!) {
    templateCustomNotifications(templateId: $templateId) {
      _id
      title
      input
      sendingTimeSettings {
        number
        timeUnit
        temporalOrder
        eventStatusDate
      }
      addresses
      disabled
    }
  }
`;
