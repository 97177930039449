import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  questionIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },

  icon: {
    width: '14px',
    height: '14px',

    '@media screen and (min-width: 1441px)': {
      width: '16px',
      height: '16px',
    },
  },

  infoIcon: {
    color: 'var(--warning-main)',
  },

  unanswred: {
    color: 'var(--primary-main)',
  },
}));

export default useStyles;
