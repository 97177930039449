import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  formWithFormik: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default useStyles;
