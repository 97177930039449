import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from 'vibo-ui/Button';

import { IconmoonFont } from 'vibo-ui/Icon';

const EventNotificationsButton: FC<ButtonProps> = ({ children, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button prefixIcon={IconmoonFont['notifyMeBellOutlined-16']} shape="round" size="lg" {...rest}>
      {children || t('eventNotifications')}
    </Button>
  );
};

export default EventNotificationsButton;
