import { gql } from '@apollo/client';

export const NOTIFICATION = gql`
  fragment NotificationFragment on Notification {
    _id
    imageUrl
    header
    body
    isRead
    notificationType
    createdAt
    metadata {
      eventId
      sectionId
      questionId
    }
  }
`;

export const NOTIFICATION_READ = gql`
  fragment NotificationReadFragment on Notification {
    isRead
  }
`;

export const EVENT_CUSTOM_NOTIFICATION = gql`
  fragment EventCustomNotificationFragment on EventCustomNotification {
    _id
    title
    input
    sendingTimeSettings {
      number
      timeUnit
      temporalOrder
      eventStatusDate
    }
    addresses
    status
  }
`;

export const TEMPLATE_CUSTOM_NOTIFICATION = gql`
  fragment TemplateCustomNotificationFragment on TemplateCustomNotification {
    _id
    title
    input
    sendingTimeSettings {
      number
      timeUnit
      temporalOrder
      eventStatusDate
    }
    addresses
  }
`;
