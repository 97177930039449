import React, { FC, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import { EventContext } from 'components/context/EventContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { GenerateEventPdfModalProps } from 'components/modals/GenerateEventPdfModal';
import { DownloadPdfButtonProps } from './interfaces';

import useStyles from './style';

const DownloadPdfButton: FC<DownloadPdfButtonProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { event, isEventDj = false } = useContext(EventContext);

  const { openModal } = useModal();

  const openGeneratePdfModal = useCallback(
    () =>
      openModal<GenerateEventPdfModalProps>({
        key: Modals.generatePdfFile,
        props: {
          isEventDj,
          eventId: event?._id as string,
        },
      }),
    [isEventDj, event?._id]
  );

  const formik = useFormikContext<EventFileOptionsWeb>();

  const isNoPdfSettings =
    !formik?.values?.withEventDetails &&
    !formik?.values?.withDJNotes &&
    !formik?.values.withTimeline &&
    !formik?.values.withSections &&
    !formik?.values.withPlaylists;

  return (
    <Button
      onClick={openGeneratePdfModal}
      className={classNames(classes.downloadPdfButton, className)}
      prefixIcon={IconmoonFont['download-24']}
      disabled={isNoPdfSettings || formik.isSubmitting}
      displayType="bordered"
      shape="round"
      size="lg"
    >
      {t('downloadPdf')}
    </Button>
  );
};

export default DownloadPdfButton;
