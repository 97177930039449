import React, { FC, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Form from 'components/common/Form';
import TimeBlock from './blocks/TimeBlock';
import MessageBlock from './blocks/MessageBlock';
import RecipientsBlock from './blocks/RecipientsBlock';
import Modal, { useModal } from 'vibo-ui/Modal';

import {
  customNitifValidationSchema,
  DEFAULT_CUSTOM_NOTIF,
  getInitNotifData,
  getPreparedCustomNotif,
} from './constants';

import { Modals } from 'types/enums';
import { CreateCustomNotifForm } from 'components/context/NotificationsContext/interfaces';
import { CustomNotificationModalProps } from './interfaces';

import useStyles from './style';

const CustomNotificationModal: FC<CustomNotificationModalProps> = ({
  notification,
  create,
  update,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isEditMode = useMemo(() => !!notification?._id, [notification?._id]);

  const title = useMemo(() => t(isEditMode ? 'editNotification' : 'newNotification'), [isEditMode]);

  const { closeModal } = useModal();

  const closeCustomNotificationModal = useCallback(() => closeModal(Modals.customNotification), []);

  const formik = useFormik<CreateCustomNotifForm>({
    initialValues: !!notification ? getInitNotifData(notification) : DEFAULT_CUSTOM_NOTIF,
    validationSchema: customNitifValidationSchema,
    isInitialValid: true,
    validateOnChange: true,
    onSubmit: values => {
      const preparedNotif = getPreparedCustomNotif(values);

      if (isEditMode) {
        update?.(preparedNotif);
      } else {
        create?.(preparedNotif);
      }

      closeCustomNotificationModal();
    },
  });

  return (
    <Modal
      modalName={Modals.customNotification}
      title={title}
      className={classes.customNotificationsModal}
      size="xxlg"
    >
      <Form formik={formik}>
        <Modal.Body>
          <h3>{t('whatWillMessageBe')}</h3>
          <MessageBlock />
          <h3>{t('whenWillBeSent')}</h3>
          <TimeBlock />
          <h3>{`${t('whoWillBeNotified')}?`}</h3>
          <RecipientsBlock />
        </Modal.Body>
      </Form>
      <Modal.Footer>
        <Button
          onClick={closeCustomNotificationModal}
          displayType="bordered"
          shape="round"
          size="lg"
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={formik.submitForm}
          type="submit"
          displayType="primary"
          shape="round"
          size="lg"
        >
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomNotificationModal;
