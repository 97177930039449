import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import MagicField from 'components/common/MagicField';
import InputLabel from 'components/common/InputLabel';
import ContentArea from 'components/common/ContentArea';

import {
  CUSTOM_NOTIF_MAGIC_FIELDS,
  CUSTON_NOTIF_INPUT_MAX,
  CUSTON_NOTIF_TITLE_MAX,
} from '../constants';

import { CreateCustomNotifForm } from 'components/context/NotificationsContext/interfaces';

const MessageBlock: FC = () => {
  const { t } = useTranslation();

  const { values, errors, setFieldValue } = useFormikContext<CreateCustomNotifForm>();

  return (
    <ContentArea className="message" shadowed>
      <InputLabel text={t('notificationTitle')} required />
      <MagicField
        onChange={value => {
          setFieldValue('title', value);
        }}
        value={values.title}
        id="title"
        maxLength={CUSTON_NOTIF_TITLE_MAX}
        errors={!!errors.title ? [errors.title] : []}
        placeholder={t('addYourTitle')}
        showMaxLength
        autoFocus
      />
      <InputLabel text={t('notificationBody')} />
      <MagicField
        onChange={value => {
          setFieldValue('input', value);
        }}
        value={values.input}
        id="input"
        options={CUSTOM_NOTIF_MAGIC_FIELDS}
        maxLength={CUSTON_NOTIF_INPUT_MAX}
        errors={!!errors.input ? [errors.input] : []}
        placeholder={t('addYourText')}
        showMaxLength
      />
    </ContentArea>
  );
};

export default MessageBlock;
