import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  viboCheckbox: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',

    '&.disabled': {
      color: 'var(--text-hint)',
      cursor: 'not-allowed',
    },
  },

  indeterminate: {
    color: 'var(--text-hint)',
  },
}));

export default useStyles;
