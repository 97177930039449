import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  colorBox: {
    width: '13px',
    height: '13px',
    borderRadius: '4px',

    'body[theme="dark"] &': {
      backgroundColor: 'var(--dark-color)',
    },

    'body[theme="light"] &': {
      backgroundColor: 'var(--light-color)',
    },

    '@media screen and (min-width: 1441px)': {
      width: '16px',
      height: '16px',
    },
  },
}));

export default useStyles;
