import { createUseStyles } from 'react-jss';

import { mixins } from 'resources/styles/mixins';

const STATUS_WIDTH_SM = 100;
const STATUS_WIDTH_LG = 140;
const NAME_WIDTH_SM = 208;
const NAME_WIDTH_LG = 300;

const useStyles = createUseStyles(() => ({
  eventContacts: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& > .viboScrollbar': {
      height: 'calc(100% - 50px)',
    },

    '& .cellName': {
      width: '100%',

      '&.photo': {
        maxWidth: '72px',
        margin: '0 8px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 16px 0 0',
          maxWidth: '92px',
        },
      },

      '&.name': {
        ...mixins.longText,
        width: '100%',
        maxWidth: `${NAME_WIDTH_SM}px`,

        '@media screen and (min-width: 1537px)': {
          maxWidth: `${NAME_WIDTH_LG}px`,
        },
      },

      '&.phone, &.email': {
        '& .viboButton': {
          padding: '0 10px 0 0',
        },
      },

      '&.phone': {
        maxWidth: '130px',

        '& .viboButton': {
          ...mixins.longText,
          display: 'block',
          maxWidth: '100%',
        },

        '@media screen and (min-width: 1441px)': {
          maxWidth: '180px',
        },
      },

      '&.email': {
        padding: '0 6px 0 0',
        maxWidth: '160px',

        '& .viboButton': {
          ...mixins.longText,
          display: 'block',
          maxWidth: '100%',
        },

        '@media screen and (min-width: 1441px)': {
          maxWidth: '260px',
        },
      },

      '&.role': {
        maxWidth: '70px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '100px',
        },
      },

      '&.status': {
        maxWidth: `${STATUS_WIDTH_SM}px`,

        '@media screen and (min-width: 1441px)': {
          maxWidth: `${STATUS_WIDTH_LG}px`,
        },
      },
    },

    '&.forUser': {
      '& .cellName': {
        '&.name': {
          maxWidth: `${NAME_WIDTH_SM + STATUS_WIDTH_SM}px`,

          '@media screen and (min-width: 1537px)': {
            maxWidth: `${NAME_WIDTH_LG + STATUS_WIDTH_LG}px`,
          },
        },
      },
    },

    '& .viboToggleDropdown': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
      width: 'fit-content',

      '& .valueName': {
        textTransform: 'capitalize',
        color: 'var(--primary-main)',
        margin: '0 0 0 4px',
      },
    },

    '& .viboList': {
      '& .virtualElement': {
        '&:not(:last-child)': {
          margin: '0 0 8px 0',

          '@media screen and (min-width: 1441px)': {
            margin: '0 0 12px 0',
          },
        },
      },
    },
  },

  statusMenu: {
    '& .viboSelect__option': {
      fontSize: '14px',
      fontWeight: 500,

      '&.contact': {
        color: 'var(--warning-main)',
      },

      '&.joined': {
        color: 'var(--success-main)',
      },
    },
  },

  eventContactsHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 0',

    '& .cellName': {
      fontWeight: 400,
      color: 'var(--text-secondary)',
      fontSize: '13px',
      lineHeight: '19.5px',

      '&.photo': {
        margin: '0 14px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 22px 0 0',
        },
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  selectContactStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .statusName': {
      color: 'var(--primary-main)',
    },
  },

  eventContactRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'var(--background-paper)',
    boxShadow: 'var(--shadows-3)',
    padding: '8px',
    borderRadius: '4px',
    overflow: 'hidden',

    '& .customLabel': {
      lineHeight: 'normal',
    },

    '& .photo': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '27px',

      '& .viboImage': {
        width: '72px',
        height: '72px',

        '@media screen and (min-width: 1441px)': {
          width: '92px',
          height: '92px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '18px',
        lineHeight: '27px',
      },
    },

    '& .status': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textTransform: 'capitalize',
    },

    '& .role': {
      '& .viboButton': {
        textTransform: 'capitalize',
        padding: '0 4px',

        '& .sufixIcon': {
          margin: '0 0 0 3px',
        },

        '@media screen and (min-width: 1441px)': {
          padding: '0 6px',
        },
      },
    },

    '& .controls': {
      width: 'auto',

      '& .viboButton': {
        width: 'fit-content',
        padding: '0',
      },
    },
  },

  contactStatusIndicator: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',

    '&.contact-status': {
      backgroundColor: 'var(--info-main)',
    },

    '&.joined-status': {
      backgroundColor: 'var(--success-main)',
    },
  },

  controls: {
    display: 'flex',
    flexDirection: 'column',

    '& .viboButton': {
      display: 'flex',
      justifyContent: 'start',
    },

    '& .iconButton': {
      '& svg': {
        width: '14px',
        height: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },
    },
  },

  selectRoleMenu: {
    width: '90px',
  },

  selectedRole: {
    color: 'var(--primary-main)',
  },

  loadingIndicator: {
    position: 'relative',
    width: '100%',
    height: '40px',
  },

  contactRoleSelect: {
    boxShadow: 'var(--shadows-3)',
    borderRadius: '4px',

    '& .viboSelect__indicator': {
      padding: '0 0 0 3px!important',
    },

    '& .viboSelect__control': {
      padding: '0 4px!important',

      '& .highlighted': {
        padding: '0!important',
      },
    },
  },
}));

export default useStyles;
