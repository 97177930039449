import React, { FC, useCallback, useContext, useEffect } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { useParams } from 'react-router';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';

import PdfContent from './PdfContent';
import PDFSettings from './PDFSettings';
import EventLayout from '../EventLayout';
import LoadingOverlay from 'components/common/LoadingOverlay/LoadingOverlay';
import { EventContext } from 'components/context/EventContext';
import { ScrollSpyContextProvider } from 'components/context/ScrollSpyContext';

import { onError } from 'graphql/helpers';
import { usePrevious } from 'services/common/dataProcessingHelpers';
import { updateEventPdfSettingsWebCache } from 'graphql/cache/events';
import {
  PDF_UPDATE_LAYOUT_FIELDS,
  getPraparedPayload,
} from 'components/modals/GenerateEventPdfModal/constants';

import { UPDATE_EVENT_PDF_SETTINGS_WEB } from 'graphql/mutations/events';
import { GET_CREATE_EVENT_FILE_OPTIONS_WEB } from 'graphql/queries/events';

import { EventUserType, Page } from 'types/enums';

import useStyles from './style';

const PDF: FC = () => {
  const { id: eventId } = useParams<EventPageRouteParams>();

  const { isEventDj = false } = useContext(EventContext);

  const { data, loading } = useQuery<GetCreateEventFileOptionsWeb, { eventId: string }>(
    GET_CREATE_EVENT_FILE_OPTIONS_WEB,
    {
      variables: {
        eventId,
      },
      fetchPolicy: 'cache-and-network',
      onError,
      skip: !eventId,
    }
  );
  const initialConfig = get(
    data?.getCreateEventFileOptionsWeb,
    EventUserType.dj,
    {}
  ) as CreateEventFileWebForm;

  const [updateEventPdfSettingsWeb] = useMutation<
    UpdateEventPdfSettingsResponse,
    UpdateEventPdfSettingsVariables
  >(UPDATE_EVENT_PDF_SETTINGS_WEB, {
    onCompleted: () => {
      formik.setSubmitting(false);
    },
    onError: e => {
      formik.setSubmitting(false);
      onError(e);
    },
  });

  const formik = useFormik<CreateEventFileWebForm>({
    initialValues: {
      ...(initialConfig as CreateEventFileWebForm),
    },
    onSubmit: params => {
      if (!!previousFormikValues && !isEmpty(previousFormikValues)) {
        handleUpdateEventPdfSettings(params);
      }
    },
    enableReinitialize: true,
  });
  const previousFormikValues = usePrevious(formik.values);

  const handleUpdateEventPdfSettings = useCallback(
    debounce(params => {
      // userType hardcoded, the reason - using only DJ's preset

      const payload = getPraparedPayload({
        params,
        isEventDj,
        fieldsToPick: PDF_UPDATE_LAYOUT_FIELDS,
        filterSelection: false,
      });

      updateEventPdfSettingsWebCache({
        eventId,
        userType: EventUserType.dj,
        data: params,
      });

      updateEventPdfSettingsWeb({
        variables: {
          eventId,
          userType: EventUserType.dj,
          payload: payload,
        },
      });
    }, 3000),
    [eventId, isEventDj]
  );

  useEffect(() => {
    if (
      !!previousFormikValues &&
      !isEmpty(previousFormikValues) &&
      !isEqual(formik.values, previousFormikValues)
    ) {
      formik.setSubmitting(true);
      handleUpdateEventPdfSettings(formik.values);
    }
  }, [formik.values]);

  return (
    <ScrollSpyContextProvider scrollId="pdf-blocks-scroll" offset={200}>
      <FormikProvider value={formik}>
        {loading || isEmpty(formik.values) ? (
          <LoadingOverlay />
        ) : (
          <>
            <PDFSettings />
            <PdfContent />
          </>
        )}
      </FormikProvider>
    </ScrollSpyContextProvider>
  );
};

const PdfWrapper: FC = () => {
  const classes = useStyles();

  return (
    <EventLayout className={classes.eventLayoutPdf} page={Page.eventPdf}>
      <PDF />
    </EventLayout>
  );
};

export default PdfWrapper;
